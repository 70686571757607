import { MilestoneListColumnTypeV2 } from './common';
import { currencyFormatter } from '@utils';

const originalConstructionBudget: MilestoneListColumnTypeV2 = {
  field: 'original_construction_budget',
  valueFormatter: (_value, row) => currencyFormatter(row.prefunding_cost + row.original_estimate),
  minWidth: 130,
  maxWidth: 200,
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Original construction budget($)',
  description:
    'Original amount to be spent on construction inclusive of holdback and borrower equity pre and post funding before any changes or reallocations of funds',
  editable: false,
};

export default originalConstructionBudget;
