import * as React from 'react';
import { RichTreeViewPro } from '@mui/x-tree-view-pro/RichTreeViewPro';
import { Box, Button } from '@mui/material';
import { CustomTreeItem } from './components/CustomTreeItem';
import { useRolesWorkflow, RolesWorkflowInterface } from './controller';
import { AddRoleDialog } from './components/AddRoleDialog';

const RolesWorkflow2 = ({ roles, updateRole, disabled, addRole }: RolesWorkflowInterface) => {
  const { itemsKeys, items, updateApi, handleAddRole } = useRolesWorkflow({
    roles,
    updateRole,
    addRole,
  });
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  return (
    <Box>
      <Box sx={{ mb: 2 }}>
        <Button color="primary" disabled={disabled} onClick={() => setIsDialogOpen(true)}>
          Add role
        </Button>
      </Box>
      <RichTreeViewPro
        isItemDisabled={() => disabled}
        items={items}
        itemsReordering
        defaultExpandedItems={disabled ? itemsKeys : ['0', '1', '2', '3']}
        onItemPositionChange={updateApi}
        canMoveItemToNewPosition={({ itemId, newPosition }) =>
          newPosition.parentId && !itemsKeys.includes(itemId)
        }
        slots={{
          item: (props) => <CustomTreeItem {...props} updateRole={updateRole} />,
        }}
        experimentalFeatures={{
          indentationAtItemLevel: true,
          itemsReordering: true,
        }}
      />
      <AddRoleDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onAdd={handleAddRole}
      />
    </Box>
  );
};

export default RolesWorkflow2;
