import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Stack } from '@mui/material';
import { StatusProcessedIcon, StatusUnProcessedIcon } from '@svgAsComponents';
import { documentCanBeProcessed } from '@utils';

export const processedStatusColumn = (): GridColDef => ({
  field: 'processed_status',
  headerName: 'Processed status',
  minWidth: 100,
  maxWidth: 100,
  cellClassName: 'icon',
  groupable: false,
  aggregable: false,
  sortable: false,
  renderCell: (params) => {
    const { has_smart_summary } = params.row;

    return (
      <Stack direction="row" justifyContent="center" alignItems="center">
        {!documentCanBeProcessed(params.row) ? (
          '-'
        ) : has_smart_summary ? (
          <StatusProcessedIcon />
        ) : (
          <StatusUnProcessedIcon />
        )}
      </Stack>
    );
  },
});
