import React, { useEffect } from 'react';
import { Popup, PropertyDetailsTable } from '@components';
import { IPropertyDetailLocal } from '@interfaces';
import { Button, Stack } from '@mui/material';
import { useProjectDetailsFields } from '@hooks';

const AdditionalPropertyDetailsModal = ({
  propertyDetailsList,
  onClose,
  onSave,
  unitId,
}: {
  propertyDetailsList: IPropertyDetailLocal[];
  onClose: () => void;
  onSave: (propertyDetails: IPropertyDetailLocal[], unitId: string) => void;
  unitId: string;
}) => {
  const { propertyDetails } = useProjectDetailsFields({});

  useEffect(() => {
    propertyDetails.updateList(propertyDetailsList);
  }, [propertyDetailsList]);

  return (
    <Popup open={true} maxWidth="xl" minWidth={1000}>
      <Stack sx={{ width: '100%' }}>
        <Stack sx={{ alignItems: 'center' }}>
          <PropertyDetailsTable
            isEditable
            propertyDetails={propertyDetails}
            isPHBProject={false}
            source="project__create__units__table"
            showAddPropertyDetailButton={false}
          />
        </Stack>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: 4 }}>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="new" color="secondary" onClick={propertyDetails.add} sx={{ ml: 1 }}>
            Add property detail
          </Button>
          <Button onClick={() => onSave(propertyDetails.list, unitId)} sx={{ ml: 1 }}>
            Save
          </Button>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default AdditionalPropertyDetailsModal;
