import React, { FC } from 'react';
import { Button, Dialog, Stack, Typography } from '@mui/material';
import { DrawRequestTypeEnum } from '@interfaces';
import { toggleElementFromArray } from '@utils';
import NewDrawRequest from 'encirclelabs-assets/new_draw_request.svg';
import NewChangeRequest from 'encirclelabs-assets/new_change_request.svg';
import NewRetainageRequest from 'encirclelabs-assets/new_retainage_request.svg';
import { useRequestCreateDialog } from './controller';
import { CloseIcon } from '@svgAsComponents';
import CheckboxWithMedia from './CheckboxWithMedia';
import { IconButtonWithTooltip } from '@components';
import { useLaunchDarklyFlags } from '@context';

const UserFocusRequestCreateDialog: FC<{
  onClose: () => void;
  createRequest: (type: DrawRequestTypeEnum) => void;
  projectId: string;
  source: string;
}> = ({ onClose, createRequest, projectId, source }) => {
  const flags = useLaunchDarklyFlags();
  const {
    retainageRate,
    isReallocationAvailable,
    createType,
    setCreateType,
    shortCreate,
    getCheckboxProps,
  } = useRequestCreateDialog(onClose, createRequest, projectId);

  return (
    <Dialog maxWidth={'lg'} fullWidth={true} open={true}>
      <Stack p={4}>
        <Stack width="100%" alignItems="center" spacing={2} direction="row">
          <Typography flex={1} variant="h2">
            What would you like to include in request?
          </Typography>
          <IconButtonWithTooltip edge="end" onClick={onClose} size="large" tooltipText="Close">
            <CloseIcon />
          </IconButtonWithTooltip>
        </Stack>
        <Typography flex={1} variant="body1">
          Select all that apply. You can make changes later if needed.
        </Typography>
      </Stack>

      <Stack width="100%" spacing={2} p={4}>
        {flags?.['ENG_9252_change_request_configuration_options'] ? (
          <>
            <CheckboxWithMedia
              disabled={getCheckboxProps(DrawRequestTypeEnum.DRAW_REQUEST).disabled}
              tooltipText={getCheckboxProps(DrawRequestTypeEnum.DRAW_REQUEST).tooltipText}
              checked={createType.includes(DrawRequestTypeEnum.DRAW_REQUEST)}
              imagePath={NewDrawRequest}
              title="Draw request"
              descriptions="Request funds from your construction holdback, share construction progress with your lender, satisfy any equity contribution requirements."
              onChange={getCheckboxProps(DrawRequestTypeEnum.DRAW_REQUEST).onChange}
              dataTestName={`${source}__draft_user_focus__check_draw`}
            />
            <CheckboxWithMedia
              disabled={getCheckboxProps(DrawRequestTypeEnum.CHANGE_REQUEST).disabled}
              checked={createType.includes(DrawRequestTypeEnum.CHANGE_REQUEST)}
              imagePath={NewChangeRequest}
              title="Change request"
              descriptions="Request budget changes such as reallocating funds across budget line items."
              tooltipText={getCheckboxProps(DrawRequestTypeEnum.CHANGE_REQUEST).tooltipText}
              onChange={getCheckboxProps(DrawRequestTypeEnum.CHANGE_REQUEST).onChange}
              dataTestName={`${source}__draft_user_focus__check_change`}
            />
            {!!retainageRate && (
              <CheckboxWithMedia
                disabled={
                  createType.includes(DrawRequestTypeEnum.CHANGE_REQUEST) &&
                  !(
                    isReallocationAvailable && createType.includes(DrawRequestTypeEnum.DRAW_REQUEST)
                  )
                }
                checked={createType.includes(DrawRequestTypeEnum.RETAINAGE_REQUEST)}
                imagePath={NewRetainageRequest}
                title="Retainage"
                descriptions="Request release of draw proceeds previously retained."
                onChange={getCheckboxProps(DrawRequestTypeEnum.RETAINAGE_REQUEST).onChange}
                dataTestName={`${source}__draft_user_focus__check_retainage`}
              />
            )}
          </>
        ) : (
          <>
            <CheckboxWithMedia
              disabled={
                createType.includes(DrawRequestTypeEnum.CHANGE_REQUEST) && !isReallocationAvailable
              }
              checked={createType.includes(DrawRequestTypeEnum.DRAW_REQUEST)}
              imagePath={NewDrawRequest}
              title="Draw request"
              descriptions="Request funds from your construction holdback, share construction progress with your lender, satisfy any equity contribution requirements."
              onChange={() =>
                setCreateType((data) =>
                  toggleElementFromArray(data, DrawRequestTypeEnum.DRAW_REQUEST),
                )
              }
              dataTestName={`${source}__draft_user_focus__check_draw`}
            />
            <CheckboxWithMedia
              disabled={
                (!isReallocationAvailable &&
                  createType.includes(DrawRequestTypeEnum.DRAW_REQUEST)) ||
                createType.includes(DrawRequestTypeEnum.RETAINAGE_REQUEST)
              }
              checked={createType.includes(DrawRequestTypeEnum.CHANGE_REQUEST)}
              imagePath={NewChangeRequest}
              title="Change request"
              descriptions="Request budget changes such as reallocating funds across budget line items."
              onChange={() =>
                setCreateType((data) =>
                  toggleElementFromArray(data, DrawRequestTypeEnum.CHANGE_REQUEST),
                )
              }
              dataTestName={`${source}__draft_user_focus__check_change`}
            />
            {!!retainageRate && (
              <CheckboxWithMedia
                disabled={
                  createType.includes(DrawRequestTypeEnum.CHANGE_REQUEST) &&
                  !(
                    isReallocationAvailable && createType.includes(DrawRequestTypeEnum.DRAW_REQUEST)
                  )
                }
                checked={createType.includes(DrawRequestTypeEnum.RETAINAGE_REQUEST)}
                imagePath={NewRetainageRequest}
                title="Retainage"
                descriptions="Request release of draw proceeds previously retained."
                onChange={() =>
                  setCreateType((data) =>
                    toggleElementFromArray(data, DrawRequestTypeEnum.RETAINAGE_REQUEST),
                  )
                }
                dataTestName={`${source}__draft_user_focus__check_retainage`}
              />
            )}
          </>
        )}
        <Typography variant="body1">
          Skip this step to see all available draw table columns on the next screen.
        </Typography>
      </Stack>
      <Stack width="100%" justifyContent="end" spacing={2} p={4} direction="row">
        <Button
          variant="text"
          onClick={() => shortCreate(false)}
          data-cy={`${source}__draft_user_focus__button_skip`}
        >
          Skip
        </Button>
        <Button
          disabled={!createType}
          onClick={() => shortCreate(true)}
          data-cy={`${source}__draft_user_focus__button_approve`}
        >
          Next
        </Button>
      </Stack>
    </Dialog>
  );
};

export default UserFocusRequestCreateDialog;
