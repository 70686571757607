import { GRID_REORDER_COL_DEF, GridColDef } from '@mui/x-data-grid-premium';

export const indexColumn: GridColDef = {
  field: 'id',
  headerName: '',
  filterable: false,
  sortable: false,
  disableColumnMenu: true,
  editable: false,
  hideable: false,
  disableReorder: true,
  align: 'left',
  cellClassName: 'index-column not-selectable',
  headerClassName: 'index-column',
  resizable: false,
  width: 35,
  minWidth: 35,
  disableExport: true,
  renderCell: ({ row }) => (isNaN(+row.index) ? '' : row.index),
};

export const rowOrderColumn: GridColDef = {
  ...GRID_REORDER_COL_DEF,
  width: 30,
  minWidth: 30,
  cellClassName: 'not-selectable',
  headerClassName: 'not-bordered',
};
