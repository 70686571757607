import React, { FC } from 'react';
import {
  Divider,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { HookState, IPermission, PopupTypeEnum } from '@interfaces';
import {
  ConfirmationModal,
  CustomAutocomplete,
  IconButtonWithTooltip,
  LabelAndValue,
  LoadingSkeleton,
  ServiceMessage,
  StyledBox,
} from '@components';
import * as Controller from './controller';
import { ArrowBackIcon, DotIcon } from '@svgAsComponents';
import snakeCase from 'lodash/snakeCase';

const TeamPermissions: FC = () => {
  const {
    state,
    team,
    goBack,
    permissionRole,
    rolesList,
    shownPermissions,
    getLeavePageConfirmModal,
    handleUpdateTeam,
    updatePermission,
    isUpdated,
    isSaving,
    updateGroupPermissions,
    hasCompanyTeamsEditPermission,
    saveChangedPermissionsModal,
  } = Controller.useTeamPermissions();

  switch (state) {
    case HookState.FETCHING: {
      return (
        <StyledBox sx={{ flex: 1 }}>
          <LoadingSkeleton type="overviewBlock" />
        </StyledBox>
      );
    }

    case HookState.ERROR: {
      return <ServiceMessage text="team permissions" />;
    }

    case HookState.SUCCESS: {
      return (
        <Stack spacing={2}>
          <Stack direction="row">
            <IconButtonWithTooltip
              tooltipText="Go back"
              onClick={goBack}
              data-cy="company_teams__permissions_go_back_button"
            >
              <ArrowBackIcon />
            </IconButtonWithTooltip>
            <Typography variant="h1">Permissions</Typography>
          </Stack>

          <StyledBox>
            <Stack
              direction={{ xs: 'column', lg: 'row' }}
              justifyContent="space-between"
              alignItems={{ xs: 'flex-start', lg: 'center' }}
              sx={{ width: '100%' }}
              spacing={{ xs: 2, lg: 0 }}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography
                  variant="body1SemiBold"
                  sx={{ ml: 0.75, whiteSpace: { sm: 'nowrap', xs: 'normal' } }}
                >
                  {team.name}
                </Typography>
                <DotIcon />
                <LabelAndValue
                  size="body1"
                  label="Members:"
                  text={team.members?.length?.toString() || '0'}
                  marginRightAfterLabel={false}
                />
                <DotIcon />
                <LabelAndValue
                  size="body1"
                  label="Role:"
                  text={team.permission_role?.company_role}
                  marginRightAfterLabel={false}
                />
                <DotIcon />
                <LabelAndValue
                  size="body1"
                  label="Approval flow step:"
                  text={team.approval_level?.toString() || 'No participation'}
                  marginRightAfterLabel={false}
                />
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center" sx={{ minWidth: '400px' }}>
                <CustomAutocomplete
                  field={permissionRole}
                  options={rolesList}
                  label="Default roles"
                  disabled={!hasCompanyTeamsEditPermission}
                  inputProps={{
                    'data-cy': 'company_teams__team_permission_role_input',
                  }}
                />
                {hasCompanyTeamsEditPermission && (
                  <LoadingButton
                    loading={isSaving}
                    disabled={!isUpdated}
                    onClick={saveChangedPermissionsModal.askConfirm}
                    data-cy="company_teams__team_permission_save_button"
                  >
                    Save
                  </LoadingButton>
                )}
                <ConfirmationModal
                  open={saveChangedPermissionsModal.isConfirmModalOpened}
                  title="Save permissions changes?"
                  text="This will update the permissions for all members of this team."
                  onClose={saveChangedPermissionsModal.closeConfirmModal}
                  confirmCallback={handleUpdateTeam}
                  type={PopupTypeEnum.CONFIRMATION}
                  isLoading={isSaving}
                  source="company_teams__team_permission__save"
                />
              </Stack>
            </Stack>
          </StyledBox>

          {Object.keys(shownPermissions).map((groupName) => (
            <StyledBox sx={{ p: 0 }} key={groupName}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h3" sx={{ m: 2 }}>
                  {groupName}
                </Typography>
                <Switch
                  sx={{ mr: '15px' }}
                  checked={shownPermissions[groupName].every(
                    (permission: IPermission) => permission.enabled,
                  )}
                  onChange={(event) => updateGroupPermissions(groupName, event.target.checked)}
                  data-cy={`permission_switcher_${snakeCase(groupName)}`}
                  disabled={!hasCompanyTeamsEditPermission}
                />
              </Stack>

              <Divider />

              <TableContainer sx={{ minWidth: '100%' }}>
                <Table>
                  <TableBody>
                    {shownPermissions[groupName]?.map((permission: IPermission) => (
                      <TableRow
                        key={permission.description}
                        sx={{ '&:last-of-type': { '& td': { borderBottom: 'none' } } }}
                      >
                        <TableCell style={{ width: '100%' }}>{permission.description}</TableCell>
                        <TableCell>
                          <Switch
                            checked={permission.enabled}
                            onChange={() => updatePermission(permission)}
                            data-cy={`permission_switcher_${permission.name}`}
                            disabled={!hasCompanyTeamsEditPermission}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </StyledBox>
          ))}

          {getLeavePageConfirmModal()}
        </Stack>
      );
    }

    default:
      return null;
  }
};

export default TeamPermissions;
