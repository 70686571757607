import { BulkUpdateMilesone } from '@interfaces';
import { DataGridPremiumProps, GridRowId, GridValidRowModel } from '@mui/x-data-grid-premium';
import { diffObjects } from '@utils';
import { useCallback, useRef } from 'react';
import React from 'react';

const useTableDataUpdate = (
  setTableLoading: React.Dispatch<React.SetStateAction<boolean>>,
  rowUpdateApi?: ({ id, json }) => void,
  bulkUpdateApi?: (params: Array<BulkUpdateMilesone>) => void,
) => {
  const needToUpdateApi = useRef<boolean>(true);

  const unsavedChangesRef = React.useRef<{
    unsavedRows: Record<GridRowId, GridValidRowModel>;
  }>({
    unsavedRows: {},
  });

  const processRowUpdateApi = useCallback(() => {
    const rowsToUpdate = Object.keys(unsavedChangesRef.current.unsavedRows);
    if (!rowsToUpdate.length) return;
    if (rowsToUpdate.length === 1) {
      rowUpdateApi({
        id: rowsToUpdate[0],
        json: { ...unsavedChangesRef.current.unsavedRows[rowsToUpdate[0]] },
      });
    }
    if (rowsToUpdate.length > 1) {
      const params = rowsToUpdate.map<BulkUpdateMilesone>((item) => ({
        milestone_id: item,
        ...unsavedChangesRef.current.unsavedRows[item],
      }));
      bulkUpdateApi?.(params);
    }
    unsavedChangesRef.current.unsavedRows = {};
    return;
  }, [unsavedChangesRef.current]);

  const processRowUpdate = useCallback<NonNullable<DataGridPremiumProps['processRowUpdate']>>(
    (updatedRow, originalRow) => {
      const rowId = updatedRow.id;
      const delta = diffObjects(updatedRow, originalRow);
      if (Object.keys(delta).length) {
        unsavedChangesRef.current.unsavedRows[rowId] = {
          ...(unsavedChangesRef.current.unsavedRows[rowId] || {}),
          ...delta,
        };
      }
      if (needToUpdateApi.current) processRowUpdateApi();
      return updatedRow;
    },
    [needToUpdateApi.current, processRowUpdateApi, unsavedChangesRef.current],
  );

  const onClipboardPasteEnd = useCallback(() => {
    processRowUpdateApi();
    needToUpdateApi.current = true;
  }, [needToUpdateApi]);

  const onClipboardPasteStart = useCallback(async () => {
    needToUpdateApi.current = false;
  }, [needToUpdateApi]);

  const onBeforeClipboardPasteStart = useCallback(async (params: { data: string[][] }) => {
    const multiColumns = Array.isArray(params.data[0]) && params.data[0].length > 1;
    const isLimites = params.data.length > 150;
    if (multiColumns) {
      window.alert('Past limited to one column.');
      throw new Error('Past limited to one column.');
    }
    const confirmed = window.confirm(
      isLimites
        ? 'Pasting up to 150 rows. Always check the results as source and destination line items may not match exactly'
        : `Pasting ${params.data.length} rows. Always check the results as source and destination line items may not match exactly`,
    );
    if (!confirmed) {
      throw new Error('Paste operation cancelled');
    }
  }, []);

  const rowsReorder = useCallback(
    async (params) => {
      setTableLoading(true);
      const { row, targetIndex } = params;
      await rowUpdateApi({
        id: row.id,
        json: { index: targetIndex + 1 },
      });
      setTableLoading(false);
    },
    [rowUpdateApi],
  );

  return {
    processRowUpdate,
    rowsReorder,
    onClipboardPasteEnd,
    onClipboardPasteStart,
    onBeforeClipboardPasteStart,
  };
};

export default useTableDataUpdate;
