import React from 'react';
import { useParams, Link } from 'react-router-dom';

import { IconButtonWithTooltip } from '@components';
import { PermissionIcon } from '@svgAsComponents';

const PermissionIconWithLink = ({
  teamId,
  dataTestName,
}: {
  teamId: string;
  dataTestName: string;
}) => {
  const { companyId } = useParams();
  const pathBase = `/company/${companyId}/teams`;

  return (
    <Link to={`${pathBase}/${teamId}/permissions`}>
      <IconButtonWithTooltip data-cy={dataTestName} tooltipText="Permissions">
        <PermissionIcon />
      </IconButtonWithTooltip>
    </Link>
  );
};

export default PermissionIconWithLink;
