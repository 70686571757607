import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { format } from 'date-fns';
import {
  getDrawRequestForApproval,
  isActiveProject,
  isRequestApproved,
  isRequestInReview,
} from '@utils';
import { IDrawRequest, IProject, IProjectSettings, QueryNamesEnums } from '@interfaces';
import {
  getProject,
  getProjectDrawRequestsList,
  getProjectSettings,
  updateProjectFields,
} from '@globalService';
import { IChangedData, TChangedData } from '../interface';
import { useSafeSnackbar } from '@hooks';

const requiredKeys = ['status', 'name', 'comments_preview', 'loan'] as const;
type ProjectData = Pick<IProject, (typeof requiredKeys)[number]>;
const requiredSettingsKeys = ['should_display_create_draw_request'] as const;
type ProjectSettings = Pick<IProjectSettings, (typeof requiredSettingsKeys)[number]>;

export interface ControllerInterface {
  showCreateButton: boolean;
  disabledCreateButton: boolean;
  waitingForApproval: boolean;
  changedData: IChangedData | null;
  setChangedData: Dispatch<SetStateAction<IChangedData | null>>;
  updateProjectData: (key: string, value: TChangedData) => void;
}

export const useOverviewTab = (projectId: string): ControllerInterface => {
  const [changedData, setChangedData] = useState<IChangedData | null>(null);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();

  const settingsQuery = useQuery<ProjectSettings, Error>(
    [QueryNamesEnums.GET_PROJECT_SETTINGS, { projectId }],
    getProjectSettings.bind(this, projectId),
  );

  const projectQuery = useQuery<ProjectData, Error>(
    [QueryNamesEnums.GET_PROJECT, { projectId }],
    getProject.bind(this, projectId),
  );

  const drawRequestsQuery = useQuery<{ results: IDrawRequest[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
    getProjectDrawRequestsList.bind(this, projectId),
  );

  const showCreateButton = useMemo(
    () =>
      settingsQuery.data?.should_display_create_draw_request &&
      isActiveProject(projectQuery.data?.status),
    [settingsQuery, projectQuery],
  );

  const disabledCreateButton = useMemo(() => {
    return drawRequestsQuery.data?.results?.some(
      (dr) => isRequestApproved(dr.status) || isRequestInReview(dr.status),
    );
  }, [drawRequestsQuery.data]);

  const waitingForApproval = useMemo(() => {
    return !!getDrawRequestForApproval(drawRequestsQuery.data?.results);
  }, [drawRequestsQuery.data]);

  const updateProjectMutation = useMutation<
    Response,
    Error,
    { projectId: string; json: Partial<IProject> }
  >(updateProjectFields, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT, { projectId }]);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const updateProjectData = (key, value) => {
    const json =
      key === 'extended_maturity_date' || key === 'servicing_status'
        ? {
            loan: {
              ...(key === 'extended_maturity_date'
                ? { extended_maturity_date: format(value, 'yyyy-MM-dd') }
                : {}),
              ...(key === 'servicing_status' ? { servicing_status: value?.name } : {}),
              id: projectQuery.data?.loan?.id,
            },
          }
        : { [key]: key === 'estimated_completion_date' ? format(value, 'yyyy-MM-dd') : value };
    updateProjectMutation.mutate({
      projectId,
      json,
    });
  };

  return {
    showCreateButton,
    disabledCreateButton,
    waitingForApproval,
    changedData,
    setChangedData,
    updateProjectData,
  };
};
