import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { ActionsColumnProps } from '../interface';
import { DocumentActionsCell } from './common';

export const actionsColumn = (props: ActionsColumnProps): GridColDef => ({
  field: 'actions',
  renderHeader: () => null,
  minWidth: 180,
  maxWidth: 180,
  editable: false,
  pinnable: false,
  disableColumnMenu: true,
  sortable: false,
  filterable: false,
  groupable: false,
  aggregable: false,
  hideable: false,
  resizable: false,
  renderCell: (params) => <DocumentActionsCell params={params} {...props} />,
});
