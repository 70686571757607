import React, { FC } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import * as Controller from './controller';
import {
  BorrowerInformation,
  ButtonWithTooltip,
  ConfirmationModal,
  IconButtonWithTooltip,
  Stepper,
} from '@components';
import { LoanDetails, PropertyDetails } from './components';
import { colors } from '@theme';
import { ArrowBackIcon } from '@svgAsComponents';
import { PopupTypeEnum } from '@interfaces';
import NewProjectV2 from '../NewProjectV2';
import { useLaunchDarklyFlags } from '@context';

const NewProject: FC = () => {
  const {
    activeStep,
    steps,
    nextStep,
    prevStep,
    goBack,
    isSubmitting,
    mainRef,
    propertyDetailsFields,
    loanDetailsFields,
    borrowerDetailsFields,
    getLeavePageConfirmModal,
    error,
    setError,
    borrowerEquityLocal,
    isNewBorrower,
    setIsNewBorrower,
    isProductionBuildProject,
    setIsProductionBuildProject,
    borrowerUser,
    setBorrowerUser,
    createProjectWithoutBorrowerModal,
    createProjectSubmit,
    editPaymentConfigurationData,
    inviteBorrowers,
    setInviteBorrowers,
    loan_servicing_statuses,
    loan_types,
  } = Controller.useCreateProject();
  const flags = useLaunchDarklyFlags();
  if (flags?.['ENG_9420_number_of_units_for_all_projects']) return <NewProjectV2 />;

  const stepperButtons = () => (
    <>
      <Button variant="text" disabled={activeStep === 0} onClick={prevStep} sx={{ mr: 1 }}>
        Back
      </Button>

      {activeStep !== steps.length - 1 ? (
        <Button
          variant="new"
          color="secondary"
          onClick={nextStep}
          data-cy="project__create__next_button"
        >
          Next
        </Button>
      ) : (
        <ButtonWithTooltip
          onClick={
            borrowerUser?.id || isNewBorrower
              ? nextStep
              : createProjectWithoutBorrowerModal.askConfirm
          }
          dataTestName="project__create__submit__button"
        >
          {isSubmitting ? 'Adding...' : 'Add project'}
        </ButtonWithTooltip>
      )}
    </>
  );

  return (
    <Stack direction="column" sx={{ height: '100%', scrollMargin: '64px' }} ref={mainRef}>
      <Stack sx={{ p: 2 }} direction="row" alignItems="center">
        <IconButtonWithTooltip
          tooltipText="Go back"
          onClick={goBack}
          data-cy="project__create__go_back_button"
        >
          <ArrowBackIcon />
        </IconButtonWithTooltip>
        <Typography sx={{ ml: 2 }} variant="h1">
          Add new project
        </Typography>
      </Stack>
      <Stepper activeStep={activeStep} steps={steps} buttons={stepperButtons()}>
        <Box
          sx={{
            backgroundColor: colors.background.gray,
            flex: 1,
            ml: -1.5,
            mr: -1.5,
            mt: 2,
            p: 3,
          }}
        >
          {activeStep === 0 && (
            <PropertyDetails
              propertyDetailsFields={propertyDetailsFields}
              isProductionBuildProject={isProductionBuildProject}
              setIsProductionBuildProject={setIsProductionBuildProject}
            />
          )}
          {activeStep === 1 && (
            <LoanDetails
              loanDetailsFields={loanDetailsFields}
              borrowerEquityLocal={borrowerEquityLocal}
              editPaymentConfigurationData={editPaymentConfigurationData}
              loan_servicing_statuses={loan_servicing_statuses}
              loan_types={loan_types}
            />
          )}
          {activeStep === 2 && (
            <BorrowerInformation
              borrowerDetailsFields={borrowerDetailsFields}
              borrowerUser={borrowerUser}
              setBorrowerUser={setBorrowerUser}
              isNewBorrower={isNewBorrower}
              setIsNewBorrower={setIsNewBorrower}
              inviteBorrowers={inviteBorrowers}
              setInviteBorrowers={setInviteBorrowers}
            />
          )}
        </Box>
      </Stepper>
      {getLeavePageConfirmModal()}
      <ConfirmationModal
        title="Create project error"
        text={`There was a problem creating a project.
        ${error}`}
        hideConfirmButton
        open={Boolean(error)}
        onClose={() => setError(null)}
        type={PopupTypeEnum.ERROR}
      />
      <ConfirmationModal
        open={createProjectWithoutBorrowerModal.isConfirmModalOpened}
        title="Confirmation"
        text="You want to add project without Borrower company."
        onClose={createProjectWithoutBorrowerModal.closeConfirmModal}
        confirmCallback={createProjectSubmit}
        type={PopupTypeEnum.CONFIRMATION}
      />
    </Stack>
  );
};

export default NewProject;
