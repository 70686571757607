import { useCallback, useRef, useEffect, useState, useMemo } from 'react';
import { TRIGGERHEIGHT } from '../styled';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';

const ROW_HEIGHT_BASIC = 36;

const useTableScrollPin = (
  apiRef: React.MutableRefObject<GridApiPremium>,
  withoutAdaptiveScroll: boolean,
  rowsLength: number,
) => {
  const containerRef = useRef<HTMLElement>();
  const [onFullScreen, setOnFullScreen] = useState(false);

  const rowBasicHeight = useMemo(() => {
    return apiRef.current?.state?.dimensions?.rowHeight || ROW_HEIGHT_BASIC;
  }, [apiRef.current]);

  const maxRowCount = useMemo(() => {
    if (onFullScreen || withoutAdaptiveScroll) return rowsLength;
    const maxRowsOnScreen = Math.round((window.innerHeight - TRIGGERHEIGHT) / rowBasicHeight);
    return Math.min(rowsLength, maxRowsOnScreen);
  }, [rowsLength, rowBasicHeight, onFullScreen, withoutAdaptiveScroll]);

  const fullScreenAvailable = useMemo(() => {
    const isOverLap = maxRowCount < rowsLength;
    return onFullScreen || (!withoutAdaptiveScroll && isOverLap);
  }, [withoutAdaptiveScroll, rowsLength, maxRowCount, onFullScreen]);

  const containerHeight = useMemo(() => {
    if (withoutAdaptiveScroll) {
      if (!containerRef.current) return undefined;
      return window.innerHeight - (containerRef.current.getBoundingClientRect().top + 40);
    }
    return fullScreenAvailable
      ? TRIGGERHEIGHT + maxRowCount * rowBasicHeight + 3 * rowBasicHeight
      : null;
  }, [
    withoutAdaptiveScroll,
    containerRef.current,
    maxRowCount,
    rowBasicHeight,
    fullScreenAvailable,
  ]);

  const checkPosition = useCallback(() => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const isPinned = rect.top <= TRIGGERHEIGHT;
      if (isPinned) {
        setOnFullScreen(true);
      }
    }
  }, [containerRef]);

  const scrollToLastIndex = useCallback(() => {
    if (fullScreenAvailable) {
      setOnFullScreen(true);
    }
    apiRef.current.scrollToIndexes({
      rowIndex: rowsLength,
    });
  }, [fullScreenAvailable, apiRef.current]);

  useEffect(() => {
    if (withoutAdaptiveScroll || onFullScreen || !fullScreenAvailable) return;
    window.addEventListener('scroll', checkPosition);
    return () => {
      window.removeEventListener('scroll', checkPosition);
    };
  }, [withoutAdaptiveScroll, onFullScreen, fullScreenAvailable]);

  return {
    checkPosition,
    containerRef,
    containerHeight,
    maxRowCount,
    onFullScreen,
    scrollToLastIndex,
    setOnFullScreen: fullScreenAvailable ? setOnFullScreen : null,
  };
};

export default useTableScrollPin;
