import React from 'react';
import { Stack, Typography, Switch, FormControlLabel } from '@mui/material';

import { StyledBox, TabWrapperWithLeaveWarning } from '@components';
import * as Controller from './controller';
import { typography } from '@theme';

const OtherSettings = ({ project, isAllProjectDetailsDisabled }) => {
  const controller = Controller.useOtherSettings({ project });
  const {
    setIsBatchSubmissionEnabled,
    isBatchSubmissionEnabled,
    handleSubmitClick,
    isSubmitting,
    isUpdated,
    exitPath,
  } = controller;

  return (
    <TabWrapperWithLeaveWarning
      onSave={handleSubmitClick}
      isUpdated={isUpdated}
      tabValue="general"
      path={exitPath}
      isSubmitting={isSubmitting}
      currentPagePathname={'/projects/*/settings/general'}
    >
      <StyledBox>
        <Stack>
          <Typography variant="h3">Draw settings</Typography>
          <FormControlLabel
            sx={{
              mt: 2,
              '& .MuiTypography-root': {
                ...typography.body3,
              },
            }}
            control={
              <Switch
                sx={{ mr: '15px' }}
                checked={isBatchSubmissionEnabled}
                onChange={(event) => setIsBatchSubmissionEnabled(event.target.checked)}
                data-cy="project__settings__general__batch_submission__switch"
                disabled={isAllProjectDetailsDisabled}
              />
            }
            label="Enable 'lump sum' submission"
            disabled={isAllProjectDetailsDisabled}
          />
        </Stack>
      </StyledBox>
    </TabWrapperWithLeaveWarning>
  );
};

export default OtherSettings;
