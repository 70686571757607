import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { EventType, IWSMessageData } from '@interfaces';
import {
  useAllQueriesInvalidation,
  useAssignCoordinatorInvalidation,
  useDocumentUploadedInvalidation,
  useInspectionStatusInvalidation,
  useReviewRequestInvalidation,
  useServiceOrderStatusInvalidation,
  useSwitchReviewerInvalidation,
  useUpdateProjectStatusInvalidation,
} from '@hooks';

export const useEventInvalidationHandlers = () => {
  const navigate = useNavigate();
  const handleProjectStatusUpdateInvalidation = useUpdateProjectStatusInvalidation();
  const handleRequestReviewInvalidation = useReviewRequestInvalidation();
  const handleAssignCoordinatorInvalidation = useAssignCoordinatorInvalidation();
  const handleDocumentUploadedInvalidation = useDocumentUploadedInvalidation();
  const handleSwitchApproverInvalidation = useSwitchReviewerInvalidation();
  const handleInspectionStatusChangeInvalidation = useInspectionStatusInvalidation();
  const handleServiceOrderChangeInvalidation = useServiceOrderStatusInvalidation();
  const handleAllQueriesInvalidation = useAllQueriesInvalidation();

  const projectEventInvalidation = useCallback(
    (params: IWSMessageData) =>
      handleProjectStatusUpdateInvalidation({ projectId: params?.project?.id }),
    [handleProjectStatusUpdateInvalidation],
  );

  const requestStatusEventInvalidation = useCallback(
    (params: IWSMessageData) => {
      if (
        params.event_type === EventType.DRAW_REQUEST_DELETED &&
        window.location.href.includes(params?.draw_request?.id)
      ) {
        navigate(`/projects/${params?.project?.id}/overview`);
      }
      handleRequestReviewInvalidation({
        projectId: params?.project?.id,
        drawRequestId: params?.draw_request?.id,
        event_type: params.event_type,
      });
    },
    [navigate, handleRequestReviewInvalidation],
  );

  const assignCoordinatorEventInvalidation = useCallback(
    (params: IWSMessageData) =>
      handleAssignCoordinatorInvalidation({
        projectId: params?.project?.id,
        drawRequestId: params?.draw_request?.id,
      }),
    [handleAssignCoordinatorInvalidation],
  );

  const switchApproverEventInvalidation = useCallback(
    (params: IWSMessageData) =>
      handleSwitchApproverInvalidation({
        projectId: params?.project?.id,
        drawRequestId: params?.draw_request?.id,
      }),
    [handleSwitchApproverInvalidation],
  );

  const documentUploadedEventInvalidation = useCallback(
    (params: IWSMessageData) =>
      handleDocumentUploadedInvalidation({
        projectId: params?.project?.id,
        document_type: params?.document_type,
        is_progress_photo: params?.is_progress_photo,
        drawRequestId: params?.draw_request?.id,
        milestoneId: params?.milestone?.id,
        inspectionId: params?.inspection?.id,
        serviceOrderId: params?.service_order?.id,
        milestoneGroupId: params?.milestone_group?.id,
      }),
    [handleDocumentUploadedInvalidation],
  );

  const inspectionStatusEventInvalidation = useCallback(
    (params: IWSMessageData) =>
      handleInspectionStatusChangeInvalidation({
        projectId: params?.project?.id,
        drawRequestId: params?.draw_request?.id,
        inspectionId: params?.inspection?.id,
      }),
    [handleInspectionStatusChangeInvalidation],
  );

  const serviceEventInvalidation = useCallback(
    (params: IWSMessageData) =>
      handleServiceOrderChangeInvalidation({
        projectId: params?.project?.id,
        drawRequestId: params?.draw_request?.id,
        serviceOrderId: params?.service_order?.id,
      }),
    [handleServiceOrderChangeInvalidation],
  );

  const invalidateQueriesByEventType = {
    [EventType.PROJECT_ACTIVATED]: projectEventInvalidation,
    [EventType.PROJECT_DEACTIVATED]: projectEventInvalidation,
    [EventType.PROJECT_PAUSED]: projectEventInvalidation,
    [EventType.PROJECT_ARCHIVED]: projectEventInvalidation,
    [EventType.PROJECT_COMPLETED]: projectEventInvalidation,
    [EventType.PROJECT_CREATED]: projectEventInvalidation,
    [EventType.DRAW_REQUEST_SUBMITTED]: requestStatusEventInvalidation,
    [EventType.DRAW_REQUEST_RETURNED]: requestStatusEventInvalidation,
    [EventType.DRAW_REQUEST_FINALIZED]: requestStatusEventInvalidation,
    [EventType.DRAW_REQUEST_APPROVED]: requestStatusEventInvalidation,
    [EventType.DRAW_REQUEST_MARKED_AS_DISBURSED]: requestStatusEventInvalidation,
    [EventType.DRAW_REQUEST_DELETED]: requestStatusEventInvalidation,
    [EventType.DRAW_REQUEST_CREATED]: requestStatusEventInvalidation,
    [EventType.DRAW_REQUEST_IS_ON_HOLD_CHANGED]: requestStatusEventInvalidation,
    [EventType.DRAW_REQUEST_COORDINATOR_CHANGED]: assignCoordinatorEventInvalidation,
    [EventType.DRAW_REQUEST_REVIEWER_TEAM_CHANGED]: switchApproverEventInvalidation,
    [EventType.DOCUMENT_ADDED]: documentUploadedEventInvalidation,
    [EventType.INSPECTION_CANCELLED]: inspectionStatusEventInvalidation,
    [EventType.INSPECTION_CANCEL_REQUESTED]: inspectionStatusEventInvalidation,
    [EventType.INSPECTION_COMPLETED]: inspectionStatusEventInvalidation,
    [EventType.INSPECTION_ERRORED]: inspectionStatusEventInvalidation,
    [EventType.INSPECTION_ORDERED]: inspectionStatusEventInvalidation,
    [EventType.INSPECTION_READY_FOR_REVIEW]: inspectionStatusEventInvalidation,
    [EventType.API_LOGOUT]: handleAllQueriesInvalidation,
    [EventType.SERVICE_ORDER_CANCELLED]: serviceEventInvalidation,
    [EventType.SERVICE_ORDER_CANCEL_REQUESTED]: serviceEventInvalidation,
    [EventType.SERVICE_ORDER_CHANGED]: serviceEventInvalidation,
    [EventType.SERVICE_ORDER_COMPLETED]: serviceEventInvalidation,
    [EventType.SERVICE_ORDER_ERRORED]: serviceEventInvalidation,
    [EventType.SERVICE_ORDER_ORDERED]: serviceEventInvalidation,
    [EventType.SERVICE_ORDER_PROCESSING]: serviceEventInvalidation,
  };

  return { invalidateQueriesByEventType };
};
