import React, { FC } from 'react';

import { ComponentProps } from './interface';
import { IconButtonWithTooltip, VerticalMenu } from '@components';
import { SortIcon } from '@svgAsComponents';

const SortingIconWithPopper: FC<ComponentProps> = ({
  value,
  menuItems,
  cypressSelector,
  disabled,
}) => {
  return (
    <VerticalMenu
      menuItems={menuItems}
      closeOnItemClick
      anchorOriginHorizontal="left"
      activeItem={value}
    >
      <IconButtonWithTooltip
        data-cy={cypressSelector}
        size="small"
        disabled={disabled}
        tooltipText="Sort by date"
      >
        <SortIcon size={16} />
      </IconButtonWithTooltip>
    </VerticalMenu>
  );
};

export default SortingIconWithPopper;
