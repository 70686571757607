import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { BaseColumnProps } from '../interface';
import { UploadedByCell } from './common';

export const uploadedByColumn = ({ source }: BaseColumnProps): GridColDef => ({
  field: 'created_by',
  headerName: 'Uploaded by',
  minWidth: 180,
  groupable: false,
  aggregable: false,
  sortable: false,
  renderCell: (params) => <UploadedByCell params={params} source={source} />,
});
