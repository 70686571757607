import React, { Dispatch, FC } from 'react';
import { Box, CardMedia, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { ICompanyFull } from '@interfaces';
import {
  CompanyAddressIcon,
  DotIcon,
  EditIcon,
  EmailIcon,
  PhoneIcon,
  SiteIcon,
} from '@svgAsComponents';
import { getAddress, phoneFormatter } from '@utils';
import { IconButtonWithTooltip } from '@components';
interface ComponentProps {
  company: ICompanyFull;
  isEditAllowed: boolean;
  setOpenCompanyInfoPopup?: Dispatch<React.SetStateAction<boolean>>;
  source: string;
}

const CompanyInfoBlock: FC<ComponentProps> = ({
  company,
  isEditAllowed,
  setOpenCompanyInfoPopup,
  source,
}) => {
  const lgMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const mdMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  return (
    <Stack sx={{ minHeight: '100px' }} direction={lgMediaQuery ? 'row' : 'column'} spacing={3}>
      {company?.logo && (
        <CardMedia
          component="img"
          height="100"
          image={company.logo}
          alt="Company logo"
          sx={{ width: 100, objectFit: 'contain' }}
        />
      )}

      <Stack spacing={1} sx={{ flex: 1, flexDirection: 'column' }}>
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography variant="h2" sx={{ mr: 2 }}>
            {company?.name}
          </Typography>
          {isEditAllowed && (
            <IconButtonWithTooltip
              onClick={() => setOpenCompanyInfoPopup(true)}
              data-cy={`${source}__company_info__edit_icon`}
              tooltipText="Edit"
            >
              <EditIcon size={24} />
            </IconButtonWithTooltip>
          )}
        </Stack>
        <Stack
          sx={{
            flexWrap: 'wrap',
            alignItems: { md: 'center', sm: 'flex-start' },
            justifyContent: 'flex-start',
          }}
          direction={mdMediaQuery ? 'row' : 'column'}
        >
          {company?.phone && (
            <Stack flexDirection="row" alignItems="center" justifyContent="flex-start" mt={1}>
              <PhoneIcon />
              <Typography variant="body1" sx={{ ml: 0.75 }}>
                {phoneFormatter(company.phone)}
              </Typography>
            </Stack>
          )}
          {company?.address && (
            <Stack flexDirection="row" alignItems="center" justifyContent="flex-start" mt={1}>
              {company?.phone && (
                <Box sx={{ mr: 2, ml: 2 }}>
                  <DotIcon />
                </Box>
              )}
              <CompanyAddressIcon />
              <Typography
                variant="body1"
                sx={{ ml: 0.75, whiteSpace: { sm: 'nowrap', xs: 'normal' } }}
              >
                {company.address.full_address || getAddress(company.address)}
              </Typography>
            </Stack>
          )}
          {company?.email && (
            <Stack flexDirection="row" alignItems="center" justifyContent="flex-start" mt={1}>
              {(company?.phone || company?.address) && (
                <Box sx={{ mr: 2, ml: 2 }}>
                  <DotIcon />
                </Box>
              )}
              <EmailIcon />
              <Typography
                variant="body1"
                sx={{
                  ml: 0.75,
                  overflowWrap: 'anywhere',
                  whiteSpace: { sm: 'nowrap', xs: 'normal' },
                }}
              >
                {company.email}
              </Typography>
            </Stack>
          )}
          {company?.url && (
            <Stack flexDirection="row" alignItems="center" justifyContent="flex-start" mt={1}>
              <Box sx={{ mr: 2, ml: 2 }}>
                <DotIcon />
              </Box>
              <SiteIcon />
              <Typography
                variant="body1"
                sx={{
                  ml: 0.75,
                }}
              >
                {company.url}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CompanyInfoBlock;
