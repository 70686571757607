import { useEffect, useMemo, useState } from 'react';
import { useQueries, useQuery } from 'react-query';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import sortBy from 'lodash/sortBy';

import { useDropdownFieldModel, useStringFieldModel } from '@models';
import {
  EnumTypeForList,
  IProject,
  IProjectDetailsFields,
  IProjectProperty,
  IPropertyDetailLocal,
  PropertyDetailsEnum,
  QueryNamesEnums,
} from '@interfaces';
import { isSQFTDetail, regexValidation, sortPropertiesWithSQFTFirst } from '@utils';
import { states } from '@constants';
import {
  getProjectBuildings,
  getProjectExitStrategiesList,
  getProjectPropertyDetailsNames,
  getProjectPropertyTypes,
  getProjectTypes,
} from '@globalService';

interface HookInterface {
  projectDetailsFields: IProjectDetailsFields;
  isProjectDetailsUpdated: boolean;
  exitStrategiesList: EnumTypeForList[];
  projectTypesList: EnumTypeForList[];
  propertyTypesList: EnumTypeForList[];
  propertyDetailsNames: EnumTypeForList[];
  projectProperty: IProjectProperty;
  propertyDetails: {
    update: (index: number, data: IPropertyDetailLocal) => void;
    updateList: (propertyDetailsList: IPropertyDetailLocal[]) => void;
    delete: (index: number) => void;
    add: () => void;
    names: EnumTypeForList[];
    list: IPropertyDetailLocal[];
    isEmptyDetailExist: boolean;
    id: string;
    isChanged: boolean;
  };
  isAllProjectDataValid: boolean;
}

export const useProjectDetailsFields = ({ project }: { project?: IProject }): HookInterface => {
  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_TYPES],
      queryFn: getProjectTypes.bind(this, ''),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_PROPERTY_TYPES],
      queryFn: getProjectPropertyTypes.bind(this, ''),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_PROPERTY_DETAILS_NAMES],
      queryFn: getProjectPropertyDetailsNames,
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_EXIT_STRATEGIES],
      queryFn: getProjectExitStrategiesList,
    },
  ]);

  const projectTypesQuery = requestedDataQueries[0].data;
  const propertyTypesQuery = requestedDataQueries[1].data;
  const propertyDetailsNamesQuery = requestedDataQueries[2].data;
  const exitStrategiesQuery = requestedDataQueries[3].data;

  const scopeOfWork = useStringFieldModel({
    initValue: project?.scope_of_work,
  });

  const projectName = useStringFieldModel({
    initValue: project?.name,
    validationRule: (value) => Boolean(value?.trim()),
  });

  // project type
  const projectType = useDropdownFieldModel({
    initValue: null,
    validationRule: (value) => Boolean(value?.id),
  });
  useEffect(() => {
    if (projectTypesQuery?.results) {
      const projectTypeValue = find(projectTypesQuery.results, { name_display: project?.type });
      projectType.setInitValue(projectTypeValue);
    }
  }, [projectTypesQuery]);

  // exit strategy
  const exitStrategy = useDropdownFieldModel({
    initValue: null,
  });
  useEffect(() => {
    if (exitStrategiesQuery?.results) {
      const exitStrategyValue = find(exitStrategiesQuery.results, {
        name_display: project?.exit_strategy,
      });
      exitStrategy.setInitValue(exitStrategyValue);
    }
  }, [exitStrategiesQuery]);

  // property types
  const existingPropertyType = useDropdownFieldModel({
    initValue: null,
    validationRule: (value) => Boolean(value?.id),
  });
  const proposedPropertyType = useDropdownFieldModel({
    initValue: null,
    validationRule: (value) => Boolean(value?.id),
  });
  useEffect(() => {
    if (propertyTypesQuery?.results) {
      const existingPropertyTypeValue = find(propertyTypesQuery.results, {
        name_display: project?.property_existing_type,
      });
      existingPropertyTypeValue && existingPropertyType.setInitValue(existingPropertyTypeValue);

      const proposedPropertyTypeValue = find(propertyTypesQuery.results, {
        name_display: project?.property_proposed_type,
      });
      proposedPropertyTypeValue && proposedPropertyType.setInitValue(proposedPropertyTypeValue);
    }
  }, [propertyTypesQuery]);

  // address
  const address_1 = useStringFieldModel({
    initValue: project?.address?.address_1 || '',
    validationRule: (value) => Boolean(value?.trim()),
    validateOnChange: true,
  });
  const city = useStringFieldModel({
    initValue: project?.address?.city || '',
    validationRule: (value) => Boolean(value?.trim()),
    validateOnChange: true,
  });
  const state = useDropdownFieldModel({
    initValue: project?.address?.state
      ? states.find((state) => state.name === project?.address?.state)
      : null,
    validationRule: (value) => Boolean(value?.name),
  });
  const zipCode = useStringFieldModel({
    initValue: project?.address?.zip_code || '',
    validationRule: (value) => regexValidation('zip_code', value) && Boolean(value),
    validateOnChange: true,
  });

  // property details names
  const [propertyDetailsLocal, setPropertyDetailsLocal] = useState<IPropertyDetailLocal[]>([]);

  const projectPropertyQuery = useQuery<{ results: IProjectProperty[]; count: number }, Error>(
    [QueryNamesEnums.GET_PROJECT_BUILDING, { projectId: project?.id }],
    getProjectBuildings.bind(this, project?.id),
    { enabled: Boolean(project?.id) },
  );

  useEffect(() => {
    const names = propertyDetailsNamesQuery?.results;
    if (!names?.length) return;
    const projectDetails = projectPropertyQuery.data?.results?.[0]?.details;
    let details = [];
    if (projectDetails) {
      details = projectDetails.map((detail) => ({
        ...detail,
        id: names.find((item) => item.name === detail.name)?.id,
      }));
    }
    if (!find(details, { name: PropertyDetailsEnum.SQFT })) {
      details.push({
        id: names.find((item) => isSQFTDetail(item.name))?.id,
        name: PropertyDetailsEnum.SQFT,
        name_display: PropertyDetailsEnum.SQFT,
        existing_value: '',
        proposed_value: '',
      });
    }

    setPropertyDetailsLocal(sortPropertiesWithSQFTFirst(details));
  }, [projectPropertyQuery.data?.results, propertyDetailsNamesQuery?.results]);

  const updatePropertyDetail = (index: number, data: IPropertyDetailLocal) =>
    setPropertyDetailsLocal((details) =>
      Object.assign([...details], {
        [index]: data,
      }),
    );

  const updatePropertyDetailList = (propertyDetailsList: IPropertyDetailLocal[]) => {
    setPropertyDetailsLocal(propertyDetailsList);
  };

  const isEmptyDetailExist = useMemo(
    () =>
      propertyDetailsLocal?.some(
        (detail) =>
          !isSQFTDetail(detail.name) &&
          (!detail.name || !detail.existing_value || !detail.proposed_value),
      ),
    [propertyDetailsLocal],
  );

  const deletePropertyDetail = (index: number) =>
    setPropertyDetailsLocal(propertyDetailsLocal.filter((_, i) => i !== index));

  const addPropertyDetail = () => {
    if (isEmptyDetailExist) return;
    setPropertyDetailsLocal((details) => [
      ...details,
      {
        name_display: '',
        name: '',
        existing_value: '',
        proposed_value: '',
      },
    ]);
  };

  const areArraysEquivalent = (arr1, arr2) => {
    // Sort both arrays by their properties (excluding 'id' and 'disabled') to ensure order doesn't affect comparison

    const processArray = (arr) =>
      sortBy(
        arr
          ?.filter((o) => o.name !== PropertyDetailsEnum.SQFT)
          ?.map((item) => omit(item, 'id', 'disabled')),
        Object.keys,
      );
    // Compare the sorted arrays with the custom comparator
    return isEqual(processArray(arr1), processArray(arr2));
  };

  const isPropertyDetailsChanged = useMemo(() => {
    const projectDetails = projectPropertyQuery.data?.results?.[0]?.details;
    const sqft1 = projectDetails?.find((item) => item.name === PropertyDetailsEnum.SQFT) || {
      existing_value: '',
      proposed_value: '',
    };
    const sqft2 = propertyDetailsLocal?.find((item) => item.name === PropertyDetailsEnum.SQFT);

    return (
      !areArraysEquivalent(propertyDetailsLocal, projectDetails) ||
      !(
        sqft1?.existing_value == sqft2?.existing_value &&
        sqft1?.proposed_value == sqft2?.proposed_value
      )
    );
  }, [propertyDetailsLocal, projectPropertyQuery.data?.results]);

  // check if project details are updated
  const isProjectDetailsUpdated = useMemo(
    () =>
      [
        scopeOfWork,
        projectName,
        exitStrategy,
        projectType,
        existingPropertyType,
        proposedPropertyType,
        address_1,
        city,
        state,
        zipCode,
      ].some((field) => field.isChanged) || isPropertyDetailsChanged,
    [
      scopeOfWork.isChanged,
      projectName.isChanged,
      exitStrategy.isChanged,
      projectType.isChanged,
      existingPropertyType.isChanged,
      proposedPropertyType.isChanged,
      address_1.isChanged,
      city.isChanged,
      state.isChanged,
      zipCode.isChanged,
      isPropertyDetailsChanged,
    ],
  );

  // exсepting property details (isEmptyDetailExist) from the comparison
  // their errors are displayed in snackbar because they cann't be errored in red during validation
  const isAllProjectDataValid = useMemo(
    () =>
      [
        scopeOfWork,
        projectName,
        exitStrategy,
        projectType,
        existingPropertyType,
        proposedPropertyType,
        address_1,
        city,
        state,
        zipCode,
      ].every((field) => field.isValid),
    [
      scopeOfWork.isValid,
      projectName.isValid,
      exitStrategy.isValid,
      projectType.isValid,
      existingPropertyType.isValid,
      proposedPropertyType.isValid,
      address_1.isValid,
      city.isValid,
      state.isValid,
      zipCode.isValid,
    ],
  );

  return {
    projectDetailsFields: {
      projectName,
      scopeOfWork,
      exitStrategy,
      projectType,
      existingPropertyType,
      proposedPropertyType,
      address_1,
      city,
      state,
      zipCode,
    },
    exitStrategiesList: exitStrategiesQuery?.results || [],
    projectTypesList: projectTypesQuery?.results || [],
    propertyTypesList: propertyTypesQuery?.results || [],
    propertyDetailsNames: propertyDetailsNamesQuery?.results,
    projectProperty: projectPropertyQuery.data?.results?.[0],
    isProjectDetailsUpdated,
    propertyDetails: {
      update: updatePropertyDetail,
      updateList: updatePropertyDetailList,
      delete: deletePropertyDetail,
      add: addPropertyDetail,
      names:
        propertyDetailsNamesQuery?.results?.map((item) => ({
          ...item,
          disabled: propertyDetailsLocal.map((detail) => detail.name).includes(item.name),
        })) || [],
      list: propertyDetailsLocal,
      isEmptyDetailExist,
      id: projectPropertyQuery.data?.results?.[0]?.id,
      isChanged: isPropertyDetailsChanged,
    },
    isAllProjectDataValid,
  };
};
