import { CellRenderWithError, MilestoneListColumnTypeV2 } from './common';
import { GridEditInputCell } from '@mui/x-data-grid';
import { notAllowNegative } from '@utils';
import { DigitInput } from '@components';
import React from 'react';

const bedrooms: MilestoneListColumnTypeV2 = {
  field: 'bedrooms',
  headerName: '# of Bedrooms',
  description: '',
  headerClassName: 'number',
  cellClassName: 'number',
  headerAlign: 'left',
  align: 'left',
  flex: 1,
  resizable: false,
  editable: true,
  sortable: true,
  filterable: true,
  groupable: false,
  aggregable: true,
  hideable: false,
  minWidth: 120,
  maxWidth: 120,
  renderCell: (params) => <CellRenderWithError params={params} />,
  renderEditCell: (props) => (
    <GridEditInputCell
      {...props}
      inputProps={{
        isAllowed: notAllowNegative,
      }}
      inputComponent={DigitInput}
    />
  ),
  editableByMilestone: (row) => row.isEditableV2 && !row?.parentId,
};

export default bedrooms;
