import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

import { useLogout } from '@hooks';

type ButtonVariantInterface = 'text' | 'outlined' | 'contained' | 'new' | 'secondaryNew';

const BackToLoginButton: FC<{ withLogout?: boolean; variant?: ButtonVariantInterface }> = ({
  withLogout,
  variant = 'text',
}) => {
  const navigate = useNavigate();
  const { logout } = useLogout();

  const goToLogin = () => {
    if (withLogout) logout();
    navigate('/login');
  };

  return (
    <Button fullWidth variant={variant} onClick={goToLogin} sx={{ mt: 2 }}>
      Back to log in
    </Button>
  );
};

export default BackToLoginButton;
