import React from 'react';
import { Stack, Typography } from '@mui/material';
import { PoliciesList, PoliciesListEditMode, StyledBox, IconButtonWithTooltip } from '@components';
import { EditIcon, NoDataImage } from '@svgAsComponents';
import * as Controller from './controller';
import { colors } from '@theme';
import { PolicyContainerProps } from './interface';

const PolicyContainer = ({
  policyLists,
  title,
  assigneeRole,
  type,
  source,
  editMode,
  setEditMode,
  handleCloseEdit,
}: PolicyContainerProps) => {
  const controller = Controller.usePolicyContainer({
    handleCloseEdit,
    policyLists,
    assigneeRole,
    type,
  });
  const { activeTemplate, handleSave, isMutating, isProjectActivation } = controller;
  const isEditing = editMode === source;

  return (
    <StyledBox
      flex={1}
      sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'inherit' }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
        <Typography variant="h3">{title}</Typography>
        <Stack direction="row">
          {!isEditing && activeTemplate?.id && (
            <IconButtonWithTooltip
              onClick={() => setEditMode(source)}
              data-cy={`${source}_policy__list__edit__button`}
              tooltipText="Edit"
            >
              <EditIcon size={20} color={colors.icons.green} />
            </IconButtonWithTooltip>
          )}
        </Stack>
      </Stack>

      {!isEditing &&
        (activeTemplate?.items?.length ? (
          <PoliciesList
            items={activeTemplate?.items}
            policiesId={activeTemplate?.id}
            isTemplate
            source={source}
            isLoading={isMutating}
          />
        ) : (
          <Stack sx={{ flexGrow: 1 }} justifyContent="center" alignItems="center">
            <NoDataImage size={200} />
            <Typography sx={{ mt: 4, textAlign: 'center' }} variant="body2SemiBold">
              No policy set up
            </Typography>
          </Stack>
        ))}
      {isEditing && (
        <PoliciesListEditMode
          isCompanyPolicyTemplate
          policiesList={activeTemplate}
          handleSave={handleSave(activeTemplate?.id)}
          handleCloseEdit={handleCloseEdit}
          source={source}
          isPlatformPolicy
          isProjectActivation={isProjectActivation}
        />
      )}
    </StyledBox>
  );
};

export default PolicyContainer;
