import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { ButtonBase, Popper } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Box from '@mui/material/Box';
import { getImageUrl } from '@utils';
import { MediaDetails } from '@components';
import { PanoramicPhotoIcon, SuccessIcon } from '@svgAsComponents';
import { colors } from '@theme';
import { ImageSizeEnum } from '@interfaces';

import { PhotoCardProps, PhotoProps } from './interface';

const IconSizes = {
  small: 20,
  medium: 24,
};

const ImageButton = styled(ButtonBase)(() => ({
  position: 'relative',
  width: '100%',
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}));

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
});

type SpanProps = {
  size: string;
  color: string;
  right?: number;
};

const IconWrapper = styled('span')<SpanProps>(({ size, color, right = 0 }) => ({
  position: 'absolute',
  right,
  bottom: 0,
  width: IconSizes[size],
  height: IconSizes[size],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: color,
}));

const StyledPopperDiv = styled('div')(
  () => `
  background-color: ${colors.background.white};
  border-radius: 4px;
  padding: 16px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
`,
);

const VerifiedLocation = ({ size, color }) => (
  <IconWrapper size={size} color={color}>
    <SuccessIcon color={colors.neutral.white} size={13} />
  </IconWrapper>
);

const PanoramicPhoto = ({ size, color, rightMargin }) => (
  <IconWrapper size={size} color={color} right={rightMargin}>
    <PanoramicPhotoIcon />
  </IconWrapper>
);

export const PhotoCard = ({
  photo,
  size,
  handleHover,
  handleClose,
  dataTestName,
  backgroundColor,
}: PhotoCardProps) => {
  const showVerifiedIcon =
    photo &&
    'location' in photo &&
    !isEmpty(photo['location']) &&
    photo['location']['lat'] &&
    photo['location']['long'];

  const isVideo = useMemo(
    () => Boolean(photo.file_representations.webm),
    [photo.file_representations.webm],
  );

  const imageType = useMemo(
    () => (isVideo ? ImageSizeEnum.THUMBNAIL : ImageSizeEnum.THUMB),
    [isVideo],
  );

  return (
    <ImageButton
      focusRipple
      onMouseEnter={handleHover}
      onMouseLeave={handleClose}
      style={{
        height: size === 'small' ? '60px' : '146px',
        backgroundColor: colors.background.gray,
        position: 'relative',
      }}
    >
      <ImageSrc
        data-cy={dataTestName}
        style={{
          backgroundImage: `url(${getImageUrl(imageType, photo.file_representations)})`,
          backgroundColor,
        }}
      />
      {isVideo && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            borderRadius: '50%',
            width: size === 'small' ? '24px' : '40px',
            height: size === 'small' ? '24px' : '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <PlayArrowIcon sx={{ fontSize: size === 'small' ? '16px' : '24px' }} />
        </Box>
      )}
      {photo?.is_panorama && (
        <PanoramicPhoto
          size={size}
          color={colors.status.information.medium}
          rightMargin={showVerifiedIcon ? 30 : 0}
        />
      )}
      {showVerifiedIcon && (
        <VerifiedLocation
          size={size}
          color={
            photo.location?.is_verified ? colors.status.success.medium : colors.status.orange.medium
          }
        />
      )}
    </ImageButton>
  );
};

export const PhotoCardWithPopper = ({ photo, size, dataTestName }: PhotoProps) => {
  const handleHover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!photo) return null;
  return (
    <>
      <PhotoCard
        photo={photo}
        size={size}
        handleHover={handleHover}
        handleClose={handleClose}
        dataTestName={dataTestName}
      />

      <Popper id={id} open={open} anchorEl={anchorEl} placement="top-end" style={{ zIndex: 1300 }}>
        <StyledPopperDiv sx={{ width: 350 }}>
          <MediaDetails media={photo} size="small" mediaType="Photo" />
        </StyledPopperDiv>
      </Popper>
    </>
  );
};
