import { useState, useMemo, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

import { IDrawRequest, QueryNamesEnums, BulkDrawRequestPathParam } from '@interfaces';
import { pathcDrawRequestBatch } from '@globalService';
import { StringFieldModel, useStringFieldModel } from '@models';
import { SettingsContext } from '@context';

interface UseLumpSumModalProps {
  onClose?: () => void;
  onSuccess?: () => void;
  fieldKey: string;
  initValue: string;
}

interface ControllerInterface {
  lumpSumField: StringFieldModel;
  lumpSumError: string | null;
  onEnterLumpSum: () => Promise<void>;
  onResetLumpSum: () => Promise<void>;
  values: {
    title: string;
    text: string;
  };
  isSubmitting: boolean;
}

export const useLumpSumModal = ({
  onClose,
  onSuccess,
  fieldKey,
  initValue,
}: UseLumpSumModalProps): ControllerInterface => {
  const { projectId, requestId: drawRequestId } = useParams();
  const { isPHBProject } = useContext(SettingsContext);

  const [lumpSumError, setLumpSumError] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const lumpSumField = useStringFieldModel({
    initValue: initValue === '0' ? '' : initValue,
  });

  const batchRequestMutation = useMutation<IDrawRequest, Error, BulkDrawRequestPathParam>(
    pathcDrawRequestBatch,
    {
      onSuccess: () => {
        // PHB project invalidations are handled in onSuccess callback
        if (!isPHBProject) {
          queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES);
        }
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST,
          { projectId, drawRequestId },
        ]);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES_COLUMNS,
          { projectId, requestId: drawRequestId },
        ]);
        queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_LIST);
        onSuccess?.();
        onClose?.();
      },
      onError: (error) => setLumpSumError(error.message),
    },
  );

  const onEnterLumpSum = async () => {
    batchRequestMutation.mutateAsync({
      projectId,
      drawRequestId,
      json: { [fieldKey]: +lumpSumField.value },
    });
  };

  const onResetLumpSum = async () => {
    lumpSumField.setValue('0');
    batchRequestMutation.mutateAsync({
      projectId,
      drawRequestId,
      json: { [fieldKey]: 0 },
    });
  };

  const textMap = {
    requested_amount: {
      title: 'Enter lump sum',
      text: `You can now submit a lump sum draw request for this project. The total amount will be automatically distributed across line items based on an internal algorithm that ensures no line item exceeds its remaining balance.\n\nOnce submitted, your draw administrator will review the distribution and make any necessary adjustments to align with the supporting documentation.`,
    },
    approved_amount: {
      title: 'Enter lump sum',
      text: `A lump sum draw request has been submitted and total amount was automatically distributed across the line items based on an internal algorithm that ensures no line item exceeds its remaining balance.\n\nYou can approve it as "lump sum" or make any necessary adjustments to align with the supporting documentation.`,
    },
  };

  const values = useMemo(() => textMap[fieldKey] || { title: '', text: '' }, [fieldKey]);

  return {
    lumpSumField,
    lumpSumError,
    onEnterLumpSum,
    values,
    isSubmitting: batchRequestMutation.isLoading,
    onResetLumpSum,
  };
};
