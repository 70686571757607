import React, { FC } from 'react';
import { FilterButtonV2, Popup } from '@components';
import { Button, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import * as Controller from './controller';
import { useParams } from 'react-router-dom';
import { ICoordinator } from '@interfaces';
import { LinkIcon } from '@svgAsComponents';
import { getUserFullName } from '@utils';

const AssignCoordinatorPopup: FC<{
  open: boolean;
  onClose: () => void;
  initCoordinator: ICoordinator;
  requestId: string;
  prId?: string;
}> = ({ open, onClose, initCoordinator, requestId, prId }) => {
  const { projectId } = useParams();
  const {
    users,
    onAssignCoordinator,
    handleChange,
    handleFilterChange,
    coordinatorId,
    shouldUpdateProjectCoordinator,
    setUpdateProjectCoordinator,
  } = Controller.useAssignCoordinatorPopup(initCoordinator, prId || projectId, requestId, onClose);

  const formattedOptions = [
    {
      value: '',
      label: 'Not assigned',
    },
    ...users.map((user) => ({
      value: user.id,
      label: user.full_name || getUserFullName(user),
    })),
  ];

  return (
    <Popup open={open} maxWidth="md" title="Assign coordinator" icon={LinkIcon}>
      <Stack sx={{ width: '100%' }}>
        {Boolean(users.length) && (
          <Stack direction="row" sx={{ width: '100%', my: 2 }}>
            <FilterButtonV2
              title="Coordinator"
              filterKey="coordinator"
              handleFiltersChange={(_key, values) => {
                handleFilterChange(values[0] || '');
              }}
              initValues={coordinatorId ? [coordinatorId] : []}
              getStaticValues={() => formattedOptions}
              closeOnChange
              defaultFilterLabel="Not assigned"
              fixedWidth="100%"
              textFieldHeight="40px"
              multiple={false}
              cypressSelector="draw_switch_coordinator_select"
            />
          </Stack>
        )}

        <FormControlLabel
          control={
            <Checkbox
              checked={shouldUpdateProjectCoordinator}
              onChange={(_e, value) => setUpdateProjectCoordinator(value)}
            />
          }
          label={
            <Typography variant="body2">
              Link this coordinator to the next requests as well.
            </Typography>
          }
        />

        <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: '3.5rem' }}>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button
            sx={{ ml: '0.5rem' }}
            onClick={onAssignCoordinator}
            data-cy="draw_switch_cooridnator_save_button"
          >
            Assign
          </Button>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default AssignCoordinatorPopup;
