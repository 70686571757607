import React from 'react';
import VideoPlayer from '../VideoPlayer';
import { Popper, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from '@theme';
import { MediaDetails } from '@components';
import { IPhoto } from '@interfaces';

const StyledPopperDiv = styled('div')(
  () => `
  background-color: ${colors.background.white};
  border-radius: 4px;
  padding: 16px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
`,
);

export const VideoCardWithPopper = ({ video }: { video: IPhoto }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <Stack sx={{ height: '146px' }} onMouseEnter={handleHover} onMouseLeave={handleClose}>
      <VideoPlayer videoUrl={video.link} />
      <Popper id={id} open={open} anchorEl={anchorEl} placement="top-end" style={{ zIndex: 1300 }}>
        <StyledPopperDiv sx={{ width: 350 }}>
          <MediaDetails
            media={{
              ...video,
              milestones: video['milestone_names']?.map((milestone) => ({
                name: milestone,
              })),
            }}
            size="small"
            mediaType="Video"
          />
        </StyledPopperDiv>
      </Popper>
    </Stack>
  );
};
