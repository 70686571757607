import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import {
  CSVBoxSheetsEnum,
  IMenuItem,
  IProjectsPortfolioItem,
  IRightDrawerParams,
  IRightMenu,
  ITablePagination,
  PermissionNamesEnums,
  PostProjectWatchersParam,
  QueryNamesEnums,
  TableKeyEnum,
} from '@interfaces';
import {
  getProjectPropertyTypes,
  getProjectsList,
  postProjectWatchers,
  removeProjectWatchers,
} from '@globalService';
import {
  useColumnFilterV2,
  useRightMenu,
  useSafeSnackbar,
  useSorting,
  useTablePagination,
  useUpdateUiSettings,
} from '@hooks';
import { useNavigate } from 'react-router-dom';
import { AuthContext, PermissionsContext, SettingsContext, useLaunchDarklyFlags } from '@context';
import {
  checkIsExternalUser,
  checkIsInvestor,
  checkIsLender,
  getDynamicColumnsForBudgetCSV,
  getSortQueryString,
  getTeamRole,
  isRestricted,
  replaceItemInPaginatedResponse,
} from '@utils';
import { CSVUploader } from '@components';
import { Skeleton, Typography } from '@mui/material';
import { getColumns } from './columns';
import { projectListFields } from '@constants';

export interface ControllerInterface {
  tablePagination: ITablePagination;
  isListView: boolean;
  updateListView: ({ listView }: { listView: boolean }) => void;
  addProjectMenuItems: IMenuItem[];
  setFilterStringQuery: Dispatch<SetStateAction<string>>;
  columns: any[];
  hiddenColumns: string[];
  changeFieldVisibility: (id: string) => void;
  isColumnFilterUpdating: boolean;
  rightMenu: IRightMenu;
  rightDrawerParams: IRightDrawerParams;
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  projects: IProjectsPortfolioItem[];
  filteredProjectsCount: number;
  handleSortClick: (column: string) => void;
  sortValue: { [key: string]: number }[];
  selectedProjects: string[];
  unSelectAllProjects: () => void;
  showNotificationsPopup: boolean;
  setShowNotificationsPopup: Dispatch<React.SetStateAction<boolean>>;
  showNotificationSetupPopup: () => void;
  onFiltersReady: () => void;
  filterStringQuery: string;
  filtersReady: boolean;
  isLender: boolean;
  selectedProjectCompanyIds: string[];
  isCustomerSuccess: boolean;
}

enum ViewTypes {
  LIST = 'list',
  CARD = 'card',
}

export const useProjects = (): ControllerInterface => {
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const { permissions } = useContext(PermissionsContext);
  const { settings } = useContext(SettingsContext);
  const { updateSettings } = useUpdateUiSettings();
  const { handleSortClick, sortValue } = useSorting();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const flags = useLaunchDarklyFlags();

  const [filtersReady, setFiltersReady] = useState<boolean>(false);
  const [filterStringQuery, setFilterStringQuery] = useState<string>('');
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [showNotificationsPopup, setShowNotificationsPopup] = useState<boolean>(false);

  const isLender = useMemo(() => checkIsLender(teamRole), [teamRole]);
  const isInvestor = useMemo(() => checkIsInvestor(teamRole), [teamRole]);
  const isInternalUser = useMemo(() => !checkIsExternalUser(teamRole), [teamRole]);

  const tablePagination = useTablePagination({});

  const [isListView, setListView] = useState(true);
  const [rightDrawerParams, setRightDrawerParams] = useState<IRightDrawerParams>({});
  const [transformedData, setTransformedData] = useState([]);

  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: () => setRightDrawerParams((old) => ({ ...old, activeTab: '' })),
  });

  const { hiddenColumns, changeFieldVisibility, isColumnFilterUpdating } = useColumnFilterV2(
    TableKeyEnum.RISK_RADAR,
  );

  const updateRightDrawer = ({
    title,
    projectId,
    activeTab,
  }: {
    title: string;
    projectId: string;
    activeTab: string;
  }) => {
    handleRightDrawerOpenerClick({ title });
    setRightDrawerParams({ projectId, activeTab });
  };

  const filterStringQueryWithPagination = useMemo(() => {
    const sortString = getSortQueryString({ sortValue, statusFieldSortName: 'project_status' });
    const sortUrl = sortString ? `&sorting=${sortString}` : '';
    const paginationUrl = `offset=${
      tablePagination.page * tablePagination.rowsPerPage
    }&limit=${tablePagination.rowsPerPage}`;
    const resQlQuery = `query={${projectListFields.join()}}`;

    return `${filterStringQuery}&${paginationUrl}&${sortUrl}&${resQlQuery}`;
  }, [filterStringQuery, tablePagination, sortValue]);

  const requestedDataQueries = useQueries([
    {
      queryKey: [
        QueryNamesEnums.GET_PROJECT_PROPERTY_TYPES,
        { query: '&has_current_customer=true' },
      ],
      queryFn: getProjectPropertyTypes.bind(this, '&has_current_customer=true'),
    },
  ]);

  const {
    data: { results, count } = {},
    isLoading,
    isFetching,
    isError,
  } = useQuery<{ results: IProjectsPortfolioItem[]; count: number }, Error>(
    [QueryNamesEnums.GET_PROJECTS_LIST, { filterStringQuery: filterStringQueryWithPagination }],
    getProjectsList.bind(this, {
      filterStringQuery: filterStringQueryWithPagination,
    }),
    {
      enabled: filtersReady,
      keepPreviousData: true,
    },
  );

  const invalidateWatchQueries = ({
    projectId,
    isWatching,
  }: {
    projectId: string;
    isWatching: boolean;
  }) => {
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_WATCHERS, { projectId: projectId }]);
    queryClient.setQueriesData<{ results: IProjectsPortfolioItem[] }>(
      {
        queryKey: [
          QueryNamesEnums.GET_PROJECTS_LIST,
          { filterStringQuery: filterStringQueryWithPagination },
        ],
        exact: false,
      },
      (old) =>
        replaceItemInPaginatedResponse({
          old,
          updatedItem: { id: projectId, is_watching: isWatching },
        }),
    );
  };

  const addProjectWatchersMutation = useMutation<Response, Error, PostProjectWatchersParam>(
    postProjectWatchers,
    {
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const removeProjectWatchersMutation = useMutation<Response, Error, PostProjectWatchersParam>(
    removeProjectWatchers,
    {
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const startWatchingProject = useCallback(
    (projectId: string) => {
      addProjectWatchersMutation.mutateAsync({
        projectId,
        projectIds: [projectId],
        userIds: [user.id],
      });
      invalidateWatchQueries({ projectId, isWatching: true });
    },
    [user.id, addProjectWatchersMutation],
  );

  const unWatchProject = useCallback(
    async (projectId: string) => {
      await removeProjectWatchersMutation.mutateAsync({
        projectId,
        projectIds: [projectId],
        userIds: [user.id],
      });
      invalidateWatchQueries({ projectId, isWatching: false });
    },
    [user.id, removeProjectWatchersMutation],
  );

  const showNotificationSetupPopup = useCallback(() => {
    const isNotificationPopupWasShown = settings.personal_setting?.shownPopups?.setup_notifications;
    if (!isNotificationPopupWasShown) {
      setShowNotificationsPopup(true);
      updateSettings({
        personal_setting: {
          shownPopups: {
            ...settings.personal_setting?.shownPopups,
            setup_notifications: true,
          },
        },
      });
    }
  }, [settings.personal_setting?.shownPopups]);

  const onWatchIconClick = useCallback(
    (isWatching: boolean, projectId: string) => {
      isWatching ? unWatchProject(projectId) : startWatchingProject(projectId);
      showNotificationSetupPopup();
    },
    [unWatchProject, startWatchingProject],
  );

  const projectPropertyTypesQuery = requestedDataQueries[0].data;

  const projectPropertyTypes = useMemo(() => {
    if (projectPropertyTypesQuery?.results?.length)
      return projectPropertyTypesQuery.results.map((item) => ({
        type: item.name,
        name: item.name_display,
      }));
    return [];
  }, [projectPropertyTypesQuery]);

  useEffect(() => {
    if (results?.length) {
      setTransformedData(results);
    } else {
      setTransformedData([]);
    }
  }, [results]);

  useEffect(() => {
    tablePagination.setPage(0);
  }, []);

  useEffect(() => {
    const viewType = settings?.personal_setting?.tables?.[TableKeyEnum.RISK_RADAR]?.view_type;
    if (viewType) {
      setListView(viewType === ViewTypes.LIST);
    }
  }, [settings?.personal_setting?.tables?.[TableKeyEnum.RISK_RADAR]?.view_type]);

  const dynamicColumns = useMemo(() => getDynamicColumnsForBudgetCSV(permissions), [permissions]);
  const budgetLaunchFunctionRef = useRef<() => void | null>(null);
  const projectLaunchFunctionRef = useRef<() => void | null>(null);

  const addProjectMenuItems = useMemo(
    () => [
      {
        text: 'Manually',
        action: () => navigate(`add-new`),
        dataTestName: 'projects__add__manually__menu_item',
      },
      {
        text: (
          <CSVUploader
            sheetKey={CSVBoxSheetsEnum.IMPORT_BUDGET_BULK}
            dynamicColumns={dynamicColumns}
            renderItem={(launch, isLoading) => {
              if (!isLoading) {
                budgetLaunchFunctionRef.current = launch;
              }
              if (isLoading || !budgetLaunchFunctionRef.current) return <Skeleton height={16} />;

              return <Typography variant="body3SemiBold">Bulk budget upload</Typography>;
            }}
            source="bulk_upload_budget_button"
          />
        ),
        action: () => {
          if (budgetLaunchFunctionRef.current) {
            budgetLaunchFunctionRef.current();
          }
        },
        dataTestName: 'projects__add__upload_bulk__menu_item',
      },
      {
        text: (
          <CSVUploader
            sheetKey={CSVBoxSheetsEnum.IMPORT_PROJECT}
            renderItem={(launch, isLoading) => {
              if (!isLoading) {
                projectLaunchFunctionRef.current = launch;
              }
              if (isLoading || !projectLaunchFunctionRef.current) return <Skeleton height={16} />;

              return <Typography variant="body3SemiBold">Upload project</Typography>;
            }}
            source="upload_project_button"
          />
        ),
        dataTestName: 'projects__add__upload__menu_item',
        action: () => {
          if (projectLaunchFunctionRef.current) {
            projectLaunchFunctionRef.current();
          }
        },
      },
    ],
    [dynamicColumns, budgetLaunchFunctionRef, projectLaunchFunctionRef],
  );

  const selectProject = (projectId: string) => setSelectedProjects((prev) => [...prev, projectId]);
  const unSelectProject = (projectId: string) =>
    setSelectedProjects((prev) => prev.filter((id) => id !== projectId));
  const selectAllProjects = () => setSelectedProjects(transformedData.map((project) => project.id));
  const unSelectAllProjects = () => setSelectedProjects([]);

  useEffect(() => {
    unSelectAllProjects();
  }, [filterStringQueryWithPagination]);

  const isAllSelected = useMemo(
    () => selectedProjects.length === transformedData.length,
    [selectedProjects, transformedData],
  );

  const onSelectAllClick = useCallback(
    () => (isAllSelected ? unSelectAllProjects() : selectAllProjects()),
    [isAllSelected, unSelectAllProjects, selectAllProjects],
  );

  const isCommentsAvailable = useMemo(
    () => !isRestricted(PermissionNamesEnums.COMMENTS_VIEW, permissions),
    [permissions],
  );

  const isCustomerSuccess = useMemo(
    () =>
      flags?.['ENG_9238_bulk_apply_policy_templates'] &&
      !isRestricted(PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS, permissions),
    [permissions],
  );

  const columns = React.useMemo(
    () =>
      getColumns({
        selectProject,
        unSelectProject,
        isLoading,
        projectPropertyTypes,
        isLender,
        isInvestor,
        isInternalUser,
        permissions,
        updateRightDrawer,
        onWatchIconClick,
        selectedProjects,
        isAllSelected,
        onSelectAllClick,
        isCommentsAvailable,
        isCustomerSuccess,
      }),
    [
      open,
      isLoading,
      permissions,
      hiddenColumns,
      selectedProjects,
      isAllSelected,
      isCommentsAvailable,
      isCustomerSuccess,
    ],
  );

  const updateListView = ({ listView }: { listView: boolean }) => {
    if (isListView !== listView) {
      setListView(listView);
      updateSettings({
        personal_setting: {
          tables: {
            ...settings.personal_setting?.tables,
            [TableKeyEnum.RISK_RADAR]: {
              view_type: listView ? ViewTypes.LIST : ViewTypes.CARD,
              hidden_columns: hiddenColumns,
            },
          },
        },
      });
    }
  };

  const selectedProjectCompanyIds = useMemo(() => {
    return [
      ...new Set(
        transformedData
          .filter((project) => selectedProjects.includes(project.id))
          .map((project) => project.customer?.id)
          .filter(Boolean),
      ),
    ];
  }, [selectedProjects, transformedData]);

  return {
    tablePagination,
    isListView,
    updateListView,
    addProjectMenuItems,
    setFilterStringQuery,
    columns,
    hiddenColumns,
    changeFieldVisibility,
    isColumnFilterUpdating,
    rightMenu,
    rightDrawerParams,
    isLoading,
    isFetching,
    isError,
    projects: transformedData,
    filteredProjectsCount: count,
    handleSortClick,
    sortValue,
    selectedProjects,
    unSelectAllProjects,
    showNotificationsPopup,
    setShowNotificationsPopup,
    showNotificationSetupPopup,
    onFiltersReady: () => setFiltersReady(true),
    filterStringQuery,
    filtersReady,
    isLender,
    selectedProjectCompanyIds,
    isCustomerSuccess,
  };
};
