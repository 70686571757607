import React, { FC } from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import { colors } from '@theme';
import { CloseIcon, VisibilityOffIcon, VisibilityOnIcon } from '@svgAsComponents';
import { useWatchProjectsBulkPanel } from './controller';
import { IconButtonWithTooltip } from '@components';

const WatchProjectsBulkPanel: FC<{
  selectedProjectsIds: string[];
  onClose: () => void;
  onComplete: () => void;
}> = ({ selectedProjectsIds, onClose, onComplete }) => {
  const { startWatchingProjects, unWatchProjects } = useWatchProjectsBulkPanel(
    selectedProjectsIds,
    onClose,
    onComplete,
  );

  return (
    <Stack
      direction="row"
      sx={{
        position: 'fixed',
        backgroundColor: colors.main.primary.main,
        bottom: 24,
        left: '50%',
        transform: 'translate(-50%, 0)',
        px: 3,
        py: 1,
      }}
      alignItems="center"
    >
      <Stack direction="row" alignItems="center">
        <IconButtonWithTooltip onClick={onClose} sx={{ p: 0 }} tooltipText="Close">
          <CloseIcon size={16} color={colors.neutral.white} />
        </IconButtonWithTooltip>
        <Typography variant="body2SemiBold" sx={{ ml: 2, color: colors.neutral.white }}>
          {`${selectedProjectsIds.length} projects selected`}
        </Typography>
      </Stack>
      <Divider
        orientation="vertical"
        sx={{ mx: '32px', color: colors.neutral.white, height: '32px' }}
      />
      <Stack direction="row" alignItems="center" spacing="40px">
        <IconButtonWithTooltip
          onClick={startWatchingProjects}
          sx={{ p: 0 }}
          tooltipText="Watch selected projects"
        >
          <>
            <VisibilityOnIcon size={24} color={colors.neutral.white} />
            <Typography variant="body2SemiBold" sx={{ ml: 1, color: colors.neutral.white }}>
              Watch
            </Typography>
          </>
        </IconButtonWithTooltip>
        <IconButtonWithTooltip
          onClick={unWatchProjects}
          sx={{ p: 0 }}
          tooltipText="Unwatch selected projects"
        >
          <>
            <VisibilityOffIcon size={24} color={colors.neutral.white} />
            <Typography variant="body2SemiBold" sx={{ ml: 1, color: colors.neutral.white }}>
              Unwatch
            </Typography>
          </>
        </IconButtonWithTooltip>
      </Stack>
    </Stack>
  );
};

export default WatchProjectsBulkPanel;
