import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import { documentsTypeMap } from '@constants';
import { BaseColumnProps } from '../interface';

export const documentTypeColumn = ({ source }: BaseColumnProps): GridColDef => ({
  field: 'document_type',
  headerName: 'Type',
  minWidth: 160,
  groupable: false,
  aggregable: false,
  renderCell: (params) => {
    const { document_type } = params.row;

    return (
      <Typography variant="body3" data-cy={`${source}__document_type__value`}>
        {documentsTypeMap[document_type] || document_type}
      </Typography>
    );
  },
});
