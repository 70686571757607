import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Stack } from '@mui/material';
import { useFormik } from 'formik';

import { initialValues, initialErrors, validatePassword, getErrorText } from '@utils';
import { CreatePasswordInputs, LoginWireframe } from '@components';
import * as Controller from './controller';
import { BackToLoginButton } from '../components';
import { colors } from '@theme';
import { ErrorDual } from '@interfaces';

const ResetPassword = () => {
  const navigate = useNavigate();
  const { handleResetPassword, isSetUpPassword } = Controller.useResetPassword();

  const [validationErrors, setValidationErrors] = useState(initialErrors);
  const [resetError, setResetError] = useState('');
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validate: (values) => validatePassword(values, setValidationErrors),
    onSubmit: async (values) => {
      try {
        await handleResetPassword(values.password.trim());
      } catch (err) {
        setResetError(getErrorText(err as ErrorDual));
        formik.setSubmitting(false);
      }
    },
  });

  const goToForgotPassword = () => {
    navigate('/forgot-password');
  };

  const getTitle = () => (
    <>
      <Typography variant="h1">
        {isSetUpPassword ? 'Set up password' : 'Create new password'}
      </Typography>
      {!isSetUpPassword && (
        <Typography variant="body2">This will replace your existing password.</Typography>
      )}
    </>
  );

  const getFormContent = useMemo(() => {
    if (resetError)
      return (
        <Typography variant="body2" sx={{ mb: '16px', color: colors.status.error.medium }}>
          {resetError}
        </Typography>
      );
    return <CreatePasswordInputs validationErrors={validationErrors} />;
  }, [resetError, validationErrors]);

  const getFormButtons = useMemo(() => {
    return (
      <Stack spacing={2}>
        {resetError ? (
          <Button fullWidth onClick={goToForgotPassword}>
            Request new reset link
          </Button>
        ) : (
          <Button
            fullWidth
            type="submit"
            variant="contained"
            disabled={formik.isSubmitting || Boolean(validationErrors.length)}
          >
            Set up password
          </Button>
        )}
        <BackToLoginButton />
      </Stack>
    );
  }, [resetError, formik]);

  return (
    <LoginWireframe
      formik={formik}
      getTitle={getTitle()}
      getFormContent={getFormContent}
      getFormButtons={getFormButtons}
    />
  );
};

export default ResetPassword;
