import { IRoleNew, RolePayload } from '@interfaces';
import groupBy from 'lodash/groupBy';
import { TreeViewItemReorderPosition } from '@mui/x-tree-view-pro/internals/plugins/useTreeViewItemsReordering';
import { useCallback } from 'react';

export interface RolesWorkflowInterface {
  roles: IRoleNew[];
  updateRole?: (data: RolePayload) => void;
  disabled?: boolean;
  addRole?: (data: Partial<IRoleNew>) => void;
}

export const useRolesWorkflow = ({ roles, updateRole, addRole }: RolesWorkflowInterface) => {
  const groupedRoles = groupBy(roles, 'review_level') as Record<string, IRoleNew[]>;
  const itemsKeys = Object.keys(groupedRoles);
  const items = Object.keys(groupedRoles).map((key) => ({
    id: key,
    label: +key > 0 ? `Review level ${key}` : 'Not include in approval flow',
    children: groupedRoles[key].map((item) => ({
      id: item.id,
      label: item.company_role,
      enrollment: item.platform_role === 'LENDER' ? 'all projects' : 'by invite only',
    })),
  }));
  if (items[0]?.id !== '0') {
    items.unshift({
      id: '0',
      label: 'Not include in approval flow',
      children: [],
    });
  }

  const handleAddRole = (data: {
    company_role: string;
    platform_role: string;
    review_level: number;
  }) => {
    if (addRole) {
      addRole({
        ...data,
      });
    }
  };

  const updateApi = useCallback(
    ({
      newPosition,
      oldPosition,
      itemId,
    }: {
      itemId: string;
      oldPosition: TreeViewItemReorderPosition;
      newPosition: TreeViewItemReorderPosition;
    }) => {
      const payload: RolePayload = {
        roleId: itemId,
        data: {},
      };

      if (oldPosition.parentId !== newPosition.parentId) {
        payload.data.review_level = +newPosition.parentId;
      }

      if (Object.keys(payload.data).length) {
        updateRole(payload);
      }
    },
    [updateRole],
  );

  return { items, itemsKeys, updateApi, handleAddRole };
};
