import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import { MagicWandIcon } from '@svgAsComponents';
import { BetaChip, DocumentSummaryList, RightDrawer, SmartSummaryButton } from '@components';
import * as Controller from './controller';
import { IProjectDocument, MediaFile } from '@interfaces';

interface ComponentProps {
  drawRequestId: string;
  projectName: string;
  openPdfViewer?: (file: IProjectDocument | MediaFile) => void;
  source: string;
}

const ProjectSmartSummaryButton = ({
  drawRequestId,
  projectName,
  openPdfViewer,
  source,
}: ComponentProps) => {
  const { projectId } = useParams();
  const {
    drawRequestDocuments,
    docSummaryMenu,
    docSummaryParams,
    setDocSummaryParams,
    handleSmartSummaryOpenerClick,
    tablePagination,
    documentsCount,
  } = Controller.useProjectSmartSummaryButton({ drawRequestId, projectId });
  const openAllDocsSummary = () => {
    handleSmartSummaryOpenerClick({
      title: (
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
          <MagicWandIcon />
          <Typography variant="h2" sx={{ whiteSpace: 'nowrap' }}>
            SmartSummary
          </Typography>
          <Stack ml={1}>
            <BetaChip dataTestName="right_drawer__documents_summary__beta__chip" />
          </Stack>
        </Stack>
      ),
    });
    setDocSummaryParams({ projectId });
  };

  return (
    <>
      <SmartSummaryButton size="small" onClick={openAllDocsSummary} source={source} />
      <RightDrawer {...docSummaryMenu} drawerWidth="720px">
        <DocumentSummaryList
          {...docSummaryParams}
          projectName={projectName}
          rows={drawRequestDocuments}
          source={`${source}__list__summary`}
          openPdfViewer={openPdfViewer}
          tablePagination={tablePagination}
          documentsCount={documentsCount}
        />
      </RightDrawer>
    </>
  );
};

export default ProjectSmartSummaryButton;
