import React, { ReactNode, useMemo } from 'react';
import { Typography, Stack } from '@mui/material';

import { BackToLoginButton, LoginWireframe } from '../components';
import { colors } from '@theme';

const LoginErrorPage = ({ error }) => {
  const getTitle = () => (
    <>
      <Typography variant="h3">Something went wrong. Try again</Typography>
    </>
  );

  const getFormContent = useMemo(() => {
    const errorMessage = typeof error === 'string' ? error : error?.message;
    return (
      <Typography variant="body3" sx={{ mb: '16px', color: colors.status.error.medium }}>
        {errorMessage}
      </Typography>
    );
  }, [error]);

  const getFormButtons: ReactNode = (
    <Stack spacing={2}>
      <BackToLoginButton />
    </Stack>
  );

  return (
    <LoginWireframe
      getTitle={getTitle()}
      getFormContent={getFormContent}
      getFormButtons={getFormButtons}
    />
  );
};

export default LoginErrorPage;
