import { useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  emptyHTML,
  getActiveDrawRequestFromList,
  getArrayFromObject,
  getBasicUrl,
  getHookState,
  getServiceTypeDisplayName,
  getTagsList,
  isAutomatedServiceProvider,
  isInactiveProject,
  isRestricted,
} from '@utils';
import {
  IDrawRequest,
  IInspection,
  IMilestone,
  IProjectComment,
  IProjectDocument,
  IServiceOrder,
  MessagePanelTabsEnum,
  PatchCommentPayload,
  PermissionNamesEnums,
  QueryNamesEnums,
  VisibilityEnum,
} from '@interfaces';
import {
  useDateFormatter,
  useProjectTeamsAndCompanies,
  useSafeSnackbar,
  useTablePagination,
} from '@hooks';
import {
  getCommentsWithPagination,
  getProjectDocumentById,
  getProjectDrawRequestsList,
  getProjectInspectionById,
  getProjectMilestone,
  getServiceOrderById,
  patchComment as patch,
  postComment as post,
} from '@globalService';
import { AuthContext, PermissionsContext, SettingsContext, useGetData } from '@context';
import { useDebouncedEffect, useStringFieldModel } from '@models';
import { ACTIVE_COMMENTS_TYPES, commentsListFields, SORT_BY_DATE_LABEL } from '@constants';
import { ControllerInterface } from './interface';
import { getFilterParams, getReceivedByCompanyId, TABS } from './utils';

export const useProjectComments = ({
  projectId,
  requestId,
  milestoneId,
  inspectionId,
  serviceOrderId,
  documentId,
  isProjectComments,
  tab = MessagePanelTabsEnum.INTERNAL,
  includeChildren,
}: {
  projectId: string;
  requestId: string;
  milestoneId: string;
  inspectionId: string;
  serviceOrderId: string;
  documentId: string;
  isProjectComments: boolean;
  tab: MessagePanelTabsEnum;
  includeChildren?: boolean;
}): ControllerInterface => {
  // Context hooks
  const { projectBorrowerTeam } = useProjectTeamsAndCompanies({ prId: projectId });
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const { permissions } = useContext(PermissionsContext);
  const { isPHBProject, settings } = useContext(SettingsContext);
  const { user } = useContext(AuthContext);

  // State hooks
  const [reportTag, setReportTag] = useState<boolean>(false);
  const [replyMode, setReplyMode] = useState<boolean>(false);
  const [parentComment, setParentComment] = useState<IProjectComment>(null);
  const [isSendToInspectorChecked, setIsSendToInspectorChecked] = useState(Boolean(inspectionId)); // TODO: check if this is correct
  const [editedComment, setEditedComment] = useState<IProjectComment>(null);
  const [expandedCommentId, setExpandedCommentId] = useState<string>(null);
  const [activeTab, setActiveTab] = useState<MessagePanelTabsEnum>(tab || null);
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');

  // Custom hooks
  const { dateFormatter } = useDateFormatter();
  const tablePagination = useTablePagination({ initialRowsPerPage: 10, rowsPerPageOptions: [] });
  const commentField = useStringFieldModel({
    initValue: '',
    validationRule: (value) => Boolean(value.trim()) && !emptyHTML(value),
  });
  const editCommentField = useStringFieldModel({
    initValue: '',
    validationRule: (value) => Boolean(value.trim()) && !emptyHTML(value),
  });
  const replyField = useStringFieldModel({
    initValue: '',
    validationRule: (value) => Boolean(value.trim()) && !emptyHTML(value),
  });

  // Memoized values
  const sortingOptions = getArrayFromObject(SORT_BY_DATE_LABEL, 'value', 'text').map((item) => ({
    ...item,
    action: () => handleSortingChange(item),
  })) as unknown as Array<{
    value: string;
    text: string;
  }>;

  const [sortingValue, setSortingValue] = useState<{
    text: string;
    value: string;
  }>(sortingOptions[0]);

  // Query hooks
  const project = useGetData({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['status'],
    args: { projectId },
  });

  const documentsQuery = `{id,scope}`;
  const documentQuery = useQuery<IProjectDocument, Error>(
    [QueryNamesEnums.GET_PROJECT_DOCUMENT_BY_ID, { projectId, documentId, documentsQuery }],
    getProjectDocumentById.bind(this, { projectId, documentId, query: documentsQuery }),
    { enabled: !!documentId },
  );

  const drawRequestsQuery = useQuery<{ results: IDrawRequest[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
    getProjectDrawRequestsList.bind(this, projectId),
    { enabled: Boolean(projectId) },
  );

  const milestoneQuery = useQuery<IMilestone, Error>(
    [QueryNamesEnums.GET_PROJECT_MILESTONE, { projectId, milestoneId }],
    getProjectMilestone.bind(this, { projectId, milestoneId }),
    { enabled: Boolean(milestoneId && !isPHBProject) },
  );

  const queryInspectionFields =
    '{status,inspection_agency,service_type,completed_at,draw_request{id,number}}';
  const inspectionQuery = useQuery<IInspection, Error>(
    [
      QueryNamesEnums.GET_PROJECT_INSPECTION_BY_ID,
      { projectId, inspectionId, query: queryInspectionFields },
    ],
    getProjectInspectionById.bind(this, {
      projectId,
      inspectionId,
      query: queryInspectionFields,
    }),
    { enabled: Boolean(inspectionId) },
  );

  const queryServiceOrderFields = '{status,service_agency,service_type,completed_at}';
  const serviceOrderQuery = useQuery<IServiceOrder, Error>(
    [
      QueryNamesEnums.GET_PROJECT_SERVICE_ORDER_BY_ID,
      { projectId, serviceOrderId, restQlquery: queryServiceOrderFields },
    ],
    getServiceOrderById.bind(this, {
      projectId,
      serviceOrderId,
      restQlquery: queryServiceOrderFields,
    }),
    { enabled: Boolean(serviceOrderId) },
  );

  // Mutation hooks
  const postComment = useMutation<
    Response,
    Error,
    {
      url: string;
      value: PatchCommentPayload;
    }
  >(post, {
    onSuccess: () => {
      parentComment ? handleCloseReplyMode() : commentField.setValue('');
      if (!inspectionId) setIsSendToInspectorChecked(false);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_PROJECT_COMMENTS,
        { url: commentsUrlForParents },
      ]);
      if (parentComment) {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_COMMENT_THREAD,
          { url: commentsUrlForThread },
        ]);
        if (!expandedCommentId) setExpandedCommentId(parentComment?.id);
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const patchComment = useMutation<
    Response,
    Error,
    {
      url: string;
      value: { tags?: string[]; is_pinned?: boolean; message?: string };
    }
  >(patch, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_PROJECT_COMMENTS,
        { url: commentsUrlForParents },
      ]);

      if (editedComment) {
        if (editedComment?.parent_id) {
          queryClient.invalidateQueries(QueryNamesEnums.GET_COMMENT_THREAD);
        }
        setEditedComment(null);
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  // Effect hooks
  useEffect(() => {
    tablePagination.setPage(0);
  }, [activeTab, debouncedSearch]);

  useEffect(() => {
    // by default, report tag is checked for any inspection comments
    if (inspectionId) setReportTag(true);
  }, [inspectionId]);

  useDebouncedEffect(
    () => {
      setDebouncedSearch(search);
    },
    [search],
    500,
  );

  useEffect(() => {
    setParentComment(null);
    setExpandedCommentId(null);
  }, [activeTab]);

  // Memoized values
  const permittedTabs = useMemo(() => {
    const scope = documentQuery?.data?.scope;

    return Object.values(TABS)
      .filter((tab) => !tab.permissionKey || !isRestricted(tab.permissionKey, permissions))
      .map((tab) => {
        const disabled =
          (tab.value === MessagePanelTabsEnum.SERVICES && !!documentId) ||
          (tab.value === MessagePanelTabsEnum.BORROWER && scope === VisibilityEnum.COMPANY);

        return {
          ...tab,
          disabled,
        };
      });
  }, [permissions, documentId, documentQuery?.data]);

  useEffect(() => {
    if (
      permittedTabs.length === 1 ||
      (activeTab && !permittedTabs.find((tab) => tab.value === activeTab)) ||
      !activeTab
    ) {
      setActiveTab(permittedTabs[0].value);
    }
  }, [permittedTabs, activeTab]);

  const received_by_company_id = useMemo(
    () =>
      getReceivedByCompanyId({
        activeTab,
        borrowerCompanyId: projectBorrowerTeam?.company?.id,
        userCompanyId: user?.company_id,
      }),
    [activeTab, projectBorrowerTeam, user?.company_id],
  );

  const isServiceTab = useMemo(() => activeTab === TABS.SERVICES.value, [activeTab]);

  const showSendToInspectorCheckbox = useMemo(
    () =>
      activeTab === MessagePanelTabsEnum.SERVICES &&
      ((inspectionId &&
        isAutomatedServiceProvider(inspectionQuery.data?.inspection_agency?.service)) ||
        (serviceOrderId &&
          isAutomatedServiceProvider(serviceOrderQuery?.data?.service_agency?.service_provider))),
    [inspectionQuery?.data, activeTab, inspectionId, serviceOrderId, serviceOrderQuery?.data],
  );

  const currentRequest = useMemo(
    () =>
      requestId
        ? drawRequestsQuery.data?.results?.find((x) => x?.id === requestId)
        : getActiveDrawRequestFromList(drawRequestsQuery.data),
    [drawRequestsQuery.data],
  );

  const basicUrl = useMemo(() => {
    return getBasicUrl({
      requestType: 'get',
      projectId,
      requestId,
      milestoneId,
      inspectionId,
      serviceOrderId,
      documentId,
      isPHBProject,
    });
  }, [projectId, requestId, milestoneId, inspectionId, serviceOrderId, documentId, isPHBProject]);

  const commentsUrlForParents = useMemo(() => {
    const basicPath = basicUrl + '?';

    const params = [];

    if (debouncedSearch.trim()) {
      params.push(new URLSearchParams({ q: debouncedSearch }).toString());
    }

    const filtersParams = new URLSearchParams(
      getFilterParams({
        activeTab,
        received_by_company_id,
        isInspectionMilestoneComments: !!(milestoneId && inspectionId),
      }) as URLSearchParams,
    ).toString();

    if (filtersParams) {
      params.push(filtersParams);
    }

    const paginationParams = `offset=${tablePagination.page * tablePagination.rowsPerPage}&limit=${tablePagination.rowsPerPage}`;
    params.push(paginationParams);

    const restQLparams = `query={${commentsListFields.join()}}`;
    params.push(restQLparams);

    const includeChildrenParams = `include_children=${includeChildren}`;
    params.push(includeChildrenParams);

    if (sortingValue?.value) {
      const sortingParams = `sorting=-${sortingValue.value}`;
      params.push(sortingParams);
    }

    params.push('has_parent=false');

    return `${basicPath}${params.join('&')}`;
  }, [
    debouncedSearch,
    basicUrl,
    activeTab,
    received_by_company_id,
    tablePagination,
    milestoneId,
    includeChildren,
    sortingValue?.value,
  ]);

  const commentsUrlForThread = useMemo(() => {
    const baseUrl = `projects/${projectId}/`;
    const documentPart = documentId ? `documents/${documentId}/` : '';
    const queryParams = `comments/?parent_id=${expandedCommentId}&include_children=${includeChildren}&sorting=created_at`;
    return `${baseUrl}${documentPart}${queryParams}`;
  }, [expandedCommentId, projectId, includeChildren, documentId]);

  const postCommentsUrl = useMemo(
    () =>
      getBasicUrl({
        requestType: 'post',
        projectId,
        requestId: milestoneId ? requestId : currentRequest?.id,
        milestoneId,
        inspectionId,
        serviceOrderId,
        documentId,
        isPHBProject,
      }),
    [
      projectId,
      requestId,
      currentRequest,
      milestoneId,
      inspectionId,
      serviceOrderId,
      documentId,
      isPHBProject,
    ],
  );

  const serviceTypeDisplayName = useMemo(() => {
    if (!serviceOrderQuery?.data) return '';
    return getServiceTypeDisplayName({
      serviceTypesMap: settings?.display?.service_types,
      serviceType: serviceOrderQuery?.data?.service_type,
    });
  }, [settings?.display?.service_types, serviceOrderQuery?.data?.service_type]);

  const tags = useMemo(
    () =>
      getTagsList({
        request: inspectionId
          ? inspectionQuery?.data?.draw_request
          : !isProjectComments
            ? currentRequest
            : null,
        milestone: milestoneQuery.data,
        inspection: isServiceTab && inspectionId ? inspectionQuery?.data : null,
        serviceOrder: isServiceTab && serviceOrderId ? serviceOrderQuery?.data : null,
        documentId,
        isAutomatedServiceProvider:
          isAutomatedServiceProvider(inspectionQuery?.data?.inspection_agency?.service) &&
          isSendToInspectorChecked,
        serviceType: serviceTypeDisplayName,
        dateFormatter,
      }),
    [
      currentRequest,
      milestoneQuery.data,
      inspectionQuery?.data,
      documentId,
      isServiceTab,
      isSendToInspectorChecked,
      isProjectComments,
      serviceOrderId,
      serviceOrderQuery?.data,
      serviceTypeDisplayName,
    ],
  );

  const projectCommentsQuery = useQuery<{ results: IProjectComment[]; count: number }, Error>(
    [QueryNamesEnums.GET_PROJECT_COMMENTS, { url: commentsUrlForParents }],
    getCommentsWithPagination.bind(this, { url: commentsUrlForParents }),
    {
      enabled:
        Boolean(projectId) &&
        (!!received_by_company_id ||
          ![TABS.BORROWER.value, TABS.INTERNAL.value].includes(activeTab)),
      staleTime: 0,
    },
  );

  const commentThreadQuery = useQuery<{ results: IProjectComment[] }, Error>(
    [QueryNamesEnums.GET_COMMENT_THREAD, { url: commentsUrlForThread }],
    getCommentsWithPagination.bind(this, { url: commentsUrlForThread }),
    { enabled: Boolean(expandedCommentId) },
  );

  // Helper functions
  const handleSortingChange = (item) => {
    const selected = sortingOptions.find((option) => option.value === item.value);
    setSortingValue(selected);
  };

  const handleReplyClick =
    ({ comment }) =>
    () => {
      setReplyMode(true);
      setParentComment(comment);
      if (comment.has_children) setExpandedCommentId(comment.id);
    };

  const handleCloseReplyMode = () => {
    replyField.setValue('');
    setReplyMode(false);
    setParentComment(null);
  };

  const handleExpandThreadClick = (commentId) => () => {
    if (expandedCommentId === commentId) {
      setExpandedCommentId(null);
      setReplyMode(false);
      setParentComment(null);
    } else {
      setExpandedCommentId(commentId);
    }
  };

  const handleTabChange = (id) => {
    setActiveTab(id);
  };

  const handleSearchSubmit = setSearch;
  const clearSearch = () => setSearch('');

  const postMessage = (message: string) => {
    const regex = /<a\s+(?:[^>]*?\s+)?href="(?:https?:\/\/)?([^"]*)"/g;
    const should_send_to_inspector = showSendToInspectorCheckbox && isSendToInspectorChecked;
    return postComment.mutateAsync({
      url: postCommentsUrl,
      value: {
        // add https:// to all links that don't have it
        message: message.replaceAll(regex, '<a href="https://$1"'),
        ...(reportTag && { tags: ['Report'] }),
        ...(should_send_to_inspector && {
          should_send_to_inspector,
        }),
        ...(received_by_company_id && { received_by_company_id }),
        ...(parentComment && {
          parent_id: parentComment?.id,
          received_by_company_id: parentComment?.received_by_company?.id,
        }),
      },
    });
  };

  const patchCommentsUrl = (commentId) => `projects/${projectId}/comments/${commentId}/`;

  const updateComment = (comment: IProjectComment) => (reportTag: boolean) =>
    patchComment.mutateAsync({
      url: patchCommentsUrl(comment?.id),
      value: {
        tags: reportTag
          ? ['Report', ...(comment?.tags || [])]
          : comment?.tags?.filter((tag) => tag !== 'Report'),
      },
    });

  const comments = useMemo(
    () =>
      projectCommentsQuery?.data?.results?.filter(
        (comment: IProjectComment) =>
          comment.message && ACTIVE_COMMENTS_TYPES.includes(comment.content_type),
      ),
    [projectCommentsQuery?.data?.results],
  );

  const showReportTag = useMemo(
    () => !isRestricted(PermissionNamesEnums.PROJECTS_REPORT_VIEW, permissions),
    [permissions],
  );

  const pinComment = (comment: IProjectComment) => () =>
    patchComment.mutateAsync({
      url: patchCommentsUrl(comment?.id),
      value: { is_pinned: !comment.is_pinned },
    });

  const handleEditCommentClick = (comment: IProjectComment) => () => {
    if (!comment) {
      setEditedComment(null);
      editCommentField.setValue('');
      return;
    }
    editCommentField.setValue(comment.message);
    setEditedComment(comment);
  };

  const saveEditedComment = () =>
    patchComment.mutateAsync({
      url: patchCommentsUrl(editedComment.id),
      value: { message: editCommentField.value },
    });

  const hideCommentInput = useMemo(
    () =>
      activeTab === TABS.ALL.value ||
      (activeTab === TABS.SERVICES.value && !(serviceOrderId || inspectionId)),
    [activeTab, inspectionId, serviceOrderId],
  );

  const isExternalComment = useMemo(() => {
    const borrowerTeamId = projectBorrowerTeam?.company?.id;
    if (!borrowerTeamId) return false;
    return (
      !isRestricted(PermissionNamesEnums.COMMENTS_ALL_VIEW, permissions) &&
      (activeTab === TABS.BORROWER.value ||
        received_by_company_id === borrowerTeamId ||
        parentComment?.received_by_company?.id === borrowerTeamId)
    );
  }, [activeTab, received_by_company_id, projectBorrowerTeam, parentComment, permissions]);

  return {
    state: getHookState(drawRequestsQuery),
    postMessage,
    commentField,
    comments,
    commentsCount: projectCommentsQuery?.data?.count,
    handleSearchSubmit,
    clearSearch,
    search,
    commentsAreLoading: projectCommentsQuery?.isLoading,
    isPostingComment: postComment.isLoading || patchComment.isLoading,
    tags,
    reportTag,
    setReportTag,
    updateComment,
    showReportTag,
    showSendToInspectorCheckbox,
    isSendToInspectorChecked,
    setIsSendToInspectorChecked,
    pinComment,
    permittedTabs,
    handleTabChange,
    activeTab,
    replyMode,
    handleReplyClick,
    replyField,
    parentComment,
    handleCloseReplyMode,
    expandedCommentId,
    handleExpandThreadClick,
    thread: commentThreadQuery.data?.results,
    threadIsLoading: commentThreadQuery.isLoading,
    hideCommentInput,
    tablePagination,
    isCurrentProjectArchived: isInactiveProject(project?.data?.status),
    handleEditCommentClick,
    editCommentField,
    editedComment,
    saveEditedComment,
    isExternalComment,
    sortingOptions,
    sortingValue,
  };
};
