import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { colors } from '@theme';
import { ConfirmationModal, MessagePanel, RightDrawer, LumpSumModal } from '@components';
import { PopupTypeEnum } from '@interfaces';
import { useRequestTable } from './controller';
import { useParams } from 'react-router-dom';
import { DeleteIcon } from '@svgAsComponents';
import MilestoneListContainer from '../MilestoneListContainer';

const RequestTable: FC<{ isSubmissionProcess?: boolean }> = ({ isSubmissionProcess }) => {
  const { projectId } = useParams();
  const controller = useRequestTable(isSubmissionProcess);
  const {
    showDeleteModal,
    setShowDeleteModal,
    deleteRequest,
    isDeleting,
    drawRequest,
    rightMenu,
    invalidationAfterBulkUpdate,
  } = controller;

  return (
    <Stack
      sx={{
        backgroundColor: colors.background.white,
        pt: 1,
        borderRadius: '4px',
        flex: 1,
      }}
    >
      <MilestoneListContainer controller={controller} />
      {controller.openLumpSumModal && (
        <LumpSumModal
          open={controller.openLumpSumModal}
          onClose={() => controller.setOpenLumpSumModal(false)}
          fieldKey="requested_amount"
          onSuccess={invalidationAfterBulkUpdate}
          initValue={drawRequest?.requested_amount?.toString()}
        />
      )}
      <ConfirmationModal
        open={showDeleteModal}
        title="Delete request"
        text="This action cannot be undone. All edits associated with this draw request will be permanently deleted."
        onClose={() => setShowDeleteModal(false)}
        confirmCallback={deleteRequest}
        type={PopupTypeEnum.ERROR}
        isLoading={isDeleting}
        confirmButtonLabel="Delete"
        icon={DeleteIcon}
      />
      <RightDrawer {...rightMenu}>
        <MessagePanel
          projectId={projectId}
          requestId={drawRequest?.id}
          source="requests_tab__comments"
        />
      </RightDrawer>
    </Stack>
  );
};
export default RequestTable;
