import React, { FC, useContext } from 'react';
import { Button, Stack, Tooltip, Typography, IconButton, ButtonGroup } from '@mui/material';
import { Route, Routes, useParams } from 'react-router-dom';
import { HookState, PopupTypeEnum, RequestModalTypeEnums } from '@interfaces';
import {
  ButtonWithTooltip,
  ConfirmationModal,
  Filter,
  LineItemEdit,
  MessagePanel,
  MilestoneListWithPatch,
  PDFViewerNew,
  RightDrawer,
  ServiceMessage,
  IconButtonWithTooltip,
  LumpSumModal,
} from '@components';
import { colors } from '@theme';
import * as Controller from './controller';
import { AutofillAllowanceV2 } from '../index';
import RequestSummary from '../RequestSummary';
import { getTooltipText } from '@utils';
import {
  CommentIconWithIndicator,
  DeleteIcon,
  NoDataImage,
  UpdateIcon,
  AddProofpointIcon,
  DownloadIcon,
  BudgetReviewIcon,
} from '@svgAsComponents';
import { ControllerInterface } from './interface';
import { SettingsContext, useLaunchDarklyFlags } from '@context';
import { ProductionRequestTableV2 } from '../../../productionBuild';
import ProjectSmartSummaryButton from '../ProjectSmartSummaryButton';
import { useDateFormatter } from '@hooks';
import { LoadingButton } from '@mui/lab';

const DrawRequestTabWrapper = ({ drawRequest }) => {
  if (!drawRequest) {
    return (
      <Stack justifyContent="center" alignItems="center" sx={{ flexGrow: 1 }}>
        <NoDataImage size={200} />
        <Typography sx={{ mt: 4, textAlign: 'center' }} variant="h2">
          There are no created draws in the project yet.
        </Typography>
      </Stack>
    );
  }

  return <DrawRequestTab drawRequest={drawRequest} />;
};

const DrawRequestTab = ({ drawRequest }) => {
  const { projectId } = useParams();
  const controller = Controller.useDrawRequest(projectId, drawRequest);

  switch (controller.state) {
    case HookState.ERROR: {
      return <ServiceMessage text="Draws" />;
    }

    case HookState.SUCCESS:
    case HookState.FETCHING: {
      return (
        <Routes>
          <Route
            path="draw-requests/:requestId"
            element={<DrawRequest controller={controller} />}
          />
        </Routes>
      );
    }

    default:
      return null;
  }
};

const DrawRequest: FC<{ controller: ControllerInterface }> = ({ controller }) => {
  const { projectId } = useParams();

  const {
    drawRequest,
    updateQueries,
    initColumns,
    milestones,
    totals,
    handleAutofillLenderAllowance,
    handleFiltersChange,
    filterOptions,
    showAutofillButton,
    canViewReport,
    addLineList,
    openDraft,
    canDeleteRequest,
    deleteRequest,
    reportCallback,
    openPdfReport,
    pdf,
    close,
    isDeleting,
    isAutofillLoading,
    confirmModalType,
    setConfirmModalType,
    lineItemModal,
    setLineItemModal,
    openEditMilestoneModal,
    filterValue,
    isMilestoneMutatingOrFetching,
    isCurrentProjectActive,
    projectName,
    openPdfViewer,
    continueDraftButtonLabel,
    updateRightDrawer,
    showAddNewLine,
    rightMenu,
    creatingNewLine,
    isRequestDraftOrInReview,
    isLumpSumApprovalAvailable,
    openLumpSumModal,
    setOpenLumpSumModal,
    isCommentsAvailable,
  } = controller;
  const { dateFormatter } = useDateFormatter();
  const { isCurrentProjectArchived, isPHBProject } = useContext(SettingsContext);
  const flags = useLaunchDarklyFlags();

  const confirmationModal = {
    [RequestModalTypeEnums.DELETE]: (
      <ConfirmationModal
        open
        title="Delete request"
        text="This action cannot be undone. All edits associated with this draw request will be permanently deleted."
        onClose={() => setConfirmModalType('')}
        confirmCallback={deleteRequest}
        type={PopupTypeEnum.ERROR}
        isLoading={isDeleting}
        confirmButtonLabel="Delete"
        icon={DeleteIcon}
      />
    ),
  };

  return (
    <>
      <RequestSummary />
      <Stack sx={{ mt: 2, backgroundColor: colors.background.white, borderRadius: '4px', flex: 1 }}>
        {isPHBProject ? (
          <ProductionRequestTableV2 />
        ) : (
          <MilestoneListWithPatch
            rowReordering={isRequestDraftOrInReview}
            withColumnIndication
            withProgress
            requestStatus={drawRequest?.status}
            tableKey={drawRequest?.type}
            refetch={updateQueries}
            requestId={drawRequest?.id}
            key={drawRequest?.id}
            initColumns={initColumns}
            milestones={milestones}
            totals={totals}
            exportToCSV
            withCredit
            contingencyMode={drawRequest?.contingency_mode}
            headerLeftPart={[
              {
                Component: (
                  <Filter
                    filterLabel="Show"
                    onChangeCallback={handleFiltersChange}
                    options={filterOptions}
                    filterValue={filterValue}
                    disabled={isMilestoneMutatingOrFetching}
                    source="requests_tab__line_items_table__filter"
                  />
                ),
              },
            ]}
            headerRightPart={[
              {
                Component: (
                  <Stack spacing={1} direction="row" alignItems="center">
                    {!flags?.['ENG_9464_table_v3_improvements'] && (
                      <>
                        {canViewReport && (
                          <ButtonGroup disableElevation>
                            <Button
                              size="small"
                              variant="new"
                              color="secondary"
                              onClick={drawRequest?.report ? openPdfReport : reportCallback}
                            >
                              {drawRequest?.report ? 'Download Report' : 'Generate report'}
                            </Button>
                            {drawRequest?.report && (
                              <Tooltip
                                title={`Generate new report. Last version: ${dateFormatter({
                                  date: drawRequest?.report?.created_at,
                                  withTime: true,
                                })}`}
                              >
                                <IconButton color="primary" onClick={reportCallback}>
                                  <UpdateIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          </ButtonGroup>
                        )}
                        {showAddNewLine && (
                          <LoadingButton
                            loading={creatingNewLine}
                            size="small"
                            onClick={addLineList}
                            data-cy="draw_add_line_item_button"
                            variant="new"
                            color="secondary"
                          >
                            Add line item
                          </LoadingButton>
                        )}
                      </>
                    )}
                    {showAutofillButton && (
                      <AutofillAllowanceV2
                        drawRequest={drawRequest}
                        handleAutofillLender={handleAutofillLenderAllowance}
                        loading={isAutofillLoading}
                      />
                    )}
                    <ProjectSmartSummaryButton
                      drawRequestId={drawRequest?.id}
                      projectName={projectName}
                      openPdfViewer={openPdfViewer}
                      source="requests_tab__documents_summary"
                    />
                    {continueDraftButtonLabel && (
                      <ButtonWithTooltip
                        disabled={!isCurrentProjectActive}
                        tooltipText={getTooltipText({ nonActiveProject: !isCurrentProjectActive })}
                        size="small"
                        onClick={openDraft}
                        dataTestName="draw_continue_draft_button"
                      >
                        {continueDraftButtonLabel}
                      </ButtonWithTooltip>
                    )}
                  </Stack>
                ),
              },
            ]}
            openEditMilestoneModal={openEditMilestoneModal}
            footerDataTestName="draw_milestone_list_table_footer"
            menuItems={[
              ...(flags?.['ENG_9464_table_v3_improvements']
                ? [
                    ...(showAddNewLine
                      ? [
                          {
                            action: addLineList,
                            text: 'Add line item',
                            icon: <AddProofpointIcon size={18} />,
                            dataTestName: 'draw_add_line_item_button',
                          },
                        ]
                      : []),
                    ...(canViewReport
                      ? [
                          {
                            action: drawRequest?.report ? openPdfReport : reportCallback,
                            text: (
                              <Stack
                                alignItems="center"
                                direction="row"
                                justifyContent="space-between"
                                minWidth={drawRequest?.report ? 200 : 100}
                              >
                                {drawRequest?.report ? 'Download Report' : 'Generate report'}
                                {drawRequest?.report && (
                                  <Tooltip
                                    title={`Generate new report. Last version: ${dateFormatter({
                                      date: drawRequest?.report?.created_at,
                                      withTime: true,
                                    })}`}
                                  >
                                    <IconButton color="primary" onClick={reportCallback}>
                                      <UpdateIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </Stack>
                            ),
                            icon: <DownloadIcon size={18} />,
                            dataTestName: 'draw_generate_report_button',
                          },
                        ]
                      : []),
                  ]
                : []),
              ...(flags?.['ENG_9465_lump_sum_draw'] && isLumpSumApprovalAvailable
                ? [
                    {
                      action: () => setOpenLumpSumModal(true),
                      text: 'Enter lump sum',
                      icon: <BudgetReviewIcon size={18} />,
                      dataTestName: 'add_lump_sum__menu_item',
                    },
                  ]
                : []),
              ...(canDeleteRequest
                ? [
                    {
                      action: () => setConfirmModalType(RequestModalTypeEnums.DELETE),
                      text: (
                        <Typography
                          variant="body3SemiBold"
                          sx={{ color: colors.status.error.medium }}
                        >
                          Delete request
                        </Typography>
                      ),
                      icon: <DeleteIcon size={16} color={colors.status.error.medium} />,
                      disabled: isCurrentProjectArchived,
                      disabledTooltipText: getTooltipText({ isCurrentProjectArchived }),
                      dataTestName: 'delete_request__menu_item',
                    },
                  ]
                : []),
            ]}
            source="requests_tab__line_items_table"
            isLoading={controller.state === HookState.FETCHING}
            commentIconComponent={
              isCommentsAvailable &&
              (flags?.[`ENG_7895_table_v3__${drawRequest?.type}`] ? (
                <Tooltip title="Comments">
                  <Button
                    data-cy="requests_tab__comments__icon"
                    startIcon={
                      <CommentIconWithIndicator
                        hasComments={drawRequest?.comments_preview?.has_comments}
                        hasUnreadComments={drawRequest?.comments_preview?.has_unread_comments}
                      />
                    }
                    variant="shortIcon"
                    onClick={updateRightDrawer()}
                  />
                </Tooltip>
              ) : (
                <IconButtonWithTooltip
                  tooltipText="Comments"
                  onClick={updateRightDrawer()}
                  data-cy="requests_tab__comments__icon"
                  size="large"
                >
                  {/** add permission */}
                  <CommentIconWithIndicator
                    hasComments={drawRequest?.comments_preview?.has_comments}
                    hasUnreadComments={drawRequest?.comments_preview?.has_unread_comments}
                  />
                </IconButtonWithTooltip>
              ))
            }
          />
        )}
      </Stack>

      {confirmationModal[confirmModalType]}
      {pdf && <PDFViewerNew pdfFile={pdf[0]} close={close} />}
      {lineItemModal?.open && (
        <LineItemEdit
          lineItem={lineItemModal?.lineItem}
          open={lineItemModal?.open}
          setLineItemModal={setLineItemModal}
          type={lineItemModal?.type}
          request={drawRequest}
        />
      )}
      <RightDrawer {...rightMenu}>
        <MessagePanel
          projectId={projectId}
          requestId={drawRequest?.id}
          source="requests_tab__comments"
        />
      </RightDrawer>
      {openLumpSumModal && (
        <LumpSumModal
          open={openLumpSumModal}
          onClose={() => setOpenLumpSumModal(false)}
          fieldKey="approved_amount"
          initValue={drawRequest?.approved_amount?.toString()}
        />
      )}
    </>
  );
};

export default DrawRequestTabWrapper;
