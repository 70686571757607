import React from 'react';
import { VisibilityEnum } from '@interfaces';
import { Skeleton, Switch, Tooltip } from '@mui/material';
import { isCreatedByUser } from '@utils';

const companyPrivacySwitcher = ({ isLoading, source, userId, updateDocumentPrivacy }) => ({
  Header: 'Company private',
  accessor: 'scope',
  disableFilters: true,
  disableSortBy: true,
  Cell: ({ cell: { value }, row }) => {
    const [checked, setChecked] = React.useState(value !== VisibilityEnum.EVERYONE);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);
      updateDocumentPrivacy({
        documentId: row?.original?.id,
        scope: event.target.checked ? VisibilityEnum.COMPANY : VisibilityEnum.EVERYONE,
      });
    };

    if (isLoading) return <Skeleton />;
    return (
      <Tooltip title={checked ? 'Make public' : 'Make private'}>
        <Switch
          checked={checked}
          onChange={handleChange}
          disabled={!isCreatedByUser(row?.original?.created_by?.user_id, userId)}
          sx={{ ml: 2 }}
          data-cy={`${source}__company_private__switch`}
        />
      </Tooltip>
    );
  },
});

export default companyPrivacySwitcher;
