import { useQueries } from 'react-query';

import { EnumTypeForList, HookState, QueryNamesEnums } from '@interfaces';
import {
  getProjectExitStrategiesList,
  getProjectPropertyTypes,
  getProjectTypes,
} from '@globalService';
import { getHookState } from '@utils';

export type ControllerInterface = {
  hookState: HookState;
  projectTypes: EnumTypeForList[];
  propertyTypes: EnumTypeForList[];
  exitStrategies: EnumTypeForList[];
};

export const useGeneralCreateProject = (): ControllerInterface => {
  const [projectTypesQuery, propertyTypesQuery, exitStrategies] = useQueries([
    { queryKey: [QueryNamesEnums.GET_PROJECT_TYPES], queryFn: getProjectTypes.bind(this, '') },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_PROPERTY_TYPES],
      queryFn: getProjectPropertyTypes.bind(this, ''),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_EXIT_STRATEGIES],
      queryFn: getProjectExitStrategiesList,
    },
  ]);

  return {
    hookState: getHookState([projectTypesQuery, propertyTypesQuery]),
    projectTypes: projectTypesQuery.data?.results || [],
    propertyTypes: propertyTypesQuery.data?.results || [],
    exitStrategies: exitStrategies.data?.results || [],
  };
};
