import { useMemo } from 'react';
import { MilestoneListColumnTypeV2 } from '../MilestoneList/columnsV2/common';
import MilestoneColumns from '../MilestoneList/columnsV2';

export type ControllerInterface = {
  columns: Array<MilestoneListColumnTypeV2>;
};

export const useModelsTable = (): ControllerInterface => {
  const initColumns = useMemo(
    () => [
      'quantity',
      'square',
      'bedrooms',
      'bathrooms',
      'additionalPropertyDetails',
      'description',
      'deleteUnit',
    ],
    [],
  );

  const columns = useMemo<Array<MilestoneListColumnTypeV2>>(
    () => initColumns?.map((item: string) => MilestoneColumns[item]),
    [initColumns],
  );

  return { columns };
};
