import { useContext, useMemo, useRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  IDocument,
  IDrawRequest,
  IMilestone,
  IProject,
  IProjectChecklist,
  QueryNamesEnums,
} from '@interfaces';
import {
  getDrawRequest,
  getDrawRequestDocumentsList,
  getDrawRequestItemChecklist,
  getDrawRequestMilestones,
  getProject,
} from '@globalService';
import { getHookState, isDrawRequest, isReallocationEnabled, isRequestDraft } from '@utils';
import { useQuery } from 'react-query';
import { IRequestController } from '../interfaces';
import {
  excludeCommentsWithTotalsAllQueryFields,
  LineItemFilterValues,
  TEAM_ROLES,
} from '@constants';
import { SettingsContext, useLaunchDarklyFlags } from '@context';
import { useRightMenu, useCommentsAndDocumentsPreview } from '@hooks';

export const useRequest = (): IRequestController => {
  const flags = useLaunchDarklyFlags();
  const { projectId, requestId: drawRequestId } = useParams();
  const mainRef = useRef(null);
  const { isPHBProject } = useContext(SettingsContext);

  const { updateCommentsPreviewInfo } = useCommentsAndDocumentsPreview({
    projectId,
    drawRequestId,
  });

  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: updateCommentsPreviewInfo,
  });

  const [openLumpSumModal, setOpenLumpSumModal] = useState(false);
  // This flag is used to prevent the lump sum modal from being auto opened multiple times
  const [isFirstAutoOpenLumpSumModal, setIsFirstAutoOpenLumpSumModal] = useState(true);

  const drawRequestData = useQuery<IDrawRequest, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST,
      { projectId, drawRequestId, query: excludeCommentsWithTotalsAllQueryFields },
    ],
    getDrawRequest.bind(this, {
      projectId,
      drawRequestId,
      query: excludeCommentsWithTotalsAllQueryFields,
    }),
  );

  const projectQuery = useQuery<IProject, Error>(
    [QueryNamesEnums.GET_PROJECT, { projectId }],
    getProject.bind(this, projectId),
  );

  const isLumpSumEnabled = useMemo(
    () =>
      flags?.['ENG_9465_lump_sum_draw'] &&
      projectQuery.data?.is_lump_sum_enabled &&
      isDrawRequest(drawRequestData.data),
    [projectQuery.data, flags, drawRequestData.data],
  );

  useEffect(() => {
    if (
      isLumpSumEnabled &&
      drawRequestData.isSuccess &&
      !drawRequestData.data?.requested_amount &&
      !drawRequestData.data?.is_lump_sum_request &&
      isFirstAutoOpenLumpSumModal
    ) {
      // auto open the lump sum modal if the requested amount is not set and condition is met
      setOpenLumpSumModal(true);
      setIsFirstAutoOpenLumpSumModal(false);
    }
  }, [
    isLumpSumEnabled,
    drawRequestData.data?.is_lump_sum_request,
    drawRequestData.data?.requested_amount,
    drawRequestData.isSuccess,
    isFirstAutoOpenLumpSumModal,
  ]);

  const query =
    '{name,id,requested_adjustments,requested_amount,requested_revised_estimate,milestone_submit_id,index,project_milestone{index}}';
  const drawRequestMilestonesQuery = useQuery<{ results: IMilestone[] }, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
      { projectId, drawRequestId, query, filterKey: LineItemFilterValues.ALL.filterKey },
    ],
    getDrawRequestMilestones.bind(this, {
      projectId,
      drawRequestId,
      restQlParams: query,
      filterKey: LineItemFilterValues.ALL.filterKey,
    }),
    { enabled: Boolean(projectId && drawRequestId && !isPHBProject) },
  );

  const drawRequestDocumentsQuery = useQuery<{ results: IDocument[] }, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST_DOCUMENTS,
      { projectId, drawRequestId, stringQueryParams: '' },
    ],
    getDrawRequestDocumentsList.bind(this, { projectId, drawRequestId, stringQueryParams: '' }),
  );

  const drawRequestPolicyQuery = useQuery<IProjectChecklist[], Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_ITEM_CHECKLIST, { projectId, drawRequestId }],
    getDrawRequestItemChecklist.bind(this, projectId, drawRequestId),
    { enabled: isDrawRequest(drawRequestData.data) },
  );

  const documentsSummary = useMemo(() => {
    const summary = {};
    drawRequestDocumentsQuery?.data?.results?.map(
      (document) =>
        (summary[document.document_type || 'Policy documents'] =
          (+summary[document.document_type || 'Policy documents'] || 0) + 1),
    );
    return summary;
  }, [drawRequestDocumentsQuery?.data]);

  const totalRetainageRequested = useMemo(
    () => drawRequestData.data?.totals?.all?.retainage_release_requested || 0,
    [drawRequestData.data],
  );

  const showDocumentsSection = useMemo(() => {
    const hasPolicyItems = drawRequestPolicyQuery.data?.some(
      (o) => o.assignee_role === TEAM_ROLES.Owner,
    );
    return Boolean(Object.entries(documentsSummary)?.length) || hasPolicyItems;
  }, [drawRequestPolicyQuery.data, documentsSummary]);

  const isReallocationAllowed = useMemo(
    () => isReallocationEnabled(drawRequestData.data, projectQuery.data),
    [drawRequestData.data, projectQuery.data],
  );

  const isRejectionResubmit = useMemo(
    () => isRequestDraft(drawRequestData.data?.status) && drawRequestData.data?.is_resubmit,
    [drawRequestData.data],
  );

  const returnedRequestNotes = useMemo(() => {
    if (isRejectionResubmit) {
      return drawRequestData.data?.is_resubmit_change_reason;
    }

    return '';
  }, [isRejectionResubmit, drawRequestData.data]);

  return {
    error: drawRequestData.error,
    drawRequest: drawRequestData.data,
    state: getHookState(drawRequestData),
    mainRef,
    project: projectQuery.data,
    documentsSummary,
    feesAmount: drawRequestData.data?.fees_amount || 0,
    allMilestones: drawRequestMilestonesQuery.data?.results,
    showDocumentsSection,
    totalRetainageRequested,
    isReallocationAllowed,
    isRejectionResubmit,
    handleRightDrawerOpenerClick,
    rightMenu,
    returnedRequestNotes,
    openLumpSumModal,
    setOpenLumpSumModal,
    isLumpSumEnabled,
  };
};
