import { useParams, useNavigate } from 'react-router-dom';
import { QueryNamesEnums } from '@interfaces';
import { useGetData } from '@context';

export const useRolesPermission = () => {
  const { roleId: role_id } = useParams();
  const navigate = useNavigate();

  const roleQuery = useGetData({
    type: QueryNamesEnums.GET_COMPANY_TEAM_BY_ID,
    args: {
      role_id,
    },
    keys: ['permissions'],
  });

  const goBack = () => {
    navigate(-1);
  };

  const groupPermissionsByGroup = (permissions) => {
    const groupedPermissions = {};

    permissions.forEach((permission) => {
      if (!permission.group) return;
      const group = permission.group;

      if (!groupedPermissions[group]) {
        groupedPermissions[group] = [];
      }
      groupedPermissions[group].push(permission);
    });

    return groupedPermissions;
  };

  return {
    status: roleQuery.status,
    shownPermissions: groupPermissionsByGroup(roleQuery.data?.permissions || []),
    goBack,
  };
};
