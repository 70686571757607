import React, { FC } from 'react';
import { Tooltip, IconButton } from '@mui/material';
import { ComponentWithTooltipProps, IconButtonElementProps } from './interface';

const IconButtonElement: FC<IconButtonElementProps> = ({ children, dragRef, ...props }) => {
  return (
    <IconButton ref={dragRef} {...props}>
      {children}
    </IconButton>
  );
};

const IconButtonWithTooltip: FC<ComponentWithTooltipProps> = ({
  tooltipText,
  placement = 'bottom',
  dragRef,
  ...props
}) => {
  return tooltipText ? (
    <Tooltip title={tooltipText} placement={placement}>
      <div>
        <IconButtonElement {...props} dragRef={dragRef} />
      </div>
    </Tooltip>
  ) : (
    <IconButtonElement {...props} dragRef={dragRef} />
  );
};

export default IconButtonWithTooltip;
