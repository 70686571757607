import React from 'react';
import { colors } from '@theme';
import { GalleryViewIcon, MenuItemIcon } from '@svgAsComponents';
import { IconButtonWithTooltip } from '@components';
const ViewSwitcher = ({
  isListView,
  source,
  handleChangeView,
}: {
  isListView: boolean;
  source: string;
  handleChangeView: (listView: boolean) => void;
}) => {
  return (
    <>
      <ViewSwitchIcon
        onClick={() => handleChangeView(false)}
        mainColor={isListView ? colors.neutral.lightest : colors.neutral.darker}
        secondaryColor={isListView ? colors.neutral.darker : colors.neutral.white}
        Icon={GalleryViewIcon}
        dataTestName={`${source}__gallery__button`}
        tooltipText="Gallery view"
      />
      <ViewSwitchIcon
        onClick={() => handleChangeView(true)}
        mainColor={isListView ? colors.neutral.darker : colors.neutral.lightest}
        secondaryColor={isListView ? colors.neutral.white : colors.neutral.darker}
        Icon={MenuItemIcon}
        dataTestName={`${source}__list__button`}
        tooltipText="List view"
      />
    </>
  );
};

const ViewSwitchIcon = ({
  onClick,
  mainColor,
  secondaryColor,
  Icon,
  dataTestName,
  tooltipText,
}) => (
  <IconButtonWithTooltip
    tooltipText={tooltipText}
    onClick={onClick}
    sx={{
      backgroundColor: mainColor,
      borderRadius: 0,
      '&:hover': {
        backgroundColor: mainColor,
      },
      '& svg path:hover': {
        fill: secondaryColor,
      },
    }}
    data-cy={dataTestName}
  >
    <Icon color={secondaryColor} />
  </IconButtonWithTooltip>
);

export default ViewSwitcher;
