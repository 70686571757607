import { Typography } from '@mui/material';
import { MilestoneListColumnTypeV2 } from './common';
import React from 'react';

const rowNumber: MilestoneListColumnTypeV2 = {
  field: 'row_number',
  headerName: '#',
  description: '',
  headerClassName: 'text',
  headerAlign: 'left',
  align: 'left',
  cellClassName: 'text',
  flex: 1,
  resizable: false,
  editable: false,
  sortable: false,
  filterable: false,
  groupable: false,
  aggregable: false,
  hideable: false,
  minWidth: 40,
  maxWidth: 40,
  renderCell: ({ row }) => {
    return <Typography variant="body2">{row.rowNumber}</Typography>;
  },
  editableByMilestone: (row) => row.isEditableV2,
};

export default rowNumber;
