import { TEAM_ROLES } from '@constants';
import { PermissionNamesEnums, QueryNamesEnums } from '@interfaces';
import { FilterObject } from '../interface';

export const inverstorFilter: FilterObject = {
  title: 'Investor',
  filterKey: 'investor',
  needToUpdateUrl: true,
  needToUpdateSetting: true,
  permissionKey: PermissionNamesEnums.INVERSTOR_VIEW,
  getDataParamsPaginated: (pagination, q) => ({
    type: QueryNamesEnums.GET_COMPANIES_BY_ROLE,
    keys: ['id', 'name'],
    args: {
      role: TEAM_ROLES.Investor,
      pagination,
      q,
    },
    options: {
      strictSerialize: (data) =>
        data.map((item) => ({
          value: item?.id,
          label: item?.name,
        })),
    },
  }),
  cypressSelector: 'filters__inverstor',
  withPagination: true,
};
