import * as React from 'react';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium';
import Box from '@mui/material/Box';
import { colors, typography } from '@theme';
import { Button, Stack, Tooltip } from '@mui/material';
import {
  CollapseIcon,
  ColumnChooserIcon,
  DensityIcon,
  ExpandIcon,
  UpdateIcon,
} from '@svgAsComponents';
import { HeaderIcons } from '../interface';
import { TRIGGERHEIGHT } from '../styled';

const CustomToolbar = ({
  headerLeft,
  headerRight,
  clearSettings,
  dotsMenu,
  withBuiltInSearch,
  onFullScreen,
  setOnFullScreen,
}: {
  headerLeft?: HeaderIcons[];
  headerRight?: HeaderIcons[];
  clearSettings?: () => void;
  dotsMenu?: React.ReactElement;
  withBuiltInSearch?: boolean;
  onFullScreen?: boolean;
  setOnFullScreen?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <GridToolbarContainer
      sx={{
        pl: 2,
        pr: 2,
        pb: 1,
        pt: 1,
        display: 'flex',
        height: TRIGGERHEIGHT,
        justifyContent: 'space-between',
      }}
    >
      <Stack spacing={1} direction="row" alignItems="stretch">
        {withBuiltInSearch && (
          <GridToolbarQuickFilter
            sx={{
              pt: '4px',
              borderRadius: '2px',
              backgroundColor: colors.neutral.lightest,
            }}
            inputProps={{
              sx: {
                padding: 0,
                color: colors.text.medium,
                ...typography.body3,
              },
            }}
            variant="outlined"
            quickFilterParser={(searchInput: string) =>
              searchInput
                .split(',')
                .map((value) => value.trim())
                .filter((value) => value !== '')
            }
          />
        )}
        {Boolean(headerLeft?.length) && (
          <Stack direction="row" alignItems="center">
            {headerLeft?.map(({ Component }, index) => (
              <Box key={index.toString()}>{Component}</Box>
            ))}
          </Stack>
        )}
      </Stack>
      <Stack spacing={1} direction="row" flex={1} justifyContent="flex-end" alignItems="center">
        {Boolean(headerRight?.length) && (
          <Stack direction="row" spacing={1} alignItems="center" sx={{ ml: 'auto' }}>
            {headerRight?.map(({ Component }, index) => (
              <Box key={index.toString()}>{Component}</Box>
            ))}
          </Stack>
        )}
        <GridToolbarColumnsButton
          slotProps={{
            button: {
              id: 'columnMenuButton',
              variant: 'shortIcon',
              startIcon: <ColumnChooserIcon color={colors.icons.green} />,
            },
          }}
        />
        <GridToolbarDensitySelector
          slotProps={{
            button: { variant: 'shortIcon', startIcon: <DensityIcon /> },
          }}
        />
        {setOnFullScreen && (
          <Button
            startIcon={
              onFullScreen ? (
                <CollapseIcon size={24} color={colors.icons.green} />
              ) : (
                <ExpandIcon size={24} color={colors.icons.green} />
              )
            }
            variant="shortIcon"
            onClick={() => setOnFullScreen(!onFullScreen)}
          />
        )}
        <Tooltip title="Reset table settings">
          <Button
            startIcon={<UpdateIcon size={24} color={colors.icons.green} />}
            variant="shortIcon"
            onClick={clearSettings}
          />
        </Tooltip>
        {dotsMenu}
      </Stack>
    </GridToolbarContainer>
  );
};

export default CustomToolbar;
