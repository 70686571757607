import { useContext, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  ChangeRequestConfigEnum,
  ErrorDual,
  HookState,
  ICompanyFull,
  ICompanyOverview,
  PermissionNamesEnums,
  QueryNamesEnums,
} from '@interfaces';
import { getHookState, isRestricted, parsePathErrorDual } from '@utils';
import { getMyCompany, updateCompany } from '@globalService';
import { enqueueSnackbar } from 'notistack';
import { PermissionsContext } from '@context';

export type ControllerInterface = {
  state: HookState;
  changeRequestConfiguration: { label: string; value: string };
  options: { label: string; value: string }[];
  companyUpdate: (value: string) => void;
  hasPoliciesEditPermission: boolean;
};

const CHANGE_REQUEST_CONFIGURATION_OPTIONS = [
  { label: 'Changes accepted with or without draws', value: ChangeRequestConfigEnum.ALL_REQUESTS },
  { label: 'Changes accepted with draws only', value: ChangeRequestConfigEnum.ONLY_DRAW_REQUESTS },
  {
    label: 'Changes accepted stand alone only',
    value: ChangeRequestConfigEnum.ONLY_CHANGE_REQUESTS,
  },
];

export const useCompanyOtherSettings = (): ControllerInterface => {
  const queryClient = useQueryClient();
  const { permissions } = useContext(PermissionsContext);
  const hasPoliciesEditPermission = useMemo(
    () => !isRestricted(PermissionNamesEnums.POLICIES_EDIT, permissions),
    [permissions],
  );

  const companyQuery = useQuery<ICompanyFull, Error>(
    [QueryNamesEnums.GET_MY_COMPANY],
    getMyCompany.bind(this),
  );

  const companyUpdate = (value: string) => {
    updateCompanyMutation.mutateAsync({ company: { change_request_mode: value } });
  };

  const updateCompanyMutation = useMutation<Response, ErrorDual, ICompanyOverview>(updateCompany, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryNamesEnums.GET_MY_COMPANY);
    },
    onError: (error) => {
      enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
    },
  });

  return {
    state: getHookState(companyQuery),
    changeRequestConfiguration: CHANGE_REQUEST_CONFIGURATION_OPTIONS.find(
      (option) => option.value === companyQuery.data?.change_request_mode,
    ),
    companyUpdate,
    options: CHANGE_REQUEST_CONFIGURATION_OPTIONS,
    hasPoliciesEditPermission,
  };
};
