import React from 'react';
import { LoadingSkeleton, RolesWorkflow, ServiceMessage, StyledBox } from '@components';
import * as Controller from './controller';
import { Stack, Typography } from '@mui/material';

const ProjectWorkFlow = () => {
  const { status, roles } = Controller.useProjectWorkflow();

  if (status === 'loading') {
    return <LoadingSkeleton type="overviewBlock" />;
  }

  if (status === 'error') {
    return <ServiceMessage text="company teams" />;
  }

  return (
    <Stack
      direction="column"
      sx={{ height: '100%', scrollMargin: '64px', flex: 1 }}
      justifyContent="space-between"
    >
      <Stack spacing={3} sx={{ p: { md: 3, xs: 2 } }}>
        <StyledBox>
          <Stack spacing={2}>
            <StyledBox>
              <Stack>
                <Typography variant="h3" mb={2}>
                  Approval flow
                </Typography>
                <Typography variant="body1">
                  Here you can set required user approvals for approval flow, sort & remove teams
                  from approval flow.
                </Typography>
              </Stack>
              <RolesWorkflow disabled roles={roles} />
            </StyledBox>
          </Stack>
        </StyledBox>
      </Stack>
    </Stack>
  );
};

export default ProjectWorkFlow;
