import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import {
  ApproveCreditPanel,
  InfoBreakdownPanel,
  InfoCreditPanel,
  LineItemBreakdownPanel,
  LineItemDataWrapper,
  MessagePanel,
  PhotosPanel,
  SubmitCreditPanel,
} from '@components';
import { Tab, Tabs } from '@mui/material';
import { Documents } from '../../sections/project/components';
import {
  IMilestone,
  MessagePanelTabsEnum,
  PermissionNamesEnums,
  RequestTableTabsEnum,
  RequestTableTabTypesEnum,
} from '@interfaces';
import { PermissionsContext, SettingsContext, useLaunchDarklyFlags } from '@context';
import { isRestricted } from '@utils';

const LineItemRightDrawer: FC<{
  rightDrawerParams?: {
    milestoneId?: string;
    tab: RequestTableTabsEnum;
    inspectionId?: string;
    requestId?: string;
    projectId?: string;
    milestoneSubmitId?: string;
    type?: RequestTableTabTypesEnum;
    creditAvailable?: boolean;
    onCreditChangeCompleted?: (data: IMilestone) => void;
    includeChildren?: boolean;
  };
  setOpen?: CallableFunction;
  source: string;
}> = ({ rightDrawerParams, source, setOpen }) => {
  const flags = useLaunchDarklyFlags();
  const { isPHBProject } = useContext(SettingsContext);
  const { permissions } = useContext(PermissionsContext);

  const showCreditTab = useMemo(
    () =>
      !isRestricted(PermissionNamesEnums.DRAWREQUESTS_CREDIT_REQUEST, permissions) ||
      !isRestricted(PermissionNamesEnums.DRAWREQUESTS_CREDIT_APPROVE, permissions),
    [permissions],
  );

  const withComments = !isRestricted(PermissionNamesEnums.COMMENTS_VIEW, permissions);

  const TABS = {
    documents: { label: 'Documents', value: RequestTableTabsEnum.DOCUMENTS },
    comments: { label: 'Comments', value: RequestTableTabsEnum.COMMENTS },
    photos: { label: 'Progress photos', value: RequestTableTabsEnum.PHOTOS },
  };
  const LINE_ITEM_TABS = {
    breakdown: { label: 'Breakdown', value: RequestTableTabsEnum.BREAKDOWN },
    credit: { label: 'Credit', value: RequestTableTabsEnum.CREDIT },
  };
  const [tab, setTab] = useState(
    rightDrawerParams.tab || withComments ? TABS.comments.value : TABS.photos.value,
  );
  const handleChange = (_event: React.SyntheticEvent, newValue: RequestTableTabsEnum) => {
    setTab(newValue);
  };
  const existingTab = useMemo(() => Object.values(TABS).find((item) => item.value === tab), [tab]);
  useEffect(() => {
    if (rightDrawerParams.tab) setTab(rightDrawerParams.tab);
  }, [rightDrawerParams.tab]);
  const lineItemTab = useMemo(
    () => Object.values(LINE_ITEM_TABS).find((item) => item.value === tab),
    [tab],
  );

  return (
    <>
      {existingTab && (
        <Tabs
          TabIndicatorProps={{
            style: { transition: 'none' },
            children: <span />,
          }}
          value={tab}
          onChange={handleChange}
          sx={{ mb: 2 }}
        >
          {!rightDrawerParams?.inspectionId && (
            <Tab
              label={TABS.documents.label}
              value={TABS.documents.value}
              sx={{ textTransform: 'none' }}
              data-cy={`${source}__tab__documents`}
            />
          )}
          {(!isPHBProject || !rightDrawerParams?.milestoneId) && (
            <Tab
              label={TABS.photos.label}
              value={TABS.photos.value}
              sx={{ textTransform: 'none' }}
              data-cy={`${source}__tab__photos`}
            />
          )}
          {withComments && (
            <Tab
              label={TABS.comments.label}
              value={TABS.comments.value}
              sx={{ textTransform: 'none' }}
              data-cy={`${source}__tab__comments`}
            />
          )}
        </Tabs>
      )}
      {lineItemTab && flags?.['ENG_7938_breakdown_of_line_item'] && (
        <LineItemDataWrapper>
          <Tabs
            TabIndicatorProps={{
              style: { transition: 'none' },
              children: <span />,
            }}
            value={tab}
            onChange={handleChange}
            sx={{ my: 3 }}
          >
            <Tab
              label={LINE_ITEM_TABS.breakdown.label}
              value={LINE_ITEM_TABS.breakdown.value}
              sx={{ textTransform: 'none' }}
              data-cy={`${source}__tab__breakdown`}
            />
            {showCreditTab && (
              <Tab
                label={LINE_ITEM_TABS.credit.label}
                value={LINE_ITEM_TABS.credit.value}
                sx={{ textTransform: 'none' }}
                data-cy={`${source}__tab__credit`}
              />
            )}
          </Tabs>
          {tab === LINE_ITEM_TABS.credit?.value &&
            (rightDrawerParams.type === RequestTableTabTypesEnum.SUBMIT ? (
              <SubmitCreditPanel
                setOpen={setOpen}
                source={`${source}__body__credit_submit_panel`}
                {...rightDrawerParams}
              />
            ) : rightDrawerParams.type === RequestTableTabTypesEnum.APPROVE ? (
              <ApproveCreditPanel
                setOpen={setOpen}
                source={`${source}__body__credit_approve_panel`}
                {...rightDrawerParams}
              />
            ) : (
              <InfoCreditPanel
                source={`${source}__body__credit_info_panel`}
                {...rightDrawerParams}
              />
            ))}
          {tab === LINE_ITEM_TABS.breakdown.value &&
            (rightDrawerParams.type === RequestTableTabTypesEnum.INFO ? (
              <InfoBreakdownPanel
                source={`${source}__body__breakdown_info_panel`}
                {...rightDrawerParams}
              />
            ) : (
              <LineItemBreakdownPanel
                type={rightDrawerParams.type}
                source={`${source}__body__breakdown_panel`}
                setOpen={setOpen}
                {...rightDrawerParams}
              />
            ))}
        </LineItemDataWrapper>
      )}
      {tab === TABS.documents.value && (
        <Documents {...rightDrawerParams} source={`${source}__body__documents`} />
      )}
      {tab === TABS.photos.value && (
        <PhotosPanel
          milestoneId={rightDrawerParams.milestoneId}
          drawRequestId={rightDrawerParams.requestId}
          inspectionId={rightDrawerParams.inspectionId}
          source={`${source}__body__photos`}
        />
      )}
      {tab === TABS.comments.value && (
        <MessagePanel
          milestoneId={rightDrawerParams.milestoneId}
          inspectionId={rightDrawerParams.inspectionId}
          requestId={rightDrawerParams.requestId}
          projectId={rightDrawerParams.projectId}
          includeChildren={rightDrawerParams.includeChildren}
          source={`${source}__body__comments`}
          showTabs={!rightDrawerParams.inspectionId}
          tab={
            rightDrawerParams.inspectionId
              ? MessagePanelTabsEnum.SERVICES
              : MessagePanelTabsEnum.INTERNAL
          }
        />
      )}
      {tab === RequestTableTabsEnum.CREDIT_INFO && (
        <InfoCreditPanel
          source={`${source}__body__credit_info_panel`}
          withProjectName
          {...rightDrawerParams}
        />
      )}
      {tab === RequestTableTabsEnum.CREDIT_SUBMIT && (
        <SubmitCreditPanel
          setOpen={setOpen}
          withProjectName
          source={`${source}__body__credit_submit_panel`}
          {...rightDrawerParams}
        />
      )}
      {tab === RequestTableTabsEnum.CREDIT_APPROVE && (
        <ApproveCreditPanel
          setOpen={setOpen}
          withProjectName
          source={`${source}__body__credit_approve_panel`}
          {...rightDrawerParams}
        />
      )}
    </>
  );
};

export default LineItemRightDrawer;
