import React from 'react';
import { Button } from '@mui/material';

import { ConfirmationModal } from '@components';
import { PopupTypeEnum, IServiceOrder } from '@interfaces';

import * as Controller from './controller';

const ServiceCancelButton = ({
  serviceOrder,
  size = 'medium',
  source,
}: {
  serviceOrder: IServiceOrder;
  size?: 'medium' | 'small' | 'large';
  source?: string;
}) => {
  const controller = Controller.useServiceOrderDocuments(serviceOrder);

  const { showCancelButton, confirmModal, isServiceManual, handleCancelService, isSubmitting } =
    controller;

  if (!showCancelButton) return null;
  return (
    <>
      <Button
        size={size}
        color="error"
        onClick={(e) => {
          e.stopPropagation();
          confirmModal.askConfirm();
        }}
        data-cy={`${source}__cancel_service__button`}
        sx={{ minWidth: isServiceManual ? 'auto' : '155px' }}
      >
        {isServiceManual ? 'Cancel' : 'Request cancellation'}
      </Button>
      <ConfirmationModal
        open={confirmModal.isConfirmModalOpened}
        title={isServiceManual ? 'Cancel service' : 'Request service cancellation'}
        text={
          isServiceManual
            ? 'Are you sure you want to cancel this service?'
            : 'Are you sure you want to request the cancellation of this service?'
        }
        onClose={confirmModal.closeConfirmModal}
        confirmCallback={handleCancelService}
        type={PopupTypeEnum.CONFIRMATION}
        cancelButtonLabel="Close"
        isLoading={isSubmitting}
      />
    </>
  );
};

export default ServiceCancelButton;
