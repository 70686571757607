import { Dispatch, useCallback, useEffect, useMemo, useState, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  IInspection,
  IInspectionAgency,
  IInspectionClosePopupEnum,
  ServiceProviderEnum,
  ServiceOrderStatusEnum,
  IProject,
  IInspectionFields,
} from '@interfaces';
import { DateFieldModel, StringFieldModel, useStringFieldModel } from '@models';
import {
  isAutomatedServiceProvider,
  debounceFunction,
  isValidDate,
  createDateAsLocal,
  hasApprovedReallocation,
  hasRequestedReallocation,
  isPseudoAutomatedInspection,
  isInspectionServiceManual,
  isTruePicInspection,
} from '@utils';
import { ConfirmModalHookInterface, useConfirmationModal, useInspectionFields } from '@hooks';
import { useInspectionQueriesAndMutations } from './utils';
export interface ControllerInterface {
  inspectionRequestedAt: DateFieldModel;
  inspectionComment: StringFieldModel;
  drNumber: string;
  project: Partial<IProject>;
  cancelInputsValues: () => void;
  handleInspectionRequest: () => Promise<void>;
  isSubmitting: boolean;
  inspectionAgencySelected: IInspectionAgency;
  setInspectionAgencySelected: Dispatch<React.SetStateAction<IInspectionAgency>>;
  inspectionAgenciesList: IInspectionAgency[];
  manualInspectionAgenciesList: IInspectionAgency[];
  otherInspectionName: IInspectionAgency;
  setOtherInspection: Dispatch<React.SetStateAction<IInspectionAgency>>;
  isOtherInspectionSelected: boolean;
  isAutomatedServiceProviderSelected: boolean;
  isSubmitDisabled: boolean;
  orderInspectionWithoutAppraisalModal: ConfirmModalHookInterface;
  requestHasReallocationModal: ConfirmModalHookInterface;
  handleCheckAppraisalBeforeOrderingInspection: () => void;
  borrowerComment: string;
  inspectionFields: IInspectionFields;
  tooltipText: string;
  submitDisabledTooltipText: string;
  createdInspection: IInspection;
  reallocationWarningText: { text: string; title: string };
  goToRequest: () => void;
  isPseudoAutomatedInspectionSelected: boolean;
  popup: IInspectionClosePopupEnum;
  setPopup: Dispatch<SetStateAction<IInspectionClosePopupEnum>>;
  showDisclaimer: boolean;
  isTruePicInspection: boolean;
}

export const useInspectionDialog = (
  projectId: string,
  onClose: (popupType?: IInspectionClosePopupEnum) => void,
  drawRequestId?: string,
): ControllerInterface => {
  const navigate = useNavigate();
  const [isNewlyCreatedInspection, setIsNewlyCreatedInspection] = useState(false);
  const [inspectionAgencySelected, setInspectionAgencySelected] = useState(null);
  const [otherInspectionName, setOtherInspection] = useState(null);
  const inspectionComment = useStringFieldModel({
    initValue: '',
  });
  const orderInspectionWithoutAppraisalModal = useConfirmationModal();
  const requestHasReallocationModal = useConfirmationModal();
  const [popup, setPopup] = useState<IInspectionClosePopupEnum>(null);

  const {
    myCompanyQuery,
    drawRequestQuery,
    drawRequestInspectionsQuery,
    projectInspectionsQuery,
    projectQuery,
    projectDocumentsQuery,
    inspectionAgenciesData,
    deleteInspectionMutation,
    updateInfoProject,
    createInspection,
    orderInspection,
    createInspectionAgencyMutation,
    createdInspection,
    getCreatedInspection,
    scheduleInspection,
  } = useInspectionQueriesAndMutations({
    projectId,
    drawRequestId,
    onClose,
    setPopup,
    isNewlyCreatedInspection,
    setIsNewlyCreatedInspection,
  });

  const {
    additionalContactName,
    additionalContactPhone,
    accessCode,
    primaryContactUserNotRequired,
    primaryContactUserList,
    isBorrowerUsersLoading,
    inspectionRequestedAt,
    isProjectInspectionSettingsUpdated,
    primaryContactPhone,
  } = useInspectionFields({
    project: projectQuery?.data,
  });

  useEffect(() => {
    if (hasRequestedReallocation(drawRequestQuery.data)) requestHasReallocationModal.askConfirm();
  }, [drawRequestQuery.data]);

  const reallocationWarningText = useMemo(
    () =>
      hasApprovedReallocation(drawRequestQuery.data)
        ? {
            text: 'The inspection will be ordered with the updated schedule of values based on the approved reallocation.',
            title: 'Order with approved reallocations',
          }
        : {
            text: 'The inspection will be ordered without updated budgets as no reallocation requests were approved.\n\nYou can return to the Request tab to approve the request or proceed with the inspection order.',
            title: 'Order without reallocations',
          },
    [drawRequestQuery.data],
  );

  useEffect(() => {
    if (drawRequestId ? !drawRequestInspectionsQuery.isSuccess : !projectInspectionsQuery.isSuccess)
      return;
    const createdInspection = getCreatedInspection(
      drawRequestId
        ? drawRequestInspectionsQuery.data?.results
        : projectInspectionsQuery.data?.results,
    );

    if (!createdInspection) {
      createInspection.mutateAsync({
        projectId,
        inspectionData: { ...(drawRequestId ? { draw_request: drawRequestId } : {}) },
      });
    } else {
      inspectionComment.setValue(createdInspection.gc_comment || '');

      if (createdInspection?.gc_requested_at) {
        inspectionRequestedAt.setValue(createDateAsLocal(createdInspection.gc_requested_at));
      }
    }
  }, [drawRequestInspectionsQuery.isSuccess, projectInspectionsQuery.isSuccess, drawRequestId]);

  const isOtherInspectionSelected = useMemo(
    () => inspectionAgencySelected?.display_name === 'Other',
    [inspectionAgencySelected],
  );

  const isAutomatedServiceProviderSelected = useMemo(
    () => isAutomatedServiceProvider(inspectionAgencySelected?.service),
    [inspectionAgencySelected],
  );
  const isPseudoAutomatedInspectionSelected = useMemo(
    () => isPseudoAutomatedInspection(inspectionAgencySelected?.service),
    [inspectionAgencySelected],
  );

  const showDisclaimer = useMemo(() => {
    return (
      isTruePicInspection(inspectionAgencySelected?.service) ||
      isPseudoAutomatedInspection(inspectionAgencySelected?.service) ||
      isAutomatedServiceProvider(inspectionAgencySelected?.service)
    );
  }, [inspectionAgencySelected?.service]);

  const handleInspectionRequest = async () => {
    let inspectionAgencyId = '';

    if (!isOtherInspectionSelected) {
      inspectionAgencyId = inspectionAgencySelected?.id;
    } else if (!otherInspectionName?.id) {
      // Create new inspection agency if it's newly created input without id
      const data = await createInspectionAgencyMutation.mutateAsync({
        company: myCompanyQuery.data?.id,
        display_name: otherInspectionName?.display_name,
        service: ServiceProviderEnum.MANUAL,
      });
      inspectionAgencyId = data?.id;
    } else {
      inspectionAgencyId = otherInspectionName.id;
    }

    const inspectionValues = {
      comment: inspectionComment.value,
      inspection_agency: inspectionAgencyId,
      gc_requested_at:
        inspectionRequestedAt?.value && isValidDate(inspectionRequestedAt.value)?.value
          ? inspectionRequestedAt.value
          : new Date(),
    };

    if (isProjectInspectionSettingsUpdated) {
      await updateInfoProject.mutateAsync({
        projectId,
        json: {
          inspection_additional_contact_name: additionalContactName.value,
          inspection_additional_contact_phone: additionalContactPhone.valueToSave,
          inspection_entry_access_code: accessCode.value,
          inspection_primary_contact_user_id: primaryContactUserNotRequired.value?.id || null,
        },
      });
    }

    const payload = {
      projectId,
      inspectionData: {
        ...inspectionValues,
        ...(createdInspection?.id && { inspectionId: createdInspection.id }),
      },
    };

    await orderInspection.mutateAsync(payload);
  };

  const cancelInputsValues = () => {
    inspectionComment.setValue(createdInspection?.gc_comment || '');
    inspectionRequestedAt.setValue(
      createdInspection?.gc_requested_at ? new Date(createdInspection.gc_requested_at) : null,
    );
    if (isNewlyCreatedInspection) {
      deleteInspectionMutation.mutateAsync({ projectId, inspectionId: createdInspection?.id });
    }
  };

  const inspectionAgenciesList = useMemo(
    () => [
      ...(inspectionAgenciesData.data?.results?.filter(
        (o) => !isInspectionServiceManual(o.service),
      ) || []),
      { display_name: 'Other', service: ServiceProviderEnum.MANUAL, id: null },
    ],
    [inspectionAgenciesData.data],
  );

  const manualInspectionAgenciesList = useMemo(
    () =>
      inspectionAgenciesData.data?.results?.filter(
        (o) => isInspectionServiceManual(o.service) && o.display_name !== 'Other',
      ) || [],
    [inspectionAgenciesData.data],
  );

  useEffect(() => {
    if (inspectionAgenciesList.length) {
      const defaultAgency =
        createdInspection?.inspection_agency || projectQuery.data?.inspection_agency;

      if (isInspectionServiceManual(defaultAgency?.service)) {
        setInspectionAgencySelected({
          display_name: 'Other',
          service: ServiceProviderEnum.MANUAL,
          id: null,
        });
        setOtherInspection(defaultAgency);
      } else {
        const serviceFromList = inspectionAgenciesList?.find(
          (item) => item.id === defaultAgency?.id,
        );
        setInspectionAgencySelected(serviceFromList || inspectionAgenciesList[0]);
      }
    }
  }, [inspectionAgenciesList, createdInspection, projectQuery.data?.inspection_agency]);

  const submitDisabledTooltipText = useMemo(() => {
    if (accessCode.value?.length > 50) return 'Access code must be less than 50 characters';
    if (isOtherInspectionSelected && !otherInspectionName?.display_name)
      return 'Please enter the name of the inspection agency.';

    if (
      !primaryContactUserNotRequired?.value &&
      (!additionalContactName.isValid || !additionalContactPhone.isValid)
    )
      return 'At least one contact with phone number (primary or additional) is required.';

    if (
      !(inspectionRequestedAt?.value
        ? inspectionRequestedAt.validate() && isValidDate(inspectionRequestedAt?.value)?.value
        : true) ||
      !additionalContactPhone.validate()
    )
      return 'Please enter valid values.';
    return '';
  }, [
    isOtherInspectionSelected,
    otherInspectionName,
    inspectionRequestedAt?.value,
    additionalContactPhone?.isValid,
    additionalContactName?.isValid,
    primaryContactUserNotRequired?.value,
  ]);

  const handleCheckAppraisalBeforeOrderingInspection = useCallback(
    debounceFunction(async () => {
      if (isAutomatedServiceProviderSelected && !projectDocumentsQuery.data?.results?.length) {
        orderInspectionWithoutAppraisalModal.askConfirm();
      } else {
        handleInspectionRequest();
      }
    }, 300),
    [
      isAutomatedServiceProviderSelected,
      projectDocumentsQuery.data?.results,
      handleInspectionRequest,
    ],
  );

  const tooltipText = useMemo(() => {
    if (isNewlyCreatedInspection || !createdInspection) return '';
    const hasComment = Boolean(createdInspection.gc_comment);
    const hasDate = Boolean(createdInspection.gc_requested_at);

    if (hasComment && hasDate) {
      return 'Borrower has already supplied the earliest availability date and additional information with the Draw request submission.';
    }
    if (hasComment) {
      return 'Borrower has already supplied additional information with the Draw request submission.';
    }
    if (hasDate) {
      return 'Borrower has already supplied the earliest availability date with the Draw request submission.';
    }
    return '';
  }, [createdInspection, isNewlyCreatedInspection]);

  const goToRequest = useCallback(() => {
    navigate(`/projects/${projectId}/draws/draw-requests/${drawRequestId}/`);
  }, [projectId, drawRequestId]);

  return {
    inspectionComment,
    borrowerComment: createdInspection?.gc_comment || '',
    drNumber: drawRequestQuery.data?.number,
    project: projectQuery.data,
    cancelInputsValues,
    handleInspectionRequest,
    isSubmitting:
      orderInspection.isLoading || createInspection.isLoading || scheduleInspection.isLoading,
    inspectionAgencySelected,
    setInspectionAgencySelected,
    inspectionAgenciesList,
    manualInspectionAgenciesList,
    otherInspectionName,
    setOtherInspection,
    isOtherInspectionSelected,
    isAutomatedServiceProviderSelected,
    isSubmitDisabled: Boolean(submitDisabledTooltipText),
    orderInspectionWithoutAppraisalModal,
    handleCheckAppraisalBeforeOrderingInspection,
    inspectionRequestedAt,
    inspectionFields: {
      additionalContactName,
      additionalContactPhone,
      accessCode,
      primaryContactUserList,
      isBorrowerUsersLoading,
      inspectionRequestedAt,
      primaryContactUserNotRequired,
      primaryContactPhone,
    },
    tooltipText,
    submitDisabledTooltipText,
    createdInspection,
    requestHasReallocationModal,
    reallocationWarningText,
    goToRequest,
    isPseudoAutomatedInspectionSelected,
    popup,
    setPopup,
    showDisclaimer,
    isTruePicInspection: isTruePicInspection(inspectionAgencySelected?.service),
  };
};
