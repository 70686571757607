import { isRestricted, tableHeaders } from '@utils';
import { ColumnWidth, PermissionNamesEnums } from '@interfaces';

export const getColumns = ({
  selectProject,
  unSelectProject,
  isLoading,
  projectPropertyTypes,
  isLender,
  isInvestor,
  isInternalUser,
  permissions,
  updateRightDrawer,
  onWatchIconClick,
  selectedProjects,
  isAllSelected,
  onSelectAllClick,
  isCommentsAvailable,
  isCustomerSuccess,
}) => [
  ...(isCustomerSuccess || isLender
    ? [
        tableHeaders.selectProject({
          isLoading,
          onCheckboxClick: (isProjectSelected: boolean, projectId: string) =>
            isProjectSelected ? selectProject(projectId) : unSelectProject(projectId),
          checkIsProjectSelected: (projectId) => selectedProjects.includes(projectId),
          onSelectAllClick,
          isAllSelected,
        }),
      ]
    : []),
  ...(isLender
    ? [
        tableHeaders.projectWatchers({
          isLoading,
          onIconClick: onWatchIconClick,
        }),
      ]
    : []),
  tableHeaders.headerTemplate({
    headerName: 'Loan #',
    accessor: 'loan.external_id',
    isLoading,
    disableSortBy: true,
  }),
  tableHeaders.headerTemplate({
    headerName: 'Project name',
    accessor: 'name',
    isLoading,
    minWidth: ColumnWidth.WIDE_TEXT,
  }),
  tableHeaders.headerTemplate({
    headerName: 'Address',
    accessor: 'address.address_1',
    isLoading,
    disableSortBy: true,
  }),
  tableHeaders.headerTemplate({
    headerName: 'City',
    accessor: 'address.city',
    isLoading,
    disableSortBy: true,
  }),
  tableHeaders.state({ isLoading, accessor: 'address.state' }),
  tableHeaders.headerTemplate({
    headerName: 'Project type',
    accessor: 'project_type',
    isLoading,
  }),
  tableHeaders.propertyType({
    header: 'Existing property type',
    isLoading,
    valuesList: projectPropertyTypes,
    accessor: 'property_existing_type',
    minWidth: ColumnWidth.WIDE_TEXT,
  }),
  tableHeaders.propertyType({
    header: 'Proposed property type',
    isLoading,
    valuesList: projectPropertyTypes,
    accessor: 'property_proposed_type',
  }),
  tableHeaders.projectStatus({
    isLoading,
    accessor: 'status',
  }),
  tableHeaders.requestName({ isLoading }),
  tableHeaders.statusWithRole({
    header: 'Request status',
    accessor: 'draw_request.status',
    isLoading,
    riskRadar: true,
    disableSortBy: true,
  }),
  ...(!isRestricted(PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS, permissions)
    ? [
        tableHeaders.headerTemplate({
          headerName: 'Customer',
          accessor: 'customer.name',
          isLoading,
          disableSortBy: true,
        }),
      ]
    : []),
  ...(isLender || isInvestor || isInternalUser
    ? [
        tableHeaders.headerTemplate({
          headerName: 'Borrower',
          accessor: 'borrower_name',
          isLoading,
          disableSortBy: true,
        }),
      ]
    : []),
  ...(isInvestor || isInternalUser
    ? [
        tableHeaders.headerTemplate({
          headerName: 'Lender',
          accessor: 'lender_name',
          isLoading,
          disableSortBy: true,
        }),
      ]
    : []),
  ...(!isRestricted(PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS, permissions)
    ? [
        tableHeaders.headerTemplate({
          headerName: 'Investor',
          accessor: 'investor_name',
          isLoading,
          disableSortBy: true,
        }),
      ]
    : []),
  ...(!isRestricted(PermissionNamesEnums.DRAWREQUESTS_COORDINATOR_VIEW, permissions)
    ? [
        tableHeaders.headerTemplate({
          headerName: 'Coordinator',
          accessor: 'coordinator.full_name',
          isLoading,
          disableSortBy: true,
        }),
      ]
    : []),
  tableHeaders.amount({
    header: 'Loan commitment',
    accessor: 'loan.loc_commitment',
    isLoading,
    disableSortBy: true,
  }),
  tableHeaders.loanType({
    isLoading,
  }),
  tableHeaders.amount({
    header: 'Original scheduled value ($)',
    accessor: 'project_funds.original_estimate',
    isLoading,
    disableSortBy: true,
  }),
  tableHeaders.amount({
    header: 'Revised scheduled value ($)',
    accessor: 'project_funds.revised_estimate',
    isLoading,
    disableSortBy: true,
    minWidth: ColumnWidth.WIDE_TEXT,
  }),
  tableHeaders.amount({
    header: 'Total construction holdback ($)',
    accessor: 'construction_holdback_total',
    isLoading,
    disableSortBy: true,
    minWidth: ColumnWidth.WIDE_TEXT,
  }),
  tableHeaders.amount({
    header: 'Disbursed construction holdback ($)',
    accessor: 'construction_holdback_current',
    isLoading,
    disableSortBy: true,
    minWidth: ColumnWidth.WIDE_TEXT,
  }),
  tableHeaders.amount({
    header: 'Remaining construction holdback ($)',
    accessor: 'construction_holdback_available',
    isLoading,
    disableSortBy: true,
    minWidth: ColumnWidth.WIDE_TEXT,
  }),
  tableHeaders.date({
    header: 'Funding date',
    accessor: 'loan.close_date',
    isLoading,
    disableSortBy: true,
  }),
  tableHeaders.date({
    header: 'Original maturity date',
    accessor: 'loan.maturity_date',
    isLoading,
    disableSortBy: true,
  }),
  tableHeaders.date({
    header: 'Extended maturity date ',
    accessor: 'loan.extended_maturity_date',
    isLoading,
    disableSortBy: true,
  }),
  tableHeaders.headerTemplate({
    isLoading,
    headerName: 'Length of project (month)',
    accessor: 'duration',
    disableSortBy: true,
    minWidth: ColumnWidth.WIDE_TEXT,
  }),
  tableHeaders.percentage({
    accessor: 'inspector_allowance_rate',
    header: 'Inspector allowance (%)',
    isLoading,
    disableSortBy: true,
    minWidth: ColumnWidth.TEXT,
  }),
  tableHeaders.percentage({
    accessor: 'lender_allowance_rate',
    header: 'Lender allowance (%)',
    isLoading,
    disableSortBy: true,
    minWidth: ColumnWidth.TEXT,
  }),
  tableHeaders.percentage({
    accessor: 'gap',
    header: 'Variance to lender allowance (%)',
    isLoading,
    disableSortBy: true,
    minWidth: ColumnWidth.WIDE_TEXT,
  }),
  ...(!isRestricted(PermissionNamesEnums.PROJECT_BUDGET_EDIT, permissions) ||
  !isRestricted(PermissionNamesEnums.PROJECT_ONBOARDING, permissions)
    ? [
        tableHeaders.yesNo({
          header: 'Locked budget',
          accessor: 'is_budget_locked',
          isLoading,
          dataTestName: 'projects__table__body__is_budget_locked',
        }),
      ]
    : []),
  ...(!isRestricted(PermissionNamesEnums.SCORES_VIEW, permissions)
    ? [
        tableHeaders.projectScore({
          headerName: 'Project health',
          accessor: 'scores.project_score',
          isLoading,
        }),
      ]
    : []),
  tableHeaders.projectActions({
    isLoading,
    updateRightDrawer,
    source: 'projects__table__body__actions',
    isCommentsAvailable,
  }),
];
