import { colors } from '@theme';
import { IIconPosition } from '@interfaces';
import { ReactElement } from 'react';

export type Sizes = 'body1' | 'body2' | 'body3' | 'printBody1' | 'label';

export interface ComponentProps {
  size?: Sizes;
  label?: string;
  text?: string;
  justifyContent?: string;
  color?: string;
  colorLabel?: string;
  icon?: ReactElement;
  iconTooltip?: string | ReactElement;
  withEllipsis?: boolean;
  tooltipText?: string;
  marginRightAfterLabel?: boolean;
  minHeight?: number;
  labelStyle?: { [key: string]: string | number | { fontSize: string } };
  textStyle?: { [key: string]: string | number | { fontSize: string } };
  valueIcon?: ReactElement;
  valueIconTooltip?: string | ReactElement;
  valueIconTooltipDataTestName?: string;
  noWrap?: boolean;
  iconPosition?: IIconPosition;
  textDataTestName?: string;
  valueIconDataTestName?: string;
  labelDataTestName?: string;
  iconDataTestName?: string;
  fullWidth?: boolean;
}

export const defaultProps = {
  size: 'body3' as Sizes,
  label: '',
  justifyContent: 'space-between',
  color: colors.text.dark,
  colorLabel: colors.text.medium,
  tooltipText: '',
  marginRightAfterLabel: true,
  minHeight: 24,
  labelStyle: {},
  iconPosition: 'right' as IIconPosition,
  iconTooltip: '',
};

export interface ComponentWithPreloadProps extends ComponentProps {
  isLoading: boolean;
}
