import { useCallback, useEffect, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';

import { useStringFieldModel } from '@models';
import { PolicyItemTypesEnum } from '@interfaces';
import { ComponentProps, ControllerInterface } from './interface';
import { PolicyItemTypesList } from '@constants';
import { emptyHTML, isAttestationPolicyItem, isSubmissionPolicySource } from '@utils';
import { useLaunchDarklyFlags } from '@context';

export const useEditPolicyItem = ({
  onClose,
  policyItem,
  saveItem,
  source,
  items,
  isProjectActivation,
}: ComponentProps): ControllerInterface => {
  const flags = useLaunchDarklyFlags();
  const [policyItemType, setPolicyItemType] = useState(
    policyItem?.type ||
      (!isSubmissionPolicySource(source) || isProjectActivation
        ? PolicyItemTypesEnum.STANDARD
        : PolicyItemTypesEnum.REQUIRED_DOCUMENT_UPLOAD),
  );

  const itemName = useStringFieldModel({
    initValue: policyItem?.label || '',
    validateOnChange: true,
    validationRule: (value) =>
      Boolean(value.trim()) &&
      !items?.find((item) => item.label === value.trim() && item.id !== policyItem?.id),
  });

  const description = useStringFieldModel({
    initValue: policyItem?.description || '',
    validationRule: (value) =>
      !isAttestationPolicyItem(policyItemType) || (Boolean(value.trim()) && !emptyHTML(value)),
    validateOnChange: true,
  });

  useEffect(() => {
    const defaultPolicyItemType =
      !isSubmissionPolicySource(source) || isProjectActivation
        ? PolicyItemTypesEnum.STANDARD
        : PolicyItemTypesEnum.REQUIRED_DOCUMENT_UPLOAD;
    itemName.setValue(policyItem?.label || '');
    description.setValue(policyItem?.description || '');
    setPolicyItemType(policyItem?.type || defaultPolicyItemType);
    return () => {
      itemName.setValue('');
      description.setValue('');
      setPolicyItemType(defaultPolicyItemType);
    };
  }, [policyItem, isProjectActivation, source]);

  const handleSaveItem = useCallback(() => {
    const descriptionValue = emptyHTML(description.value) ? '' : description.value;

    if (itemName.validate() && description.validate()) {
      const editedItem = {
        label: itemName.value,
        description: descriptionValue,
        ...(policyItemType && { type: policyItemType }),
        ...(policyItem?.created_by_team && { created_by_team: policyItem.created_by_team }),
      };
      saveItem(editedItem);
      onClose();
    }
  }, [itemName, description, policyItem, policyItemType]);

  return {
    itemName,
    description,
    handleSaveItem,
    policyItemType,
    policyItemTypesList: PolicyItemTypesList.filter(
      (item) =>
        flags?.['ENG_9055_photo_upload_policy_requirement'] ||
        item.value !== PolicyItemTypesEnum.REQUIRED_PHOTO_UPLOAD,
    ),
    handlePolicyItemTypeChange: (event: SelectChangeEvent<string>) =>
      setPolicyItemType(event.target.value as PolicyItemTypesEnum),
  };
};
