import React, { Dispatch, FC, SetStateAction } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { FundingSourceStatusEnum, FundingSourceTypeEnum } from '@interfaces';
import { StringFieldModel } from '@models';
import { EditIcon } from '@svgAsComponents';
import { colors } from '@theme';
import { currencyFormatter } from '@utils';
import { IconButtonWithTooltip } from '@components';
interface ActiveFundingSource {
  color: string;
  backgroundColor: string;
  sourceName: string;
  type: string;
  title?: string;
  field: StringFieldModel;
  sourceType: FundingSourceTypeEnum;
  sourceStatus: FundingSourceStatusEnum;
  fractionValue?: string;
}

interface FundingSourceCardProps {
  fundingSource: ActiveFundingSource;
  setActiveFundingSource: Dispatch<SetStateAction<ActiveFundingSource>>;
  source: string;
  isEditable?: boolean;
}

const FundingSourceCard: FC<FundingSourceCardProps> = ({
  fundingSource,
  setActiveFundingSource,
  source,
  isEditable = true,
}) => {
  if (!fundingSource) return null;

  if (fundingSource.field.floatValue) {
    return (
      <Stack
        sx={{
          paddingX: '40px',
          paddingY: '24px',
          backgroundColor: fundingSource.backgroundColor,
          minWidth: '200px',
          minHeight: '146px',
        }}
        spacing={1}
      >
        <Stack direction="row" sx={{ justifyContent: 'flex-end' }}>
          {isEditable && (
            <IconButtonWithTooltip
              onClick={() => setActiveFundingSource({ ...fundingSource })}
              tooltipText="Edit"
              data-cy={`${source}__funding_source__${fundingSource.type}__edit__button`}
            >
              <EditIcon color={colors.icons.green} size={24} />
            </IconButtonWithTooltip>
          )}
        </Stack>
        <Typography
          color={colors.text.heading}
          variant="labelSemiBold"
          data-cy={`${source}__funding_source__${fundingSource.type}__name__value`}
        >
          {fundingSource.sourceName}
        </Typography>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Typography
            variant="h3"
            color={fundingSource.color}
            data-cy={`${source}__funding_source__${fundingSource.type}__amount__value`}
          >
            {`${currencyFormatter(fundingSource.field.floatValue)} ${fundingSource.fractionValue ? '/' : ''}`}
          </Typography>
          {Boolean(fundingSource.fractionValue) && (
            <Typography
              variant="body3SemiBold"
              color={fundingSource.color}
              data-cy={`${source}__funding_source__${fundingSource.type}__fraction__value`}
            >
              {fundingSource.fractionValue}
            </Typography>
          )}
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      sx={{
        paddingX: '40px',
        paddingY: '29px',
        backgroundColor: fundingSource.backgroundColor,
        minHeight: '146px',
      }}
      justifyContent="center"
      spacing={1}
    >
      <Typography variant="h3">{fundingSource.sourceName}</Typography>
      {isEditable && (
        <Button
          sx={{ width: 56 }}
          onClick={() => setActiveFundingSource({ ...fundingSource })}
          data-cy={`${source}__funding_source__${fundingSource.type}__add__button`}
          size="small"
        >
          Add
        </Button>
      )}
    </Stack>
  );
};

export default FundingSourceCard;
