import React, { FC } from 'react';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { MemoizedPhoto, TablePaginationNew, VideoCardWithPopper } from '@components';
import { HookState, IPhoto } from '@interfaces';
import { usePhotoList } from './controller';
import { PhotoListProps } from '../../interface';
import { useLaunchDarklyFlags } from '@context';

const PhotoList: FC<PhotoListProps> = ({
  onPhotoClick,
  source,
  context,
  projectId,
  milestoneId,
  drawRequestId,
  inspectionId,
  headerText,
  setTotalDocCount,
}) => {
  const {
    state,
    photos,
    documentsCount,
    tablePagination: { page, rowsPerPage, rowsPerPageOptions, onPageChange, onRowsPerPageChange },
  } = usePhotoList({
    context,
    projectId,
    milestoneId,
    drawRequestId,
    inspectionId,
    setTotalDocCount,
  });
  const flags = useLaunchDarklyFlags();
  const videoCardEnabled = flags?.['ENG_9457_video_player'];

  if (state === HookState.FETCHING) return <LoadingSkeleton />;
  if (state === HookState.SUCCESS && !photos?.length) return null;

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">{headerText}</Typography>

        <TablePaginationNew
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          itemsCount={documentsCount}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          source={source}
        />
      </Stack>

      <Stack direction="row" flexWrap="wrap" alignItems="flex-start">
        {photos?.map((media, index) => (
          <>
            {media.mime_type.includes('video') && videoCardEnabled ? (
              <Box sx={{ mr: 1 }} key={media.id}>
                <VideoCardWithPopper video={media as IPhoto} />
              </Box>
            ) : (
              <MemoizedPhoto
                key={media.id}
                photo={media}
                onClick={() =>
                  onPhotoClick({
                    photosArray: photos,
                    photo: media,
                    index,
                  })
                }
                source={source}
              />
            )}
          </>
        ))}
      </Stack>
    </Stack>
  );
};

const LoadingSkeleton = () => (
  <Stack
    alignItems="flex-start"
    justifyContent="center"
    direction="row"
    spacing={1}
    sx={{ width: '100%' }}
  >
    <Skeleton height={140} width={125} />
    <Skeleton height={140} width={125} />
    <Skeleton height={140} width={125} />
    <Skeleton height={140} width={125} />
  </Stack>
);

export default PhotoList;
