import React from 'react';
import {
  Box,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  PopoverVirtualElement,
  Stack,
  Tooltip,
  Button,
  Typography,
  Divider,
} from '@mui/material';
import { ComponentProps, OriginHorizontalType } from './interface';
import { CheckSmallIcon, MenuDotsIcon } from '@svgAsComponents';
import { IMenuItem } from '@interfaces';
import { colors } from '@theme';
import { IconButtonWithTooltip } from '@components';

export function DotsVerticalMenu({
  menuItems,
  anchorOriginHorizontal = 'center',
  transformOriginHorizontal = 'center',
  dataTestName,
  closeOnItemClick,
  type = 'icon',
  size = 'large',
  tooltipText = 'Options',
}: Readonly<ComponentProps>) {
  return (
    <VerticalMenu
      menuItems={menuItems}
      anchorOriginHorizontal={anchorOriginHorizontal}
      transformOriginHorizontal={transformOriginHorizontal}
      dataTestName={dataTestName}
      closeOnItemClick={closeOnItemClick}
    >
      <>
        {type === 'button' && (
          <Tooltip title={tooltipText}>
            <Button
              variant="new"
              color="secondary"
              sx={{ minWidth: 40, maxWidth: 40 }}
              data-cy={dataTestName}
            >
              <Stack sx={{ minHeight: 24, minWidth: 24 }} alignItems="center">
                <MenuDotsIcon size={24} />
              </Stack>
            </Button>
          </Tooltip>
        )}
        {type === 'icon' && (
          <IconButtonWithTooltip size={size} data-cy={dataTestName} tooltipText={tooltipText}>
            <MenuDotsIcon />
          </IconButtonWithTooltip>
        )}
        {type === 'shortIcon' && (
          <Tooltip title={tooltipText}>
            <Button startIcon={<MenuDotsIcon />} variant="shortIcon" data-cy={dataTestName} />
          </Tooltip>
        )}
      </>
    </VerticalMenu>
  );
}

export function VerticalMenu({
  menuItems,
  disabled,
  children,
  anchorOriginHorizontal = 'center',
  transformOriginHorizontal = 'center',
  closeOnItemClick,
  activeItem,
}: Readonly<ComponentProps>) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    event.stopPropagation();
    if (!disabled) setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Stack onClick={handlePopoverOpen} sx={{ cursor: 'pointer' }}>
        {children}
      </Stack>
      {anchorEl && (
        <MenuComponent
          anchorOriginHorizontal={anchorOriginHorizontal}
          transformOriginHorizontal={transformOriginHorizontal}
          anchorEl={anchorEl}
          menuItems={menuItems}
          handlePopoverClose={handlePopoverClose}
          closeOnItemClick={closeOnItemClick}
          activeItem={activeItem}
        />
      )}
    </>
  );
}

export const MenuItemComponent = ({
  item,
  index,
  divider,
  handlePopoverClose,
  closeOnItemClick = true,
  sectionBreak,
  sectionTitle,
  activeItem,
}: {
  item: IMenuItem;
  index: number;
  divider: boolean;
  closeOnItemClick?: boolean;
  handlePopoverClose: () => void;
  sectionBreak?: boolean;
  sectionTitle?: string;
  activeItem?: IMenuItem;
}) => {
  const Item = () => (
    <Stack>
      {sectionTitle && (
        <Stack>
          <Typography px={2} py={1} variant="labelSemiBold">
            {sectionTitle}
          </Typography>
          <Divider />
        </Stack>
      )}
      <MenuItem
        divider={divider}
        onClick={(e) => {
          e.stopPropagation();
          closeOnItemClick && handlePopoverClose();
          item?.action?.();
        }}
        sx={{ py: '15px' }}
        disabled={item?.disabled}
        data-cy={item?.dataTestName}
      >
        {item.icon && <ListItemIcon sx={{ width: '5px' }}>{item.icon}</ListItemIcon>}
        {activeItem && activeItem?.value === item.value && <CheckSmallIcon />}
        <ListItemText
          primaryTypographyProps={item.textTypographyProps || { variant: 'body3SemiBold' }}
          primary={item.text}
          sx={activeItem && { ml: activeItem?.value === item.value ? 1 : 4 }}
        />
      </MenuItem>
      {sectionBreak && <Box sx={{ height: '8px', backgroundColor: colors.neutral.lighter }} />}
    </Stack>
  );

  if (item.disabled && item.disabledTooltipText)
    return (
      <Tooltip title={item.disabledTooltipText} key={index} placement="left-start">
        <div>
          <Item />
        </div>
      </Tooltip>
    );

  return item.link ? (
    <a
      href={item.link}
      target="_blank"
      rel="noopener noreferrer"
      key={index}
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      <Item key={index} />
    </a>
  ) : (
    <Item key={index} />
  );
};

export const MenuComponent = ({
  menuItems,
  anchorEl,
  handlePopoverClose,
  anchorOriginHorizontal,
  transformOriginHorizontal,
  closeOnItemClick = true,
  activeItem,
}: {
  menuItems: IMenuItem[];
  anchorEl?: Element | (() => Element) | PopoverVirtualElement | (() => PopoverVirtualElement);
  handlePopoverClose: () => void;
  anchorOriginHorizontal?: OriginHorizontalType;
  transformOriginHorizontal?: OriginHorizontalType;
  closeOnItemClick?: boolean;
  activeItem: IMenuItem;
}) => {
  if (!menuItems?.length) return null;

  return (
    <Menu
      id="customized-menu"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: anchorOriginHorizontal,
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: transformOriginHorizontal,
      }}
      sx={{
        '& .MuiList-root': { p: 0 },
        '& .MuiPaper-root': {
          borderRadius: '4px',
          boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.15)',
        },
      }}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handlePopoverClose}
    >
      {menuItems.map((item, index) => (
        <MenuItemComponent
          key={index}
          item={item}
          index={index}
          divider={index !== menuItems.length - 1}
          handlePopoverClose={handlePopoverClose}
          closeOnItemClick={closeOnItemClick}
          sectionBreak={item.sectionBreak}
          sectionTitle={item.sectionTitle}
          activeItem={activeItem}
        />
      ))}
    </Menu>
  );
};
