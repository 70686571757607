import * as React from 'react';
import {
  UseTreeItem2Parameters,
  useTreeItem2,
  TreeItem2Provider,
  TreeItem2Root,
  TreeItem2Content,
  TreeItem2IconContainer,
  TreeItem2Icon,
  TreeItem2Checkbox,
  TreeItem2DragAndDropOverlay,
  TreeItem2GroupTransition,
  TreeItem2Props,
} from '@mui/x-tree-view-pro';
import { Typography } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { RoleIconWithLink } from '@components';
import { TextField } from '@mui/material';
import { useStringFieldModel } from '@models';
import { IRoleNew } from '@interfaces';

interface CustomTreeItemProps extends Omit<UseTreeItem2Parameters, 'rootRef'>, TreeItem2Props {
  itemId: string;
  item: IRoleNew;
  updateRole: (data: { roleId: string; company_role: string }) => void;
}

export const CustomTreeItem = React.forwardRef(function CustomTreeItem(
  props: CustomTreeItemProps,
  ref: React.Ref<HTMLLIElement>,
) {
  const { id, itemId, label, disabled, children, ...other } = props;

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getCheckboxProps,
    getGroupTransitionProps,
    getDragAndDropOverlayProps,
    status,
    publicAPI,
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });
  const item = publicAPI.getItem(itemId);
  const { draggable, onDragStart, onDragOver, onDragEnd, ...otherRootProps } = getRootProps(other);

  const handleDragStart = (event: React.DragEvent) => {
    if (!onDragStart) {
      return;
    }
    onDragStart(event);
    event.dataTransfer.setDragImage((event.target as HTMLElement).parentElement!, 0, 0);
  };

  const showDrawIcon = !item.children;
  const { value, setValue } = useStringFieldModel({
    initValue: label.toString(),
    withProgressCheck: true,
  });
  const [isEditing, setIsEditing] = React.useState(false);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
    props.updateRole({ roleId: item.id, company_role: value });
  };

  return (
    <TreeItem2Provider itemId={itemId}>
      <TreeItem2Root {...otherRootProps}>
        <TreeItem2Content {...getContentProps()} onDoubleClick={handleDoubleClick}>
          <TreeItem2IconContainer {...getIconContainerProps()}>
            <TreeItem2Icon status={status} />
          </TreeItem2IconContainer>
          {showDrawIcon && (
            <TreeItem2IconContainer
              draggable={draggable}
              onDragStart={handleDragStart}
              onDragOver={onDragOver}
              onDragEnd={onDragEnd}
            >
              <DragIndicatorIcon />
            </TreeItem2IconContainer>
          )}
          <TreeItem2Checkbox {...getCheckboxProps()} />
          {isEditing ? (
            <TextField
              value={value}
              onChange={(e) => setValue(e.target.value)}
              onBlur={handleBlur}
              autoFocus
              variant="outlined"
              size="small"
            />
          ) : (
            <Typography variant="body2">{item.label}</Typography>
          )}
          {showDrawIcon && <Typography variant="label">{item.enrollment}</Typography>}
          {showDrawIcon && <RoleIconWithLink dataTestName="_" roleId={item.id} />}
          <TreeItem2DragAndDropOverlay {...getDragAndDropOverlayProps()} />
        </TreeItem2Content>
        {children && <TreeItem2GroupTransition {...getGroupTransitionProps()} />}
      </TreeItem2Root>
    </TreeItem2Provider>
  );
});
