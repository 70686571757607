import React, { useEffect, useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import { useFormik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';

import {
  emailAndPasswordValidationSchema,
  getErrorText,
  getProtectedPassword,
  initialValues,
} from '@utils';
import { ForgotPasswordLink, MFAModal, ThirdPartyLoginButtons } from '../components';
import { InputField, LoginWireframe, ToolTipLine } from '@components';
import * as Controller from './controller';
import { ErrorDual, ToolTipLineVariantEnum } from '@interfaces';
import { useNavigateToOriginalUrl } from '@hooks';

const SimpleLogin = () => {
  const { handleLogin, requestMfa, isRequestingMfa, loginError, setLoginError } =
    Controller.useSimpleLogin();
  const { fromResetPassword: fromResetPasswordFromState, from } = useNavigateToOriginalUrl();

  // fromResetPassword flag is used to show different titles for login page
  // if user was redirected here from reset password screen
  const [fromResetPassword, setFromResetPassword] = useState(fromResetPasswordFromState);

  const [openMfaModal, setOpenMfaModal] = useState(false);
  const [isSendingMfaCode, setIsSendingMfaCode] = useState(false);

  const logout = () => {
    setOpenMfaModal(false);
    formik.setFieldValue('password', '');
    formik.setFieldValue('email', '');
    formik.setFieldError('email', '');
  };

  const handleResendMfaRequest = async () => {
    if (!isSendingMfaCode) {
      await requestMfa({
        email: formik.values.email.trim(),
        password: formik.values.password.trim(),
      });
      setIsSendingMfaCode(true);
      setTimeout(() => {
        setIsSendingMfaCode(false);
      }, 60000);
    }
  };

  const sendMfaCode = async (code: string, rememberMe: boolean) => {
    try {
      await handleLogin({
        email: formik.values.email.trim(),
        password: formik.values.password.trim(),
        mfa_code: code,
        remember_this_device_for_mfa: rememberMe,
      });
    } catch (err) {
      formik.setFieldValue('password', '');
      setOpenMfaModal(false);
      setIsSendingMfaCode(false);
    }
  };

  const isMFAEnabled = async (values: { email: string; password: string }) => {
    try {
      const response = await requestMfa({
        email: values.email.trim(),
        password: values.password.trim(),
      });
      return response;
    } catch (err) {
      setOpenMfaModal(false);
      setIsSendingMfaCode(false);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: emailAndPasswordValidationSchema,
    onSubmit: async (values) => {
      try {
        setIsSendingMfaCode(true);
        setTimeout(() => {
          setIsSendingMfaCode(false);
        }, 60000);
        const response = await isMFAEnabled(values);
        if (!response) return;

        if (!response.is_mfa_enabled) {
          await handleLogin({ email: values.email.trim(), password: values.password.trim() });
        } else {
          setOpenMfaModal(true);
        }
      } catch (err) {
        // switch to simple login screen if fromResetPassword and error
        if (fromResetPassword) setFromResetPassword(false);
        setLoginError(getErrorText(err as ErrorDual));
      }
    },
  });

  useEffect(() => {
    if (Object.keys(formik.errors).length > 0) {
      document.getElementById('root').scrollIntoView(true);
    }
  }, [formik]);

  const getTitle = useMemo(() => {
    if (fromResetPassword)
      return (
        <>
          <Typography variant="h1">Success!</Typography>
          <Typography variant="body2" sx={{ mt: '16px' }}>
            Your password has been changed.
          </Typography>
          <Typography variant="body2">Login with your email to access your account.</Typography>
        </>
      );
    if (!from) return <Typography variant="h1">Hi, welcome back!</Typography>;
    return null;
  }, [fromResetPassword]);

  const getFormContent = () => (
    <>
      {loginError && (
        <ToolTipLine
          variant={ToolTipLineVariantEnum.ERROR}
          withBackground
          sx={{ mb: 2 }}
          text={loginError}
          typographyVariant="body3SemiBold"
        />
      )}
      <InputField
        data-cy="email"
        name="email"
        label="Email"
        type="email"
        variant="outlined"
        size="small"
        disabled={formik.isSubmitting}
      />
      <InputField
        data-cy="password"
        type="password"
        name="password"
        label="Password"
        variant="outlined"
        mt="24px"
        size="small"
        disabled={formik.isSubmitting}
        {...getProtectedPassword()}
      />
      <ForgotPasswordLink />
    </>
  );

  const getFormButtons = useMemo(() => {
    return (
      <>
        <LoadingButton
          data-cy="submit"
          fullWidth
          type="submit"
          loading={formik.isSubmitting || isRequestingMfa}
        >
          Log in
        </LoadingButton>

        <ThirdPartyLoginButtons />
      </>
    );
  }, [formik.isSubmitting]);

  return (
    <>
      <LoginWireframe
        formik={formik}
        getTitle={getTitle}
        getFormContent={getFormContent()}
        getFormButtons={getFormButtons}
      />
      <MFAModal
        open={openMfaModal}
        logout={logout}
        handleResendMfaRequest={handleResendMfaRequest}
        sendMfaCode={sendMfaCode}
        isSendingMfaCode={isSendingMfaCode}
      />
    </>
  );
};

export default React.memo(SimpleLogin);
