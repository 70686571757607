import { useMutation, useQuery, useQueryClient } from 'react-query';
import { INotifications, PermissionNamesEnums, QueryNamesEnums } from '@interfaces';
import { getUserNotifications, patchUserNotifications } from '@globalService';
import { useSafeSnackbar } from '@hooks';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AuthContext, PermissionsContext, useLaunchDarklyFlags } from '@context';
import { checkIsInvestor, checkIsLender, checkIsOwner, getTeamRole, isRestricted } from '@utils';

interface ICategory {
  name: string;
  value: string;
  isChecked: boolean;
  subText: string[];
}

interface ControllerInterface {
  categories: ICategory[];
  updateNotifications: (category: string, value: boolean) => void;
  onSetupClick: () => void;
}

export const useEmailNotifications = (
  saveDataCategoryClick: boolean,
  onClose?: () => void,
): ControllerInterface => {
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const { permissions } = useContext(PermissionsContext);
  const flags = useLaunchDarklyFlags();
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const isLender = useMemo(() => checkIsLender(teamRole), [teamRole]);
  const isOwner = useMemo(() => checkIsOwner(teamRole), [teamRole]);
  const isInvestor = useMemo(() => checkIsInvestor(teamRole), [teamRole]);

  const [categories, setCategories] = useState<ICategory[] | null>(null);

  const userNotificationsQuery = useQuery<INotifications, Error>(
    [QueryNamesEnums.GET_USER_NOTIFICATIONS],
    getUserNotifications.bind(this),
  );

  const borrowerCategories = useMemo(
    () => [
      {
        name: 'Request updates',
        value: 'draw_request_updates_enabled',
        subText: ['Notifications for all draw events.'],
        isChecked: userNotificationsQuery.data?.draw_request_updates_enabled,
      },
      {
        name: 'New comments',
        value: 'comment_and_document_updates_enabled',
        subText: ['Notifications about new comments.'],
        isChecked: userNotificationsQuery.data?.comment_and_document_updates_enabled,
      },
    ],
    [userNotificationsQuery.data],
  );

  const investorCategories = useMemo(
    () => [
      {
        name: 'Requests waiting approval',
        value: 'workflow_updates_enabled',
        subText: [
          'Notifications for draw requests awaiting your approval (watching project is not required).',
        ],
        isChecked: userNotificationsQuery.data?.workflow_updates_enabled,
      },
    ],
    [userNotificationsQuery.data],
  );

  const lenderCategories = useMemo(
    () => [
      {
        name: 'Project milestone updates',
        value: 'critical_project_updates_enabled',
        subText: [
          'AI-powered notifications for strategic milestones for projects you are watching, intended for sales and executive audiences.',
        ],
        isChecked: userNotificationsQuery.data?.critical_project_updates_enabled,
      },
      {
        name: 'Project updates',
        value: 'project_updates_enabled',
        subText: [
          'Notifications for project status changes, custom reminders and underwriting events for the watched projects.',
        ],
        isChecked: userNotificationsQuery.data?.project_updates_enabled,
      },
      {
        name: 'Requests waiting approval',
        value: 'workflow_updates_enabled',
        subText: [
          'Notifications for draw requests awaiting your approval (watching project is not required).',
        ],
        isChecked: userNotificationsQuery.data?.workflow_updates_enabled,
      },
      {
        name: 'Request updates',
        value: 'draw_request_updates_enabled',
        subText: ['Notifications for all draw events for projects you are watching.'],
        isChecked: userNotificationsQuery.data?.draw_request_updates_enabled,
      },
      ...(isRestricted(PermissionNamesEnums.INSPECTIONS_VIEW, permissions)
        ? []
        : [
            {
              name: 'Services updates',
              value: 'service_updates_enabled',
              subText: [
                'Notifications for completed automated services in projects you are watching.',
              ],
              isChecked: userNotificationsQuery.data?.service_updates_enabled,
            },
          ]),
      {
        name: 'New comments and documents',
        value: 'comment_and_document_updates_enabled',
        subText: ['Notifications about new documents and comments added to watched projects.'],
        isChecked: userNotificationsQuery.data?.comment_and_document_updates_enabled,
      },
    ],
    [userNotificationsQuery.data, permissions],
  );

  useEffect(() => {
    if (permissions && userNotificationsQuery.data && !categories) {
      if (flags?.[`ENG_9462_notification_settings_update`]) {
        setCategories(
          isLender ? lenderCategories : isOwner ? borrowerCategories : investorCategories,
        );
      } else {
        setCategories(lenderCategories);
      }
    }
  }, [userNotificationsQuery.data, categories, permissions, isLender, isOwner, isInvestor, flags]);

  const updateNotificationsMutation = useMutation<
    INotifications,
    Error,
    { params: Partial<INotifications> }
  >(patchUserNotifications, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryNamesEnums.GET_USER_NOTIFICATIONS);
      if (saveDataCategoryClick) enqueueSnackbar('Notification updated', { variant: 'success' });
      if (onClose) onClose();
    },
    onError: (error) => {
      userNotificationsQuery.refetch();
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const updateNotifications = useCallback((category: string, value: boolean) => {
    setCategories((prev) =>
      prev.map((prevCategory) => {
        if (prevCategory.value === category) {
          return { ...prevCategory, isChecked: value };
        }
        return prevCategory;
      }),
    );
    if (saveDataCategoryClick) {
      updateNotificationsMutation.mutateAsync({
        params: { [category]: value },
      });
    }
  }, []);

  const categoriesToObject = (categories) => {
    return categories.reduce((acc, category) => {
      acc[category.value] = category.isChecked;
      return acc;
    }, {});
  };

  const onSetupClick = useCallback(() => {
    const params = categoriesToObject(categories);
    updateNotificationsMutation.mutateAsync({
      params,
    });
  }, [categories]);

  return { categories, updateNotifications, onSetupClick };
};
