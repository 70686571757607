import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { NameColumnProps } from '../interface';
import { NameCell } from './common';

export const nameColumn = ({ source, open }: NameColumnProps): GridColDef => ({
  field: 'name',
  headerName: 'Name',
  flex: 1,
  minWidth: 180,
  groupable: false,
  aggregable: false,
  renderCell: (params) => <NameCell params={params} source={source} open={open} />,
});
