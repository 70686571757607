import React, { FC } from 'react';
import { Button, Stack } from '@mui/material';
import { CustomTextField, Popup } from '@components';
import { StringFieldModel } from '@models';

export const TransactionIdEditPopup: FC<{
  transactionId: StringFieldModel;
  onClose: () => void;
  onSave: ({ external_transaction_id }: { external_transaction_id: string }) => void;
}> = ({ transactionId, onClose, onSave }) => (
  <Popup open maxWidth="md" title="Edit transaction ID">
    <Stack sx={{ width: '100%' }}>
      <CustomTextField
        field={transactionId}
        label="Transaction ID"
        inputProps={{
          'data-cy': 'transaction_id_input',
        }}
      />
      <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: 7 }}>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button
          sx={{ ml: 1 }}
          onClick={() => onSave({ external_transaction_id: transactionId.value })}
          data-cy="transaction_id_save_button"
        >
          Save
        </Button>
      </Stack>
    </Stack>
  </Popup>
);
