import React from 'react';
import { useSnackbar } from 'notistack';
import { IconButtonWithTooltip } from '@components';
import { CloseIcon } from '@svgAsComponents';
import { colors } from '@theme';

const SnackbarCloseButton = ({ snackbarKey }) => {
  const { closeSnackbar } = useSnackbar();

  const onClickDismiss = () => {
    closeSnackbar(snackbarKey);
  };

  return (
    <IconButtonWithTooltip aria-label="close" onClick={onClickDismiss} tooltipText="Close">
      <CloseIcon size={13} color={colors.icons.gray} />
    </IconButtonWithTooltip>
  );
};

export default SnackbarCloseButton;
