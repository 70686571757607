import { useState } from 'react';
import { useMutation } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';

import { resetPassword } from '@globalService';
import { ErrorDual } from '@interfaces';
export interface ControllerInterface {
  showProgress: boolean;
  handleResetPassword: (password: string) => void;
  isSetUpPassword: boolean;
}

export interface ResetPasswordInterface {
  password: string;
  token: string;
}

export const useResetPassword = (): ControllerInterface => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [showProgress, setShowProgress] = useState(false);
  const originalUrl = JSON.parse(localStorage.getItem('originalUrl') || 'null');

  const resetPasswordPost = useMutation<Response, ErrorDual, ResetPasswordInterface>(
    resetPassword,
    {
      onError: () => {
        setShowProgress(false);
      },
    },
  );

  const handleResetPassword = async (password) => {
    setShowProgress(true);
    const result = await resetPasswordPost.mutateAsync({ password, token });
    if (result) {
      navigate('/login', { state: { fromResetPassword: true } });
    }
    setShowProgress(false);
  };

  return {
    showProgress: showProgress,
    handleResetPassword,
    isSetUpPassword: !!originalUrl,
  };
};
