import React, { useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import { MagicWandIcon } from '@svgAsComponents';
import {
  BetaChip,
  DocumentSummaryList,
  Gallery,
  PDFViewerNew,
  RightDrawer,
  SmartSummaryButton,
} from '@components';
import { IDocument } from '@interfaces';
import { useImagePicker, useRightMenu } from '@hooks';

interface ComponentProps {
  source: string;
  documents: IDocument[];
}

const SmartSummaryPanel = ({ source, documents }: ComponentProps) => {
  const { projectId } = useParams();
  const [docSummaryParams, setDocSummaryParams] = useState<{
    projectId: string;
  }>({
    projectId,
  });

  const { handleRightDrawerOpenerClick: handleSmartSummaryOpenerClick, ...docSummaryMenu } =
    useRightMenu({});

  const { pdf, gallery, open, close, startIndex } = useImagePicker();
  const openDocument = (file) => {
    if (!file) return close();
    open([file]);
  };

  const openAllDocsSummary = () => {
    handleSmartSummaryOpenerClick({
      title: (
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
          <MagicWandIcon />
          <Typography variant="h2" sx={{ whiteSpace: 'nowrap' }}>
            SmartSummary
          </Typography>
          <Stack ml={1}>
            <BetaChip dataTestName="right_drawer__documents_summary__beta__chip" />
          </Stack>
        </Stack>
      ),
    });
    setDocSummaryParams({ projectId });
  };

  return (
    <>
      <SmartSummaryButton onClick={openAllDocsSummary} source={source} />

      <RightDrawer {...docSummaryMenu} drawerWidth="720px">
        <DocumentSummaryList
          {...docSummaryParams}
          rows={documents}
          source={`${source}__list__summary`}
          openPdfViewer={openDocument}
        />
      </RightDrawer>
      {gallery && <Gallery startIndex={startIndex} close={close} files={gallery} />}
      {pdf && <PDFViewerNew pdfFile={pdf[0]} close={close} />}
    </>
  );
};

export default SmartSummaryPanel;
