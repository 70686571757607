import React, { ChangeEvent, useContext } from 'react';
import { Grid2, Stack, TextField } from '@mui/material';
import { ArrowRightIcon } from '@svgAsComponents';
import {
  CustomAutocomplete,
  CustomTextField,
  ProjectImage,
  PropertyDetailsTable,
  StyledBox,
  TabWrapperWithLeaveWarning,
} from '@components';
import { SettingsContext } from '@context';
import { states } from '@constants';
import * as Controller from './controller';

const GeneralSettings = ({
  project,
  isAllProjectDetailsDisabled,
  isLimitedProjectDetailsDisabled,
}) => {
  const controller = Controller.useGeneralSettings({ project });
  const { isPHBProject } = useContext(SettingsContext);
  const {
    projectDetailsFields,
    handleSubmitClick,
    isSubmitting,
    isUpdated,
    exitPath,
    exitStrategiesList,
    projectTypesList,
    propertyTypesList,
    propertyDetails,
    isAllProjectDataValid,
    isCreateByModels,
  } = controller;
  const {
    projectName,
    scopeOfWork,
    exitStrategy,
    projectType,
    existingPropertyType,
    proposedPropertyType,
    address_1,
    city,
    state,
    zipCode,
  } = projectDetailsFields;
  return (
    <TabWrapperWithLeaveWarning
      onSave={handleSubmitClick}
      isUpdated={isUpdated}
      isDisabled={!isAllProjectDataValid}
      tabValue="general"
      path={exitPath}
      isSubmitting={isSubmitting}
      currentPagePathname={'/projects/*/settings/general'}
    >
      <StyledBox>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            <ProjectImage
              project={project}
              disabled={isAllProjectDetailsDisabled && isLimitedProjectDetailsDisabled}
              source="project__settings__general__project_image"
            />
            <Stack sx={{ flex: 1 }} spacing={2}>
              <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 12, lg: 8 }} sx={{ ml: -2 }}>
                  <CustomTextField
                    field={projectName}
                    label="Project name"
                    required
                    inputProps={{ 'data-cy': 'project__overview__name__input' }}
                    disabled={isAllProjectDetailsDisabled}
                  />
                </Grid2>

                <Grid2 size={{ xs: 12, lg: 4 }}>
                  <CustomAutocomplete
                    field={exitStrategy}
                    label="Exit strategy"
                    options={exitStrategiesList}
                    inputProps={{
                      'data-cy': 'project__settings__general__exit_strategy__input',
                    }}
                    disabled={isAllProjectDetailsDisabled}
                  />
                </Grid2>
              </Grid2>
              <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 12, lg: 4 }} sx={{ ml: -2 }}>
                  <CustomAutocomplete
                    field={projectType}
                    label="Project type"
                    options={projectTypesList}
                    required
                    inputProps={{
                      'data-cy': 'project__settings__general__project_type__input',
                    }}
                    disabled={isAllProjectDetailsDisabled}
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, lg: 4 }}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <CustomAutocomplete
                      field={existingPropertyType}
                      label="Property type (Existing)"
                      options={propertyTypesList}
                      required
                      inputProps={{
                        'data-cy': 'project__settings__general__property_type_exisiting__input',
                      }}
                      disabled={isAllProjectDetailsDisabled}
                    />
                    <ArrowRightIcon size={20} />
                  </Stack>
                </Grid2>
                <Grid2 size={{ xs: 12, lg: 4 }}>
                  <CustomAutocomplete
                    field={proposedPropertyType}
                    label="Property type (Proposed)"
                    options={propertyTypesList}
                    inputProps={{
                      'data-cy': 'project__settings__general__property_type_proposed__input',
                    }}
                    disabled={isAllProjectDetailsDisabled}
                  />
                </Grid2>
              </Grid2>

              <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 12, lg: 4 }} sx={{ ml: -2 }}>
                  <CustomTextField
                    field={address_1}
                    label="Street address"
                    required
                    inputProps={{
                      'data-cy': `project__settings__general__address_1_input`,
                    }}
                    disabled={isAllProjectDetailsDisabled}
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, lg: 4 }}>
                  <CustomTextField
                    field={city}
                    label="City"
                    required
                    inputProps={{
                      'data-cy': `project__settings__general__city_input`,
                    }}
                    disabled={isAllProjectDetailsDisabled}
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, lg: 2 }}>
                  <CustomAutocomplete
                    field={state}
                    label="State"
                    options={states}
                    required
                    inputProps={{
                      'data-cy': `project__settings__general__state_input`,
                    }}
                    disabled={isAllProjectDetailsDisabled}
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, lg: 2 }}>
                  <CustomTextField
                    field={zipCode}
                    label="ZIP code"
                    validationText="Please enter a valid 5-digit zip code"
                    required
                    inputProps={{
                      'data-cy': `project__settings__general__zip_code_input`,
                    }}
                    disabled={isAllProjectDetailsDisabled}
                  />
                </Grid2>
              </Grid2>
            </Stack>
          </Stack>

          <TextField
            data-cy="project__settings__general__scope_of_work__input"
            fullWidth
            variant="outlined"
            label="Scope of work"
            value={scopeOfWork.value || ''}
            sx={{ mb: '24px' }}
            multiline
            rows={5}
            onChange={(event: ChangeEvent<HTMLInputElement>) => scopeOfWork.handleChange(event)}
            disabled={isAllProjectDetailsDisabled && isLimitedProjectDetailsDisabled}
          />
        </Stack>
      </StyledBox>
      <PropertyDetailsTable
        isEditable={!(isAllProjectDetailsDisabled && isLimitedProjectDetailsDisabled)}
        propertyDetails={propertyDetails}
        isCreateByModels={isCreateByModels}
        isPHBProject={isPHBProject}
        source="project__settings__general"
      />
    </TabWrapperWithLeaveWarning>
  );
};

export default GeneralSettings;
