import React, { FC, useCallback, useContext, useMemo } from 'react';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { ColumnLabel, CommonRowType, HeaderText, MilestoneListColumnType } from './common';
import { TableContext } from '../controller';
import { PermissionsContext } from '@context';
import { getEllipsisStyle, isRestricted, useBlockerCell } from '@utils';
import { useDebounceInput, useStringFieldModel } from '@models';
import { colors } from '@theme';
import {
  ColumnAlign,
  ColumnV2Width,
  ColumnWidth,
  ICostType,
  PermissionNamesEnums,
} from '@interfaces';
import { LabelAndValue, TextInputWithTooltip, IconButtonWithTooltip } from '@components';
import { DeleteIcon, InfoIcon } from '@svgAsComponents';

const LineItemName: FC<{ row: CommonRowType }> = ({ row }) => {
  const isDeleting = useBlockerCell({ milestoneId: row.id });
  const deleteCallback = useCallback(() => deleteMilestone(row.id), [row.id]);
  const { permissions } = useContext(PermissionsContext);
  const { apiUpdate, resetMutation, deleteMilestone, getLineItemError } = useContext(TableContext);
  const error = getLineItemError(row.id, 'name');

  const isBudget = !row.project_milestone;

  const name = useStringFieldModel({
    initError: error,
    initValue: (row.name || '')?.toString(),
    withProgressCheck: true,
    blockNavigationKey: 'name',
  });

  const isEditable = useMemo(
    () =>
      isBudget &&
      row.activeToEdit &&
      row.localNew &&
      !isRestricted(PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE, permissions),
    [permissions, row],
  );

  const effect = useCallback(() => {
    if (row.name !== name.value) {
      apiUpdate({
        milestoneId: row.project_milestone?.id || row.id,
        name: 'name',
        value: name.value,
        isNonNumber: true,
      });
    }
  }, [row, name]);

  const { onBlur } = useDebounceInput({
    effect,
    handleChange: name.handleChange,
    resetter: () => resetMutation({ milestone: row.id, json: { name: '' } }),
    afterEffect: () => name.changeNavigationBlockContext(false),
  });

  return isEditable ? (
    <Stack flexDirection="row" maxWidth={220} justifyContent="space-between">
      <TextInputWithTooltip
        type="string"
        dataTestName={`name_index_${row.index}`}
        value={name.value}
        onChange={name.handleChange}
        error={!name.value}
        tooltipText={row.disabled?.reason}
        disabled={row.disabled?.value}
        errorTip={name.value ? '' : 'Please, fill the name or delete the row.'}
        onBlur={onBlur}
      />
      {(isBudget || row.can_be_deleted) && (
        <IconButtonWithTooltip disabled={isDeleting} onClick={deleteCallback} tooltipText="Delete">
          <DeleteIcon size={20} color={colors.icons.gray} />
        </IconButtonWithTooltip>
      )}
    </Stack>
  ) : (
    <Box textAlign={ColumnAlign.TEXT}>
      <Stack flexDirection="row">
        <Tooltip title={row.name?.length > 25 ? row.name : ''}>
          <Typography component="div" sx={getEllipsisStyle('100%')} variant="body3">
            {row.localNew && (
              <Typography color={colors.status.information.medium} variant="body3SemiBold">
                NEW{' '}
              </Typography>
            )}
            {row.name}
          </Typography>
        </Tooltip>
        {row.localNew && (
          <Tooltip
            title={
              <Stack spacing={1}>
                <Typography variant="body3SemiBold">{row.name}</Typography>
                <LabelAndValue
                  label={'Request #'}
                  text={row.project_milestone?.change_request_number?.toString()}
                />
                <LabelAndValue
                  label="Cost type"
                  text={(row.cost_type as ICostType)?.display || '-'}
                />
              </Stack>
            }
          >
            <Stack ml={1} mt="2px">
              <InfoIcon size={14} />
            </Stack>
          </Tooltip>
        )}
      </Stack>

      {row.company?.name && <Typography variant="body3">{row.company?.name}</Typography>}
      {row.localNew && (
        <Typography color={colors.text.medium} variant="body3">
          (Request #{row.project_milestone?.change_request_number})
        </Typography>
      )}
    </Box>
  );
};

const name: MilestoneListColumnType = {
  name: 'name',
  columnText: 'Line item',
  noBorder: true,
  width: ColumnWidth.NAME,
  isEditable: (permissions) =>
    !isRestricted(PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE, permissions),
  renderCell: ({ row }) => <LineItemName row={row} />,
  Footer: (row) => (
    <Typography component="div" align={ColumnAlign.TEXT} variant="body3SemiBold">
      {row?.displayAll ? 'TOTAL (ALL)' : row.isFiltered ? 'DISPLAYED TOTAL' : 'TOTAL'}
    </Typography>
  ),
  justifyContent: 'flex-start',
  minWidth: () => ColumnV2Width.NAME_MIN,
  maxWidth: () => ColumnV2Width.NAME_MAX,
  Header: (
    <HeaderText alignItems="flex-start" tooltipText="Description of work">
      <ColumnLabel>Line item</ColumnLabel>
    </HeaderText>
  ),
};

export default name;
