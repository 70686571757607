import React from 'react';
import { Stack } from '@mui/material';

import {
  AssignCoordinatorPopup,
  ReactTable,
  RightDrawer,
  SwitchApproverPopup,
  TableItemRightDrawer,
  AssignCoordinatorPopupV2,
} from '@components';
import { TableKeyEnum } from '@interfaces';
import { useLaunchDarklyFlags } from '@context';

const DrawChangeRequestTable = ({
  initialData,
  initialSortBy,
  handleSortClick,
  isUpdating,
  isLoading,
  hiddenColumns,
  controller,
}) => {
  const {
    approverPopupOpen,
    columns,
    coordinatorPopupOpen,
    currentRequest,
    handleRowClick,
    rightDrawerParams,
    rightMenu,
    setCoordinatorPopupOpen,
    setApproverPopupOpen,
  } = controller;
  const data = React.useMemo(
    () => (isLoading ? Array(10).fill({}) : initialData),
    [initialData, isLoading],
  );
  const flags = useLaunchDarklyFlags();
  return (
    <Stack style={{ opacity: isUpdating ? 0.3 : 1 }}>
      <ReactTable
        data={data}
        columns={columns}
        onRowClick={handleRowClick}
        footer={false}
        maxHeight={'calc(100vh - 276px)'}
        minHeight={'calc(100vh - 276px)'}
        hiddenColumns={hiddenColumns}
        dataTestName={status}
        initialSortBy={initialSortBy}
        handleSortClick={handleSortClick}
        manualSortBy
        source="requests_queue__table"
        tableKey={TableKeyEnum.REQUESTS}
        showContextMenu
      />
      <RightDrawer {...rightMenu}>
        <TableItemRightDrawer
          projectId={rightDrawerParams.projectId}
          requestId={rightDrawerParams.requestId}
          activeTab={rightDrawerParams.activeTab}
          source="requests_queue__right_drawer"
        />
      </RightDrawer>
      {coordinatorPopupOpen &&
        (flags?.['ENG_9424_unassing_button_for_coordinator'] ? (
          <AssignCoordinatorPopupV2
            open={coordinatorPopupOpen}
            requestId={currentRequest?.id}
            prId={currentRequest?.project?.id}
            initCoordinator={currentRequest?.coordinator}
            onClose={() => setCoordinatorPopupOpen(false)}
          />
        ) : (
          <AssignCoordinatorPopup
            open={coordinatorPopupOpen}
            requestId={currentRequest?.id}
            prId={currentRequest?.project?.id}
            initCoordinator={currentRequest?.coordinator}
            onClose={() => setCoordinatorPopupOpen(false)}
          />
        ))}
      {approverPopupOpen && (
        <SwitchApproverPopup
          open={approverPopupOpen}
          request={currentRequest}
          onClose={() => setApproverPopupOpen(false)}
          projectId={currentRequest?.project?.id}
          source="requests_queue"
        />
      )}
    </Stack>
  );
};

export default DrawChangeRequestTable;
