import { QueryNamesEnums } from '@interfaces';

import {
  getDrawRequestMilestone,
  getDrawRequestMilestoneGroup,
  getDrawRequestMilestones,
  getProjectMilestone,
  getProjectMilestonesList,
  getProjectsCompaniesV2,
  getProjectTeamsWithQuery,
  getProjectsUsersV2,
  getProjectWithQuery,
  getUISettingsLoanTypes,
  getUISettingsServicesTypes,
  getProjectsTeamsV2,
  getCompaniesV2,
  getProjectsInspectionAgenciesV2,
  getBuildingsTypesV2,
  getProjectsTypesV2,
  getProjectsRolesV2,
  getCompaniesRoleByIdV2,
  getCompaniesRolesV2,
} from '@globalService';

export const dataProviders = {
  [QueryNamesEnums.GET_PROJECT]: getProjectWithQuery,
  [QueryNamesEnums.GET_PROJECT_MILESTONE]: getProjectMilestone,
  [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE]: getDrawRequestMilestone,
  [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE_GROUP]: getDrawRequestMilestoneGroup,
  [QueryNamesEnums.GET_COMPANY_TEAM_BY_ID]: getCompaniesRoleByIdV2,
} as const;

export const dataProvidersLists = {
  [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES]: getDrawRequestMilestones,
  [QueryNamesEnums.GET_PROJECT_MILESTONES]: getProjectMilestonesList,
  [QueryNamesEnums.GET_UI_SETTINGS_SERVICES_TYPE]: getUISettingsServicesTypes,
  [QueryNamesEnums.GET_UI_SETTINGS_LOANS_TYPE]: getUISettingsLoanTypes,

  [QueryNamesEnums.GET_COMPANIES_BY_ROLE]: getProjectsCompaniesV2,
  [QueryNamesEnums.GET_PROJECTS_TEAMS]: getProjectsTeamsV2,
  [QueryNamesEnums.GET_COORDINATORS]: getProjectsUsersV2,
  [QueryNamesEnums.GET_COMPANIES]: getCompaniesV2,
  [QueryNamesEnums.GET_INSPECTION_AGENCIES]: getProjectsInspectionAgenciesV2,
  [QueryNamesEnums.GET_PROJECT_PROPERTY_TYPES]: getBuildingsTypesV2,
  [QueryNamesEnums.GET_PROJECT_TYPES]: getProjectsTypesV2,

  [QueryNamesEnums.GET_PROJECT_TEAMS]: getProjectTeamsWithQuery,
  [QueryNamesEnums.GET_PROJECT_ROLES]: getProjectsRolesV2,
  [QueryNamesEnums.GET_COMPANY_ROLES]: getCompaniesRolesV2,
} as const;
