import { useLocation, useNavigate } from 'react-router-dom';
import { useMemo, useCallback, useState } from 'react';

interface CustomizedState {
  fromRedirect: boolean;
  isAutoLogout: boolean;
  from: string;
  ssoAccessToken: string;
  fromResetPassword: boolean;
}

export const useNavigateToOriginalUrl = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location?.state as CustomizedState;

  // location.state - 'fromRedirect' flag is used to identify redirect after okta sso login
  // to define if it's initial sso login page or after redirect callback

  const { fromRedirect, isAutoLogout, from, ssoAccessToken, fromResetPassword } = state || {};

  const deepLink = from || '/';

  const handleRedirect = () => {
    const urlToNavigate =
      JSON.parse(
        sessionStorage.getItem('originalUrl') || localStorage.getItem('originalUrl') || 'null',
      ) || deepLink;

    if (urlToNavigate) {
      sessionStorage.removeItem('originalUrl');
      localStorage.removeItem('originalUrl');
      navigate(urlToNavigate);
    }
  };

  return {
    handleRedirect,
    fromRedirect,
    isAutoLogout,
    from,
    ssoAccessToken,
    fromResetPassword,
  };
};
