import React, { FC, useState } from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import { colors } from '@theme';
import { CloseIcon, PermitIcon } from '@svgAsComponents';
import { BulkApplyPolicies, IconButtonWithTooltip } from '@components';
import { useSafeSnackbar } from '@hooks';

const ApplyProjectPoliciesBulkPanel: FC<{
  selectedProjectsIds: string[];
  selectedProjectCompanyIds: string[];
  onClose: () => void;
}> = ({ selectedProjectsIds, selectedProjectCompanyIds, onClose }) => {
  const [openApplyPolicies, setOpenApplyPolicies] = useState(false);
  const { enqueueSnackbar } = useSafeSnackbar();

  const handleCloseApplyPolicies = () => {
    setOpenApplyPolicies(false);
    onClose();
  };

  return (
    <Stack
      direction="row"
      sx={{
        position: 'fixed',
        backgroundColor: colors.main.primary.main,
        bottom: 24,
        left: '50%',
        transform: 'translate(-50%, 0)',
        px: 3,
        py: 1,
      }}
      alignItems="center"
    >
      <Stack direction="row" alignItems="center">
        <IconButtonWithTooltip onClick={onClose} sx={{ p: 0 }} tooltipText="Close">
          <CloseIcon size={16} color={colors.neutral.white} />
        </IconButtonWithTooltip>
        <Typography variant="body2SemiBold" sx={{ ml: 2, color: colors.neutral.white }}>
          {`${selectedProjectsIds.length} projects selected`}
        </Typography>
      </Stack>
      <Divider
        orientation="vertical"
        sx={{ mx: '32px', color: colors.neutral.white, height: '32px' }}
      />
      <Stack direction="row" alignItems="center" spacing="40px">
        <IconButtonWithTooltip
          onClick={() => {
            if (selectedProjectCompanyIds.length > 1) {
              enqueueSnackbar('Please select projects from only one company', {
                variant: 'error',
              });
              return;
            }
            setOpenApplyPolicies(true);
          }}
          sx={{ p: 0 }}
          tooltipText="Apply policies to selected projects"
        >
          <>
            <PermitIcon size={24} color={colors.neutral.white} />
            <Typography variant="body2SemiBold" sx={{ ml: 1, color: colors.neutral.white }}>
              Apply policies
            </Typography>
          </>
        </IconButtonWithTooltip>
      </Stack>
      <BulkApplyPolicies
        open={openApplyPolicies}
        onClose={handleCloseApplyPolicies}
        projectIds={selectedProjectsIds}
        companyId={selectedProjectCompanyIds[0]}
      />
    </Stack>
  );
};

export default ApplyProjectPoliciesBulkPanel;
