import { useQuery } from 'react-query';
import { useMemo } from 'react';
import { getHookState } from '@utils';
import { HookState, IProjectScores, QueryNamesEnums, TScoreItem } from '@interfaces';
import { getProjectScoresNew } from '@globalService';

export interface ControllerInterface {
  projectScore: TScoreItem;
  state: HookState;
  scores: TScoreItem[];
}

export const useProjectScore = (projectId: string): ControllerInterface => {
  const scoresQuery = useQuery<IProjectScores, Error>(
    [QueryNamesEnums.GET_PROJECT_SCORES_NEW, { projectId }],
    getProjectScoresNew.bind(this, projectId),
  );

  const projectScore = useMemo(() => scoresQuery.data?.project_score, [scoresQuery.data]);

  const scores = useMemo(() => scoresQuery.data?.scores, [scoresQuery.data]);

  return {
    projectScore,
    state: getHookState(scoresQuery),
    scores,
  };
};
