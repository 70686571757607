import { Divider, Box } from '@mui/material';
import React from 'react';
import { IPhoto } from '@interfaces';
import { Stack, Typography } from '@mui/material';
import { useDateFormatter } from '@hooks';
import { IconButtonWithTooltip, LabelAndValue, Tags, GoogleMap } from '@components';
import { CloseIcon, SuccessIcon } from '@svgAsComponents';
import { colors } from '@theme';
interface IMediaDetailsProps {
  media: IPhoto;
  size: string;
  dataTestName?: string;
  closeCallback?: () => void;
  mediaType?: 'Photo' | 'Video';
}

const DetailsSizes = {
  small: {
    title: 'labelSemiBold',
    label: 'label',
  },
  medium: {
    title: 'h2',
    label: 'body2',
  },
};

const MediaDetails = ({ media, size, closeCallback, mediaType = 'Photo' }: IMediaDetailsProps) => {
  const { dateFormatter } = useDateFormatter();
  if (!media) return null;

  return (
    <Stack spacing={1}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ pb: 1 }}>
        <Typography variant={DetailsSizes[size]?.title}>{mediaType} details</Typography>
        {closeCallback && (
          <IconButtonWithTooltip
            onClick={closeCallback}
            data-cy="photos_tab__card__close__button"
            tooltipText="Close"
          >
            <CloseIcon />
          </IconButtonWithTooltip>
        )}
      </Stack>

      <LabelAndValue
        size={DetailsSizes[size]?.label}
        label="Line item"
        text={media.milestones?.map((milestone) => milestone.name).join(', ') || '-'}
      />
      <LabelAndValue
        size={DetailsSizes[size]?.label}
        label="Approximate location"
        text={media.location?.address || '-'}
        icon={
          media.location?.is_verified && (
            <SuccessIcon color={colors.status.success.medium} size={18} />
          )
        }
      />
      {size !== 'small' && (
        <GoogleMap location={{ ...media.location, lng: media.location?.long }} />
      )}
      <LabelAndValue size={DetailsSizes[size]?.label} label="Device" text={media.device || '-'} />
      <LabelAndValue
        size={DetailsSizes[size]?.label}
        label="Taken on"
        text={dateFormatter({ date: media.metadata?.progress_photo_file_created_at }) || '-'}
      />
      <LabelAndValue
        size={DetailsSizes[size]?.label}
        label="Loaded by"
        text={media.created_by_service_name || media.created_by?.['full_name'] || '-'}
      />
      <LabelAndValue
        size={DetailsSizes[size]?.label}
        label="Loaded at"
        text={dateFormatter({ date: media.created_at }) || '-'}
      />
      <LabelAndValue
        size={DetailsSizes[size]?.label}
        label={`${mediaType} name`}
        text={media.original_filename || '-'}
      />
      <Box sx={{ py: 1 }} />
      {media.comment && (
        <>
          <Typography variant="h4">Comments</Typography>
          <Typography variant="body3">{media.comment}</Typography>
          <Box sx={{ py: 1 }}>
            <Divider />
          </Box>
        </>
      )}
      <Tags items={[...(media.source_label?.split('/') || [])]} source="photos_tab__card" />
    </Stack>
  );
};

export default MediaDetails;
