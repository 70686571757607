import React, { FC } from 'react';
import { Box } from '@mui/material';
import { IDocument, IPhoto, IProofpoint } from '@interfaces';
import { PhotoCardWithPopper } from '@components';
import { useLaunchDarklyFlags } from '@context';

const Photo: FC<{
  photo: IProofpoint | IDocument;
  onClick: () => void;
  source: string;
}> = ({ photo, onClick, source }) => {
  const flags = useLaunchDarklyFlags();
  return (
    <Box
      sx={{ width: '125px', mr: 1 }}
      key={photo.id}
      onClick={onClick}
      data-cy={`${source}__image`}
    >
      <PhotoCardWithPopper
        photo={
          {
            ...photo,
            milestones: flags?.['ENG_9342_replace_line_item_name_with_milestone_names']
              ? photo['milestone_names']?.map((milestone) => ({
                  name: milestone,
                }))
              : photo['line_item_name']?.map((milestone) => ({
                  name: milestone,
                })) || [],
          } as IPhoto
        }
        size="medium"
        dataTestName={`${source}__image__button`}
      />
    </Box>
  );
};

const MemoizedPhoto = React.memo(
  Photo,
  (prevProps, nextProps) => prevProps?.photo?.id === nextProps?.photo?.id,
);

export default MemoizedPhoto;
