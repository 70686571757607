import { dataProvider } from '@api';
import {
  CommonServiceOrderPayload,
  IServiceAgency,
  IServiceOrder,
  IServiceOrderPayload,
  IDocumentType,
} from '@interfaces';
import {
  serviceAgencies,
  projectServiceOrdersList,
  projectServiceOrder,
  drawRequestServiceOrdersList,
  scheduleServiceOrder,
  cancelProjectServiceOrder,
  serviceOrdersList,
  serviceOrderDocumentsTypes,
} from '../paths';

export const getServiceAgencies = async (): Promise<{ results: IServiceAgency[] }> =>
  dataProvider.get(serviceAgencies()).json();

export const postServiceOrderToProject = async ({
  projectId,
  json,
}: {
  projectId: string;
  json: IServiceOrderPayload;
}): Promise<IServiceOrder> =>
  dataProvider.post(projectServiceOrdersList(projectId), { json }).json();

export const patchServiceOrderToProject = async ({
  projectId,
  serviceOrderId,
  json,
}: {
  projectId: string;
  serviceOrderId: string;
  json: IServiceOrderPayload;
}): Promise<IServiceOrder> =>
  dataProvider
    .patch(projectServiceOrder({ projectId, serviceOrderId, restQlquery: '{}' }), {
      json: json,
    })
    .json();

export const getServiceOrderById = async ({
  projectId,
  serviceOrderId,
  restQlquery,
}: {
  projectId: string;
  serviceOrderId: string;
  restQlquery?: string;
}): Promise<IServiceOrder> =>
  dataProvider.get(projectServiceOrder({ projectId, serviceOrderId, restQlquery })).json();

export const getProjectServiceOrdersList = async ({
  projectId,
  restQlquery,
  stringQueryParams,
}: {
  projectId: string;
  restQlquery?: string;
  stringQueryParams?: string;
}): Promise<{ results: IServiceOrder[]; count: number }> =>
  dataProvider.get(projectServiceOrdersList(projectId, restQlquery, stringQueryParams)).json();

export const getDrawRequestServiceOrdersList = async ({
  projectId,
  drawRequestId,
  restQlquery,
  stringQueryParams,
}: {
  projectId: string;
  drawRequestId: string;
  restQlquery?: string;
  stringQueryParams?: string;
}): Promise<{ results: IServiceOrder[] }> =>
  dataProvider
    .get(drawRequestServiceOrdersList({ projectId, drawRequestId, restQlquery, stringQueryParams }))
    .json();

export const deleteServiceOrder = async ({
  projectId,
  serviceOrderId,
}: {
  projectId: string;
  serviceOrderId: string;
}): Promise<Response> =>
  dataProvider.delete(projectServiceOrder({ projectId, serviceOrderId })).json();

export const scheduleServiceOrderToProject = async ({
  projectId,
  serviceOrderId,
}: CommonServiceOrderPayload): Promise<Response> =>
  dataProvider.post(scheduleServiceOrder({ projectId, serviceOrderId })).json();

export const cancelServiceOrder = async ({
  projectId,
  serviceOrderId,
}: CommonServiceOrderPayload): Promise<Response> =>
  dataProvider.post(cancelProjectServiceOrder({ projectId, serviceOrderId })).json();

export const getServiceOrdersListForAllProjects = async ({
  stringQueryParams,
}: {
  stringQueryParams: string;
}): Promise<{ results: IServiceOrder[]; count: number }> => {
  return dataProvider.get(serviceOrdersList({ stringQueryParams })).json();
};

export const getServiceDocumentTypes = async (): Promise<IDocumentType[]> =>
  dataProvider.get(serviceOrderDocumentsTypes()).json();
