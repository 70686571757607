import React from 'react';
import { Autocomplete, Stack, TextField, Typography } from '@mui/material';

import { HookState } from '@interfaces';
import { LoadingSkeleton, ServiceMessage, StyledBox } from '@components';
import * as Controller from './controller';

const CompanyOtherSettings = () => {
  const controller = Controller.useCompanyOtherSettings();
  const { state, changeRequestConfiguration, options, companyUpdate, hasPoliciesEditPermission } =
    controller;

  switch (state) {
    case HookState.FETCHING: {
      return (
        <StyledBox sx={{ flex: 1 }}>
          <LoadingSkeleton type="overviewBlock" />
        </StyledBox>
      );
    }

    case HookState.ERROR: {
      return <ServiceMessage text="Company Other Settings" />;
    }

    case HookState.SUCCESS: {
      return (
        <Stack spacing={2} sx={{ flex: 1 }}>
          <StyledBox>
            <Typography variant="h3">Change request</Typography>
            <Autocomplete
              size="small"
              options={options}
              getOptionLabel={(option) => option.label}
              onChange={(_, value) => companyUpdate(value.value)}
              value={changeRequestConfiguration}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="change-request-configuration"
                  label="Change request configuration"
                  variant="outlined"
                  inputProps={{
                    ...params?.inputProps,
                    'data-cy': 'company__other_settings__change_request_configuration__input',
                  }}
                />
              )}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              disabled={!hasPoliciesEditPermission}
              sx={{ minWidth: 200, width: '100%', pl: 2, mt: 3 }}
            />
          </StyledBox>
        </Stack>
      );
    }

    default:
      return null;
  }
};

export default CompanyOtherSettings;
