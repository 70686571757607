import React, { FC, memo, useCallback, useContext, useMemo } from 'react';
import { CommonRowType, MilestoneListColumnTypeV2 } from './common';
import { EditIcon } from '@svgAsComponents';
import { colors } from '@theme';
import { IconButtonWithTooltip } from '@components';
import { UnitsContext } from '../../../sections/NewProjectV2/components/PropertyDetails';
import { Stack, Typography } from '@mui/material';

const Row: FC<{ row: CommonRowType }> = ({ row }) => {
  const { openAdditionalPropertyDetailsModal } = useContext(UnitsContext);

  const isEditable = useMemo(() => row.isEditableV2, [row]);
  const openModalCallback = useCallback(() => openAdditionalPropertyDetailsModal(row.id), [row.id]);
  const propertyDetailsString = useMemo(
    () =>
      row.propertyDetails
        ?.map(
          (detail) =>
            `${detail.name_display || detail.name}  ${detail.existing_value} -> ${detail.proposed_value}`,
        )
        .join(', ') || '',
    [row],
  );
  if (row.parentId) return null;

  return (
    <Stack width="100%" flex={1} alignItems="center" justifyContent="space-between" direction="row">
      <Typography variant="body2" sx={{ mr: 1 }}>
        {propertyDetailsString}
      </Typography>
      <IconButtonWithTooltip
        disabled={!isEditable}
        onClick={openModalCallback}
        tooltipText="Open popup"
      >
        <EditIcon size={24} color={colors.icons.green} />
      </IconButtonWithTooltip>
    </Stack>
  );
};

const Memoized = memo(Row);

const additionalPropertyDetails: MilestoneListColumnTypeV2 = {
  field: 'additional_property_details',
  headerName: 'Additional property details',
  description: '',
  headerClassName: 'text',
  headerAlign: 'left',
  align: 'left',
  flex: 1,
  cellClassName: 'text',
  editable: false,
  pinnable: false,
  disableColumnMenu: true,
  sortable: false,
  filterable: false,
  groupable: false,
  aggregable: false,
  hideable: false,
  resizable: true,
  renderCell: (params) => <Memoized row={params.row} />,
  minWidth: 200,
  editableByMilestone: (row) => row.isEditableV2,
};

export default additionalPropertyDetails;
