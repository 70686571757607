import React, { FC, useContext, useMemo } from 'react';
import { MilestoneListColumnTypeV2 } from './common';
import { Box, Stack, Typography } from '@mui/material';
import { TextInputWithTooltip } from '@components';
import { ColumnAlign, ProductionBuildCommonRowType } from '@interfaces';
import { useStringFieldModel } from '@models';
import { UnitsContext } from '../../../sections/NewProjectV2/components/PropertyDetails';

const Quantity: FC<{ row: ProductionBuildCommonRowType }> = ({ row }) => {
  const { updateQuantity } = useContext(UnitsContext);

  const isEditable = useMemo(() => row.isEditableV2, [row]);
  const quantity = useStringFieldModel({
    initValue: (row['quantity'] || '').toString(),
    validationRule: (value) => Boolean(value?.trim()),
  });

  if (row.parentId) return null;

  return isEditable ? (
    <Stack flexDirection="row" maxWidth={120} justifyContent="space-between">
      <TextInputWithTooltip
        type="number"
        value={quantity.value}
        onChange={(e) => updateQuantity(row.id, +e.target.value)}
      />
    </Stack>
  ) : (
    <Box textAlign={ColumnAlign.TEXT}>
      <Typography variant="body3">{row.quantity}</Typography>
    </Box>
  );
};

const quantity: MilestoneListColumnTypeV2 = {
  field: 'quantity',
  headerName: 'Quantity',
  description: '',
  headerClassName: 'text',
  headerAlign: 'left',
  align: 'left',
  cellClassName: 'text',
  flex: 1,
  resizable: false,
  editable: true,
  sortable: true,
  filterable: true,
  groupable: false,
  aggregable: true,
  hideable: false,
  minWidth: 120,
  maxWidth: 120,
  renderCell: (params) => <Quantity row={params.row} />,
};

export default quantity;
