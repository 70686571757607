import React, { useContext } from 'react';
import { Button, Divider, Stack, Tooltip, Typography } from '@mui/material';
import {
  ButtonWithTooltip,
  CollapsibleWidget,
  PopperWithAutoComplete,
  SetupNotificationsPopup,
  UserAvatar,
  IconButtonWithTooltip,
} from '@components';
import { colors } from '@theme';
import { VisibilityOffIcon, VisibilityOnIcon } from '@svgAsComponents';
import { useWatchProject } from './controller';
import { AuthContext } from '@context';
import FilterButtonV2 from '../FilterButtonV2';
import truncate from 'lodash/truncate';
import { TOOLTIP_TEXTS, WIDGET_TYPE } from '@constants';

const WatchProject = ({
  onWidgetOpened,
  isWidgetOpen,
  onClose,
}: {
  onWidgetOpened: () => void;
  isWidgetOpen: boolean;
  onClose?: () => void;
}) => {
  const { user } = useContext(AuthContext);
  const WATCHER_TEAMS_LENGTH = 30;
  const WATCHER_NAME_LENGTH = 22;

  const {
    watchers,
    isCurrentUserWatching,
    handleWatchersChange,
    getUserName,
    handleMouseEnter,
    handleMouseLeave,
    hoveredWatcherId,
    filterConfig,
    setFilteredTeamIds,
    filteredTeamIds,
    allWatchersCount,
    isLoading,
    usersOptionsList,
    selectedUsersIds,
    handleAddWatcherClick,
    handleUsersListClose,
    ref,
    anchorEl,
    handleStartWatching,
    handleRemoveWatcher,
    isEditable,
    showNotificationsPopup,
    setShowNotificationsPopup,
    showStartWatchingButton,
  } = useWatchProject();

  return (
    <CollapsibleWidget
      title={
        <Tooltip title="Watchers">
          <Stack direction="row" spacing={1} ref={ref}>
            <VisibilityOnIcon
              size={24}
              color={isCurrentUserWatching ? colors.status.information.medium : colors.icons.gray}
            />
            <Typography variant="body2SemiBold">{allWatchersCount}</Typography>
          </Stack>
        </Tooltip>
      }
      titleBackgroundColor={
        isCurrentUserWatching ? colors.status.information.lighter : colors.background.gray
      }
      containerStyles={{ minWidth: '66px', maxWidth: '66px', height: '40px', cursor: 'pointer' }}
      widgetType={WIDGET_TYPE.WATCHER}
      popperWidth="450px"
      showIcon={false}
      listStyles={{ padding: '0' }}
      dataTestNameButton="project__watch__expand_icon"
      onWidgetOpened={onWidgetOpened}
      isWidgetOpen={isWidgetOpen}
      onWidgetClosed={onClose}
    >
      <Stack sx={{ py: 2 }}>
        {showStartWatchingButton && (
          <Stack sx={{ px: 2 }}>
            <Typography variant="body1SemiBold">You are not watching this project</Typography>
            <Typography variant="body3">Start watching the project to receive updates.</Typography>
            <Button
              onClick={() => handleStartWatching(user.id)}
              data-cy="project__watch__start__button"
              sx={{ width: '150px', mt: 2 }}
            >
              Start watching
            </Button>
            <Divider sx={{ my: '16px' }} />
          </Stack>
        )}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            px: 2,
            pb: 2,
          }}
        >
          <Typography variant="body2SemiBold">Watchers ({watchers.length})</Typography>
          <ButtonWithTooltip
            color="secondary"
            variant="new"
            dataTestName="project__watch__add_watcher__button"
            size="small"
            sx={{ width: '110px', ml: 1 }}
            loading={isLoading}
            onClick={handleAddWatcherClick}
            disabled={!isEditable}
            tooltipText={isEditable ? '' : TOOLTIP_TEXTS.isCurrentProjectArchived}
          >
            Add watcher
          </ButtonWithTooltip>
          <PopperWithAutoComplete
            anchorEl={anchorEl}
            id="watch_project__popper"
            handleClose={handleUsersListClose}
            open={Boolean(anchorEl)}
            options={usersOptionsList}
            placeHolder="Find user"
            handleValuesChange={handleWatchersChange}
            values={selectedUsersIds || []}
            maxLabelLength={35}
          />
        </Stack>
        <Stack sx={{ px: 2, pb: 2 }}>
          <FilterButtonV2
            handleFiltersChange={(_key, value) => setFilteredTeamIds(value)}
            initValues={filteredTeamIds}
            maxWidth="100%"
            minWidth="100%"
            closeOnChange
            {...filterConfig}
          />
        </Stack>
        {watchers.map((watcher) => {
          const watcherTeams = watcher.teams?.join(', ');
          const watcherName = getUserName(watcher);

          return (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                ...(watcher.id === hoveredWatcherId && {
                  backgroundColor: colors.background.lightest,
                }),
                px: 2,
                py: 1,
              }}
              key={watcher.id}
              onMouseEnter={() => handleMouseEnter(watcher.id)}
              onMouseLeave={handleMouseLeave}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                sx={{ mr: 1 }}
              >
                <UserAvatar user={watcher} size={24} />
                <Tooltip title={watcherName.length > WATCHER_NAME_LENGTH ? watcherName : ''}>
                  <Typography variant="body3">
                    {truncate(watcherName, { length: WATCHER_NAME_LENGTH })}
                  </Typography>
                </Tooltip>
              </Stack>
              <Stack alignItems="center" justifyContent="space-between" spacing={2} direction="row">
                <Tooltip title={watcherTeams?.length > WATCHER_TEAMS_LENGTH ? watcherTeams : ''}>
                  <Typography variant="labelSemiBold">
                    {truncate(watcherTeams, { length: WATCHER_TEAMS_LENGTH })}
                  </Typography>
                </Tooltip>
                {isEditable && (
                  <IconButtonWithTooltip
                    tooltipText="Stop watching"
                    sx={{
                      p: 0,
                    }}
                    onClick={() => handleRemoveWatcher(watcher.id)}
                  >
                    <VisibilityOffIcon color={colors.icons.green} />
                  </IconButtonWithTooltip>
                )}
              </Stack>
            </Stack>
          );
        })}
      </Stack>
      {showNotificationsPopup && (
        <SetupNotificationsPopup onClose={() => setShowNotificationsPopup(false)} />
      )}
    </CollapsibleWidget>
  );
};

export default WatchProject;
