import React, { FC } from 'react';
import { Button, Stack } from '@mui/material';
import { CustomDatePickerInput, Popup } from '@components';
import { DateFieldModel } from '@models';

export const TransactionDateEditPopup: FC<{
  transactionDate: DateFieldModel;
  onClose: () => void;
  onSave: ({ disbursed_at }: { disbursed_at: Date }) => void;
}> = ({ transactionDate, onClose, onSave }) => (
  <Popup open maxWidth="md" title="Edit transaction date">
    <Stack sx={{ width: '100%' }}>
      <CustomDatePickerInput
        field={transactionDate}
        label="Transaction date"
        maxDate={new Date()}
        inputProps={{
          'data-cy': 'transaction_date_picker',
        }}
        addTimestamp
      />
      <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: 7 }}>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button
          sx={{ ml: 1 }}
          onClick={() => onSave({ disbursed_at: transactionDate.value })}
          data-cy="transaction_date_save_button"
        >
          Save
        </Button>
      </Stack>
    </Stack>
  </Popup>
);
