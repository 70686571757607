import React, { useCallback, useMemo, useState } from 'react';
import {
  ChangeRequestConfigEnum,
  DrawRequestTypeEnum,
  IProject,
  QueryNamesEnums,
  TableKeyEnum,
} from '@interfaces';
import { useQuery } from 'react-query';
import { getProject } from '@globalService';
import {
  getHiddenColumnsForDR,
  isReallocationEnabledByProject,
  mergeColumnVisibilityModel,
  toggleElementFromArray,
} from '@utils';
import { useColumnFilterV2, useUpdateUiSettings } from '@hooks';
import { useLaunchDarklyFlags } from '@context';

export interface ControllerInterface {
  retainageRate: boolean;
  isReallocationAvailable?: boolean;
  createType?: Array<DrawRequestTypeEnum>;
  setCreateType: React.Dispatch<React.SetStateAction<DrawRequestTypeEnum[]>>;
  shortCreate: (withOverWrite: boolean) => void;
  getCheckboxProps: (type: DrawRequestTypeEnum) => {
    disabled: boolean;
    tooltipText: string;
    onChange: () => void;
  };
}

export const useRequestCreateDialog = (
  onClose: () => void,
  createRequest: (type: DrawRequestTypeEnum) => void,
  projectId: string,
): ControllerInterface => {
  const flags = useLaunchDarklyFlags();
  const [createType, setCreateType] = useState<DrawRequestTypeEnum[]>([]);
  const { updateUserColumns } = useColumnFilterV2(TableKeyEnum.SUBMISSION);
  const { userSettings, updateTableSettings } = useUpdateUiSettings();

  const projectQuery = useQuery<IProject, Error>(
    [QueryNamesEnums.GET_PROJECT, { projectId }],
    getProject.bind(this, projectId),
  );

  const isReallocationAvailable = useMemo(
    () => isReallocationEnabledByProject(projectQuery.data),
    [projectQuery.data],
  );
  const retainageRate = useMemo(() => !!projectQuery.data?.retainage_rate, [projectQuery.data]);

  const changeRequestConfig = useMemo(
    () => projectQuery.data?.change_request_mode as ChangeRequestConfigEnum,
    [projectQuery.data],
  );

  const isOnlyDrawRequest = useMemo(
    () => changeRequestConfig === ChangeRequestConfigEnum.ONLY_DRAW_REQUESTS,
    [changeRequestConfig],
  );
  const isOnlyChangeRequest = useMemo(
    () => changeRequestConfig === ChangeRequestConfigEnum.ONLY_CHANGE_REQUESTS,
    [changeRequestConfig],
  );

  const shortCreate = useCallback(
    (withOverWrite: boolean) => {
      if (withOverWrite) {
        const hiddenColumns = getHiddenColumnsForDR(createType);
        if (flags?.[`ENG_7895_table_v3__${TableKeyEnum.SUBMISSION}`]) {
          updateTableSettings(
            TableKeyEnum.SUBMISSION,
            mergeColumnVisibilityModel(
              hiddenColumns,
              userSettings.tableV3?.[TableKeyEnum.SUBMISSION],
            ),
          );
        }

        updateUserColumns(hiddenColumns);
      }

      onClose();
      if (createType[0] === DrawRequestTypeEnum.CHANGE_REQUEST && createType.length === 1) {
        createRequest(DrawRequestTypeEnum.CHANGE_REQUEST);
      } else {
        createRequest(DrawRequestTypeEnum.DRAW_REQUEST);
      }
    },
    [createType, createRequest, userSettings],
  );

  const isChangeRequestAllowed = useMemo(() => {
    const hasDrawRequest = createType.includes(DrawRequestTypeEnum.DRAW_REQUEST);

    switch (changeRequestConfig) {
      case ChangeRequestConfigEnum.ONLY_DRAW_REQUESTS:
        return hasDrawRequest;
      case ChangeRequestConfigEnum.ONLY_CHANGE_REQUESTS:
        return !hasDrawRequest;
      case ChangeRequestConfigEnum.ALL_REQUESTS:
      default:
        return true;
    }
  }, [createType, changeRequestConfig]);

  const getChangeRequestTooltip = useMemo(() => {
    if (isOnlyDrawRequest && !createType.includes(DrawRequestTypeEnum.DRAW_REQUEST)) {
      return 'Your lender does not allow to order change requests independently from a draw request.';
    }
    if (isOnlyChangeRequest && createType.includes(DrawRequestTypeEnum.DRAW_REQUEST)) {
      return 'Your lender allows you to order change requests only independently from a draw request.';
    }
    return '';
  }, [isOnlyDrawRequest, isOnlyChangeRequest, createType]);

  const getDrawRequestTooltip = useMemo(() => {
    if (isOnlyChangeRequest && createType.includes(DrawRequestTypeEnum.CHANGE_REQUEST)) {
      return 'Your lender allows you to order change requests only independently from a draw request.';
    }
    return '';
  }, [isOnlyChangeRequest, createType]);

  const getCheckboxProps = useCallback(
    (type: DrawRequestTypeEnum) => {
      let disabled = false;
      let tooltipText = '';
      let onChange = () => {};

      switch (type) {
        case DrawRequestTypeEnum.DRAW_REQUEST:
          disabled =
            createType.includes(DrawRequestTypeEnum.CHANGE_REQUEST) &&
            (isOnlyChangeRequest || !isReallocationAvailable);
          tooltipText = getDrawRequestTooltip;
          onChange = () => {
            setCreateType((data) => {
              const newData = toggleElementFromArray(data, DrawRequestTypeEnum.DRAW_REQUEST);
              if (isOnlyDrawRequest && newData.includes(DrawRequestTypeEnum.CHANGE_REQUEST)) {
                return toggleElementFromArray(newData, DrawRequestTypeEnum.CHANGE_REQUEST);
              }
              return newData;
            });
          };
          break;
        case DrawRequestTypeEnum.CHANGE_REQUEST:
          disabled =
            !isChangeRequestAllowed || createType.includes(DrawRequestTypeEnum.RETAINAGE_REQUEST);
          tooltipText = getChangeRequestTooltip;
          onChange = () => {
            setCreateType((data) =>
              toggleElementFromArray(data, DrawRequestTypeEnum.CHANGE_REQUEST),
            );
          };
          break;
        case DrawRequestTypeEnum.RETAINAGE_REQUEST:
          disabled =
            createType.includes(DrawRequestTypeEnum.CHANGE_REQUEST) &&
            !(isReallocationAvailable && createType.includes(DrawRequestTypeEnum.DRAW_REQUEST));
          tooltipText = '';
          onChange = () => {
            setCreateType((data) =>
              toggleElementFromArray(data, DrawRequestTypeEnum.RETAINAGE_REQUEST),
            );
          };
          break;
        default:
          break;
      }

      return { disabled, tooltipText, onChange };
    },
    [
      createType,
      isChangeRequestAllowed,
      isOnlyChangeRequest,
      isReallocationAvailable,
      getDrawRequestTooltip,
      getChangeRequestTooltip,
    ],
  );

  return {
    retainageRate,
    isReallocationAvailable,
    createType,
    setCreateType,
    shortCreate,
    getCheckboxProps,
  };
};
