import React, { FC, useState } from 'react';
import { Box, Button, Grid2, Stack, Typography } from '@mui/material';

import {
  ColumnsFilter,
  DownloadCSVButton,
  DrawRequestsListFiltersV2,
  FiltersTypes,
  FiltersV2,
  ServiceMessage,
  StyledBox,
  TablePagination,
} from '@components';
import { useColumnFilterV2, useSorting, useTablePagination } from '@hooks';
import { HookState, PermissionNamesEnums, TableKeyEnum } from '@interfaces';
import * as Controller from './controller';
import { useDrawRequestList } from './dataController';
import { AddRequestPopup, DrawChangeRequestTable, RequestsSummaryBlock } from '../index';
import { WithPermission } from '@utils';

const DrawChangeRequestTab: FC = () => {
  const { page, rowsPerPage, rowsPerPageOptions, onPageChange, onRowsPerPageChange } =
    useTablePagination({});
  const { handleSortClick, sortValue } = useSorting();
  const [filterStringQuery, setFilterStringQuery] = useState<string>('');
  const { state, count, formattedData, allRequestCount, onFiltersReady } = useDrawRequestList({
    page,
    rowsPerPage,
    sortValue,
    filterStringQuery,
  });

  const { hiddenColumns, changeFieldVisibility, isColumnFilterUpdating } = useColumnFilterV2(
    TableKeyEnum.REQUESTS,
  );

  const controller = Controller.useDrawChangeRequestTab({
    isLoading: state === HookState.FETCHING,
    formattedData,
    hiddenColumns,
  });
  const { columns, csvHeaders } = controller;

  return (
    <Stack sx={{ height: '100%' }}>
      <StyledBox
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 1,
        }}
      >
        <Typography variant="h2" sx={{ mr: 2 }}>
          Requests {allRequestCount ? `(${allRequestCount})` : ''}
        </Typography>
        {controller.isOwner && (
          <WithPermission permissionKey={PermissionNamesEnums.DRAWREQUESTS_CREATE}>
            <Button
              data-cy="requests__add__button"
              size="small"
              onClick={() => controller.setIsAddRequestPopupOpen(true)}
            >
              Add request
            </Button>
          </WithPermission>
        )}
      </StyledBox>

      <RequestsSummaryBlock />

      <Box sx={{ mt: 2 }} />
      <StyledBox sx={{ p: 0, flexGrow: 1 }}>
        <Stack flexDirection="row" justifyContent="space-between">
          <FiltersV2
            onReady={onFiltersReady}
            tableKey={TableKeyEnum.REQUESTS}
            source="requests"
            setFilterStringQuery={setFilterStringQuery}
            LeftComponent={DrawRequestsListFiltersV2}
            filters={
              [
                'textSearchFilter',
                'isOnHoldFilter',
                'requestStatusFilter',
                'currentReviewerTeamFilter',
                'projectStatusFilter',
                'inspectionStatusFilter',
                'lenderFilter',
                'borrowerFilter',
                'coordinatorFilter',
                'projectHealthFilter',
                'customerFilter',
                'inverstorFilter',
              ] as FiltersTypes[]
            }
            onPageChange={onPageChange}
          />

          <Box mt={1} mr={2}>
            <Stack flexDirection="row" sx={{ alignItems: 'center' }}>
              <Stack mr={1}>
                <ColumnsFilter
                  columns={columns}
                  hiddenColumns={hiddenColumns}
                  changeFieldVisibility={changeFieldVisibility}
                  isUpdating={isColumnFilterUpdating}
                  source={TableKeyEnum.REQUESTS.toLowerCase()}
                />
              </Stack>
              <Stack>
                <DownloadCSVButton
                  data={formattedData}
                  headers={csvHeaders}
                  filename={`dr-list.csv`}
                />
              </Stack>
            </Stack>
          </Box>
        </Stack>
        {state === HookState.ERROR && <ServiceMessage text="draw requests list" />}
        {state !== HookState.FETCHING && state !== HookState.ERROR && !formattedData?.length && (
          <ServiceMessage>There are no draw requests in this category</ServiceMessage>
        )}
        {(state === HookState.FETCHING || Boolean(formattedData?.length)) && (
          <>
            <DrawChangeRequestTable
              initialData={formattedData}
              initialSortBy={sortValue}
              handleSortClick={handleSortClick}
              isUpdating={state === HookState.FETCHING}
              isLoading={state === HookState.FETCHING}
              hiddenColumns={hiddenColumns}
              controller={controller}
            />
            <Grid2 container justifyContent="flex-end" alignItems="center" sx={{ mt: 1 }}>
              <TablePagination
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
                itemsCount={count}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
              />
            </Grid2>
          </>
        )}
      </StyledBox>
      {controller.isAddRequestPopupOpen && (
        <AddRequestPopup onClose={() => controller.setIsAddRequestPopupOpen(false)} />
      )}
    </Stack>
  );
};

export default DrawChangeRequestTab;
