import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import {
  CenteredStyledBox,
  CustomAutocomplete,
  CustomCurrencyTextField,
  CustomDatePickerInput,
  CustomPercentTextField,
  CustomRadioGroup,
  CustomTextField,
  FundingSourcesContainer,
  PaymentConfigurationEdit,
  WysiwygEditor,
} from '@components';
import { getNextDay, getPrevDay } from '@utils';
import { useLaunchDarklyFlags } from '@context';

const LoanDetails = ({
  loanDetailsFields,
  borrowerEquityLocal,
  editPaymentConfigurationData,
  loan_servicing_statuses,
  loan_types,
}) => {
  const {
    external_id,
    maturity_date,
    extended_maturity_date,
    funding_date,
    loc_commitment,
    prefunding_cost,
    construction_holdback,
    property_after_repair_value,
    contribution_fraction,
    retainage_rate,
    borrower_equity,
    post_funding_construction_budget,
    estimated_start_date,
    original_completion_date,
    estimated_completion_date,
    payment_configuration_comment,
    loan_status,
    loan_type,
  } = loanDetailsFields;
  const flags = useLaunchDarklyFlags();

  return (
    <Stack alignItems="center" spacing={2}>
      <CenteredStyledBox>
        <Stack spacing={3}>
          <Stack direction="row" spacing={2}>
            <CustomTextField
              field={external_id}
              label="Loan ID (optional)"
              inputProps={{
                'data-cy': 'project__create__loan_id__input',
              }}
            />
            {flags?.['ENG_8716_introduce_funding_sources'] && (
              <Stack sx={{ width: '100%' }}>
                <CustomCurrencyTextField
                  label="Total loan commitment (optional)"
                  field={loc_commitment}
                  inputProps={{
                    'data-cy': 'project__create__total_loan_commitment__input',
                  }}
                />
                <Typography variant="label" sx={{ display: 'inline-block', ml: 1 }}>
                  Costs towards land acquisition, construction, associated expenses
                </Typography>
              </Stack>
            )}
          </Stack>
          <Stack direction="row" spacing={2}>
            <CustomAutocomplete
              label="Loan type (optional)"
              field={loan_type}
              options={loan_types}
              inputProps={{
                'data-cy': 'project__settings__loan__type__input',
              }}
            />
            <CustomAutocomplete
              label="Loan servicing status (optional)"
              field={loan_status}
              options={loan_servicing_statuses}
              inputProps={{
                'data-cy': 'project__settings__loan__servicing_status__input',
              }}
            />
          </Stack>
        </Stack>
      </CenteredStyledBox>
      <CenteredStyledBox>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            <CustomDatePickerInput
              label="Loan funding date (optional)"
              field={funding_date}
              maxDate={getPrevDay(maturity_date.value)}
              inputProps={{
                'data-cy': 'project__create__funding_date__input',
              }}
            />
            <CustomDatePickerInput
              label="Estimated start date  (optional)"
              field={estimated_start_date}
              maxDate={getPrevDay(original_completion_date.value)}
              inputProps={{
                'data-cy': 'project__create__estimated_start_date__input',
              }}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <CustomDatePickerInput
              label="Original maturity date (optional)"
              field={maturity_date}
              minDate={getNextDay(funding_date.value)}
              maxDate={extended_maturity_date.value}
              inputProps={{
                'data-cy': 'project__create__maturity_date__input',
              }}
            />
            <CustomDatePickerInput
              label="Extended maturity date (optional)"
              field={extended_maturity_date}
              minDate={maturity_date.value}
              inputProps={{
                'data-cy': 'project__create__extended_maturity_date__input',
              }}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <CustomDatePickerInput
              label="Estimated completion date (optional)"
              field={estimated_completion_date}
              minDate={getNextDay(estimated_start_date.value)}
              inputProps={{
                'data-cy': 'project__create__estimated_completion_date__input',
              }}
            />
          </Stack>
        </Stack>
      </CenteredStyledBox>
      {flags?.['ENG_8716_introduce_funding_sources'] ? (
        <CenteredStyledBox>
          <FundingSourcesContainer
            prefunding_cost={prefunding_cost}
            construction_holdback={construction_holdback}
            constructionHoldbackFraction={contribution_fraction}
            borrower_equity={borrower_equity}
            borrowerEquityFraction={borrowerEquityLocal}
            configurationType={editPaymentConfigurationData.configurationType}
            setConfigurationType={editPaymentConfigurationData.setConfigurationType}
            constructionBudget={post_funding_construction_budget}
            source="project__create"
            configurationTypeOptions={editPaymentConfigurationData.configurationTypeOptions}
          />
        </CenteredStyledBox>
      ) : (
        <>
          <CenteredStyledBox>
            <Stack spacing={2}>
              <Stack direction="row" spacing={2}>
                <Stack sx={{ width: '100%' }}>
                  <CustomCurrencyTextField
                    label="Total Loan Commitment (optional)"
                    field={loc_commitment}
                    inputProps={{
                      'data-cy': 'project__create__loc_commitment__input',
                    }}
                  />
                  <Typography variant="label" sx={{ ml: '14px', display: 'inline-block' }}>
                    This loan amount includes costs towards land acquisition, construction, and
                    associated expenses.
                  </Typography>
                </Stack>
              </Stack>
              <Box>
                <Stack direction="row" spacing={2}>
                  <CustomCurrencyTextField
                    label="Pre-funding Costs (not in budget) (optional)"
                    field={prefunding_cost}
                    inputProps={{
                      'data-cy': 'project__create__prefunding_cost__input',
                    }}
                  />
                  <CustomCurrencyTextField
                    label="After repair value (ARV) (optional)"
                    field={property_after_repair_value}
                  />
                </Stack>
                <Typography variant="label" sx={{ ml: '14px', display: 'inline-block' }}>
                  Project costs spent prior to loan closing.
                </Typography>
              </Box>
            </Stack>
          </CenteredStyledBox>
          <CenteredStyledBox>
            <Stack>
              <Typography variant="h3">Funding contribution ratio (optional)</Typography>
              <Typography variant="body1" sx={{ mt: '8px', display: 'inline-block' }}>
                Please provide the details below. The construction budget post-funding represents
                the sum of the construction holdback and borrower equity. Note that certain loan
                details will not be visible in your project until the budget has been uploaded.
              </Typography>
              <Stack mt={3}>
                <PaymentConfigurationEdit
                  postFundingConstructionBudget={post_funding_construction_budget}
                  constructionHoldback={construction_holdback}
                  constructionHoldbackFraction={contribution_fraction}
                  borrowerEquity={borrower_equity}
                  borrowerEquityFraction={borrowerEquityLocal}
                  source="project__create__loan"
                  clearOnTouchPostFundingConstructionBudget
                  isCreateProject
                />
              </Stack>
            </Stack>
          </CenteredStyledBox>
          <CenteredStyledBox>
            <Stack data-cy={'project__create__funding_option__block'}>
              <Typography variant="h3" sx={{ mb: 2 }}>
                Payment model (optional)
              </Typography>
              <CustomRadioGroup
                dataTestName="project__create__funding_option__radio_group"
                value={editPaymentConfigurationData.configurationType}
                onChange={editPaymentConfigurationData.handleConfigurationTypeChange}
                options={editPaymentConfigurationData.configurationTypeOptions}
                direction="column"
              />
              <Stack sx={{ maxWidth: '800px', mt: 2 }}>
                <WysiwygEditor
                  editField={payment_configuration_comment}
                  source="project__create____payment_configuration__comment"
                />
              </Stack>
            </Stack>
          </CenteredStyledBox>
        </>
      )}
      <CenteredStyledBox>
        <Stack data-cy={'project__create__loan_retainage_block__block'}>
          <Typography variant="h3">Retainage</Typography>
          <Typography variant="body1" sx={{ mt: '8px', display: 'inline-block' }}>
            If retainage is a fixed rate, please enter the value below. If it is variable, you can
            enter the value per line item when creating the budget.
          </Typography>
          <Stack direction="row" justifyContent="space-between" spacing={3} mt={3}>
            <CustomPercentTextField
              label="Fixed retainage (%) (optional)"
              field={retainage_rate}
              inputProps={{
                'data-cy': 'project__create__loan_retainage__input',
              }}
            />
          </Stack>
        </Stack>
      </CenteredStyledBox>
    </Stack>
  );
};

export default LoanDetails;
