import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { Stack, Typography } from '@mui/material';
import { DeleteIcon, EditIcon } from '@svgAsComponents';
import { colors } from '@theme';
import { useDragAndDrop } from '@hooks';
import { PoliciesItemLocal } from '@interfaces';
import { InfoIconWithTooltip, IconButtonWithTooltip } from '@components';

const DND_ITEM_TYPE = 'policiesInput';

interface ComponentProps {
  index: number;
  item: PoliciesItemLocal;
  setItems: Dispatch<SetStateAction<PoliciesItemLocal[]>>;
  limitedEditing?: boolean;
  setOpenEditPolicyItem: Dispatch<SetStateAction<string>>;
  source: string;
}

const PoliciesItemRow = ({
  index,
  item,
  setItems,
  limitedEditing,
  setOpenEditPolicyItem,
  source,
}: ComponentProps) => {
  const { opacity, boxShadow, getDragItemIcon, dropRef } = useDragAndDrop({
    dndItemType: DND_ITEM_TYPE,
    setItems,
    index,
  });

  const removeItem = useCallback(
    () => setItems((old) => old.filter((_, i) => i !== index)),
    [index],
  );

  if (!item) return null;
  const { id, label, description } = item;

  return (
    <Stack
      ref={dropRef}
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ width: '100%', mb: 1, mt: 0.5, opacity, boxShadow }}
    >
      {!limitedEditing && (
        <Stack sx={{ p: 0, ml: 1, alignItems: 'flex-end' }}>{getDragItemIcon()}</Stack>
      )}
      <Stack sx={{ width: '90%' }}>
        <Stack sx={{ m: 0.5, ml: 1.5 }}>
          <Typography variant="body2" data-cy={`${source}_policy__items__label__value`}>
            {label}
          </Typography>
        </Stack>
      </Stack>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
        <Stack sx={{ mr: 1 }}>
          <InfoIconWithTooltip
            data-cy={`${source}_policy__items__list__info_tooltip__icon`}
            color={description ? colors.status.information.medium : colors.neutral.medium}
            tooltipText={
              description && (
                <>
                  <Typography variant="labelSemiBold">Description:</Typography>
                  <Typography variant="label" dangerouslySetInnerHTML={{ __html: description }} />
                </>
              )
            }
          />
        </Stack>
        <Stack sx={{ pt: 0.5 }}>
          <IconButtonWithTooltip
            onClick={() => setOpenEditPolicyItem(id)}
            sx={{ p: 0, minWidth: 24, alignItems: 'flex-end' }}
            data-cy={`${source}_policy__items__list__edit_policy_item__icon`}
            tooltipText="Edit"
            placement="top"
          >
            <EditIcon size={20} />
          </IconButtonWithTooltip>
        </Stack>
        <IconButtonWithTooltip
          onClick={removeItem}
          sx={{ p: 0, ml: 1, alignItems: 'flex-end' }}
          data-cy={`${source}_policy__items__list__delete__icon`}
          tooltipText="Delete"
          placement="top"
        >
          <DeleteIcon size={20} color={colors.icons.gray} />
        </IconButtonWithTooltip>
      </Stack>
    </Stack>
  );
};

export default PoliciesItemRow;
