import { useParams } from 'react-router-dom';
import { useMemo, useContext } from 'react';

import { IProject, QueryNamesEnums, PermissionNamesEnums } from '@interfaces';
import { isRestricted } from '@utils';
import { SettingsContext, PermissionsContext, useGetData } from '@context';

interface ControllerInterface {
  isLoading: boolean;
  project: Partial<IProject>;
  isAllProjectDetailsDisabled: boolean;
  isLimitedProjectDetailsDisabled: boolean;
  hasInspectionsViewPermission: boolean;
  hasInspectionsEditPermission: boolean;
}

export const useProjectSettings = (): ControllerInterface => {
  const { projectId } = useParams();
  const { permissions } = useContext(PermissionsContext);
  const { isCurrentProjectArchived } = useContext(SettingsContext);
  const project = useGetData({
    type: QueryNamesEnums.GET_PROJECT,
    keys: [
      'id',
      'name',
      'status',
      'status_change_reason',
      'loan',
      'address',
      'legal_entity',
      'scope_of_work',
      'exit_strategy',
      'type',
      'property_existing_type',
      'property_proposed_type',
      'estimated_completion_date',
      'estimated_completion_date_change_reason',
      'estimated_start_date',
      'original_completion_date',
      'payment_configuration_type',
      'payment_configuration_comment',
      'inspection_additional_contact_name',
      'inspection_additional_contact_phone',
      'inspection_entry_access_code',
      'inspection_is_order_automatic',
      'inspection_primary_contact_user_id',
      'inspection_cadence',
      'fees',
      'thumb_representations',
      'is_lump_sum_enabled',
    ],
    args: { projectId },
  });

  const hasInspectionsViewPermission = useMemo(
    () => !isRestricted(PermissionNamesEnums.INSPECTIONS_VIEW, permissions),
    [permissions],
  );

  const hasInspectionsEditPermission = useMemo(
    () => !isRestricted(PermissionNamesEnums.INSPECTIONS_EDIT, permissions),
    [permissions],
  );

  // permission projects_details_edit (mostly for lenders)
  const isAllProjectDetailsDisabled = useMemo(
    () =>
      isCurrentProjectArchived ||
      isRestricted(PermissionNamesEnums.PROJECTS_DETAILS_EDIT, permissions),
    [permissions, isCurrentProjectArchived],
  );
  // permission projects_edit (mostly for borrowers)
  const isLimitedProjectDetailsDisabled = useMemo(
    () => isCurrentProjectArchived || isRestricted(PermissionNamesEnums.PROJECTS_EDIT, permissions),
    [permissions, isCurrentProjectArchived],
  );

  return {
    isLoading: project.isLoading,
    project: project.data,
    isAllProjectDetailsDisabled,
    isLimitedProjectDetailsDisabled,
    hasInspectionsViewPermission,
    hasInspectionsEditPermission,
  };
};
