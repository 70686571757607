import React, { useMemo } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { colors } from '@theme';
import {
  ApplyProjectPoliciesBulkPanel,
  ColumnsFilter,
  DrawRequestsListFiltersV2,
  FiltersTypes,
  FiltersV2,
  ProjectsStats,
  RightDrawer,
  SetupNotificationsPopup,
  StyledBox,
  TableItemRightDrawer,
  TablePaginationNew,
  VerticalMenu,
  ViewSwitcher,
  WatchProjectsBulkPanel,
} from '@components';
import { useProjects } from './controller';
import { PermissionNamesEnums, TableKeyEnum } from '@interfaces';
import ProjectsList from '../ProjectsList';
import { WithPermission } from '@utils';
import ProjectCardsList from '../ProjectCardsList';
import { useLaunchDarklyFlags } from '@context';

const Projects = () => {
  const {
    tablePagination,
    isListView,
    updateListView,
    addProjectMenuItems,
    setFilterStringQuery,
    columns,
    hiddenColumns,
    changeFieldVisibility,
    isColumnFilterUpdating,
    rightMenu,
    rightDrawerParams,
    isLoading,
    isFetching,
    isError,
    projects,
    filteredProjectsCount,
    handleSortClick,
    sortValue,
    selectedProjects,
    unSelectAllProjects,
    showNotificationsPopup,
    setShowNotificationsPopup,
    showNotificationSetupPopup,
    onFiltersReady,
    filterStringQuery,
    filtersReady,
    isLender,
    selectedProjectCompanyIds,
    isCustomerSuccess,
  } = useProjects();
  const { page, rowsPerPage, rowsPerPageOptions, onPageChange, onRowsPerPageChange } =
    tablePagination;
  const flags = useLaunchDarklyFlags();
  const filters = useMemo(
    () => [
      'textSearchFilter',
      'projectStatusFilter',
      'projectTypeFilter',
      'borrowerFilter',
      'projectHealthFilter',
      'loansTypeFilter',
      'propertyExistingTypeFilter',
      'propertyProposedTypeFilter',
      'customerFilter',
      'inverstorFilter',
      'lenderFilter',
      'coordinatorFilter',
      'isBudgetLockedFilter',
      'statesFilter',
    ],
    [flags],
  );

  return (
    <Stack sx={{ height: '100%' }}>
      <Stack
        sx={{
          backgroundColor: colors.background.gray,
          flexGrow: 1,
          p: { md: 3, xs: 2 },
        }}
      >
        <StyledBox
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 1,
          }}
        >
          <Typography variant="h2" sx={{ mr: 2 }}>
            Projects {filteredProjectsCount ? `(${filteredProjectsCount} selected)` : ''}
          </Typography>
          <WithPermission permissionKey={PermissionNamesEnums.PROJECT_ONBOARDING}>
            <Stack direction="row" spacing={2} ml={5}>
              <VerticalMenu
                menuItems={addProjectMenuItems}
                closeOnItemClick={false}
                anchorOriginHorizontal="left"
              >
                <Button data-cy="projects__add__menu" size="small">
                  Add project
                </Button>
              </VerticalMenu>
            </Stack>
          </WithPermission>
        </StyledBox>
        <ProjectsStats filterStringQuery={filterStringQuery} filtersReady={filtersReady} />
        <StyledBox
          sx={{
            flexGrow: 1,
            p: { sm: 2, xs: 0 },
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
            sx={{ mb: 3 }}
          >
            <FiltersV2
              onReady={onFiltersReady}
              tableKey={TableKeyEnum.RISK_RADAR}
              source="projects__table"
              setFilterStringQuery={setFilterStringQuery}
              LeftComponent={DrawRequestsListFiltersV2}
              padding={0}
              filters={filters as FiltersTypes[]}
              onPageChange={onPageChange}
            />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              sx={{
                '& a': { textDecoration: 'none' },
                ml: 2,
                minWidth: '430px',
              }}
            >
              <ViewSwitcher
                isListView={isListView}
                source="projects"
                handleChangeView={(listView) => updateListView({ listView })}
              />
              <TablePaginationNew
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
                itemsCount={filteredProjectsCount}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                source="projects"
              />
              <ColumnsFilter
                columns={columns}
                hiddenColumns={hiddenColumns}
                changeFieldVisibility={changeFieldVisibility}
                isUpdating={isColumnFilterUpdating}
                source="projects__table"
                disabled={!isListView}
              />
            </Stack>
          </Stack>
          {isListView ? (
            <ProjectsList
              columns={columns}
              hiddenColumns={hiddenColumns}
              isLoading={isLoading}
              isFetching={isFetching}
              isError={isError}
              projects={projects}
              handleSortClick={handleSortClick}
              sortValue={sortValue}
            />
          ) : (
            <ProjectCardsList
              projects={projects}
              isLoading={isLoading || isFetching}
              isError={isError}
            />
          )}
        </StyledBox>
      </Stack>
      <RightDrawer {...rightMenu}>
        <TableItemRightDrawer
          projectId={rightDrawerParams.projectId}
          activeTab={rightDrawerParams.activeTab}
          source="projects__right_drawer"
        />
      </RightDrawer>
      {selectedProjects.length > 0 && isLender && (
        <WatchProjectsBulkPanel
          selectedProjectsIds={selectedProjects}
          onClose={unSelectAllProjects}
          onComplete={showNotificationSetupPopup}
        />
      )}
      {selectedProjects.length > 0 && isCustomerSuccess && (
        <ApplyProjectPoliciesBulkPanel
          selectedProjectsIds={selectedProjects}
          selectedProjectCompanyIds={selectedProjectCompanyIds}
          onClose={unSelectAllProjects}
        />
      )}
      {showNotificationsPopup && (
        <SetupNotificationsPopup onClose={() => setShowNotificationsPopup(false)} />
      )}
    </Stack>
  );
};

export default Projects;
