import React, { FC, useCallback, useContext, useMemo } from 'react';
import { CommonRowType, MilestoneListColumnType } from './common';
import { TableContext } from '../controller';
import { PermissionsContext } from '@context';
import { isRestricted, useBlockerCell } from '@utils';
import { DeleteIcon } from '@svgAsComponents';
import { colors } from '@theme';
import { ColumnV2Width, PermissionNamesEnums } from '@interfaces';
import { IconButtonWithTooltip } from '@components';

const DeleteIconRow: FC<{ row: CommonRowType }> = ({ row }) => {
  const isDeleting = useBlockerCell({ milestoneId: row.id });
  const { permissions } = useContext(PermissionsContext);
  const { deleteMilestone } = useContext(TableContext);
  const isBudget = !row.project_milestone;

  const isEditable = useMemo(
    () =>
      isBudget &&
      row.activeToEdit &&
      row.localNew &&
      !isRestricted(PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE, permissions),
    [permissions, row],
  );
  const deleteCallback = useCallback(() => deleteMilestone(row.id), [row.id]);

  return (
    <IconButtonWithTooltip
      disabled={isDeleting || !isEditable}
      onClick={deleteCallback}
      tooltipText="Delete"
    >
      <DeleteIcon size={24} color={colors.status.error.medium} />
    </IconButtonWithTooltip>
  );
};

const deleteItem: MilestoneListColumnType = {
  name: 'can_be_deleted',
  columnText: '',
  permissionKey: PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE,
  Header: null,
  noMargin: true,
  noBorder: true,
  renderCell: ({ row }) => <DeleteIconRow row={row} />,
  minWidth: () => ColumnV2Width.WIDE_ICON,
  maxWidth: () => ColumnV2Width.WIDE_ICON,
};

export default deleteItem;
