import React from 'react';
import { ComponentProps, hasImagesColor, hasUnseenImagesColor } from './interface';
import { colors } from '@theme';

const PhotoGalleryIconWithIndicator = ({
  size = 24,
  color = colors.icons.gray,
  hasUnseenImages,
  hasImages,
}: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 3.5C2.89543 3.5 2 4.39543 2 5.5V16.5C2 17.6046 2.89543 18.5 4 18.5V19.5C4 20.6046 4.89543 21.5 6 21.5H18C19.1046 21.5 20 20.6046 20 19.5V18.5C21.1046 18.5 22 17.6046 22 16.5V14.0042V13.9951V5.5C22 4.39543 21.1046 3.5 20 3.5H4ZM18 18.5V19.5H6V18.5H18ZM19 16.5H20V14.5V14.3802L16.8961 10.8883L11.6402 15.2682C11.335 15.5226 10.9081 15.5721 10.5528 15.3944L7.0913 13.6637L4.08124 15.8137C4.05473 15.8327 4.02762 15.8501 4 15.8661V16.5H5H6H18H19ZM20 7.5V11.3698L17.7474 8.83564C17.3887 8.43214 16.7746 8.38616 16.3598 8.73178L10.8622 13.3131L7.44721 11.6056C7.11591 11.4399 6.72018 11.471 6.41876 11.6863L4 13.414V7.5V5.5H6H18H20V7.5ZM10.5 7.5C9.67157 7.5 9 8.17157 9 9C9 9.82843 9.67157 10.5 10.5 10.5C11.3284 10.5 12 9.82843 12 9C12 8.17157 11.3284 7.5 10.5 7.5Z"
    />
    {hasImages && (
      <circle cx="20" cy="4" r="4" fill={hasUnseenImages ? hasUnseenImagesColor : hasImagesColor} />
    )}
  </svg>
);

export default PhotoGalleryIconWithIndicator;
