import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { BaseColumnProps } from '../interface';
import { DateUploadedCell } from './common';

export const dateUploadedColumn = ({ source }: BaseColumnProps): GridColDef => ({
  field: 'created_at',
  headerName: 'Date uploaded',
  minWidth: 120,
  groupable: false,
  aggregable: false,
  renderCell: (params) => <DateUploadedCell params={params} source={source} />,
});
