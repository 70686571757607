import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  ICompany,
  ICoordinator,
  IProjectCoordinator,
  IUser,
  QueryNamesEnums,
  UpdateRequestPayload,
} from '@interfaces';
import {
  getCompanyUsers,
  getMyCompany,
  updateDrawRequestCoordinator,
  updateProjectCoordinator,
} from '@globalService';
import { useAssignCoordinatorInvalidation, useSafeSnackbar } from '@hooks';
import { filterActiveUsers, getUserFullNameOrEmail } from '@utils';
import { useLaunchDarklyFlags } from '@context';

interface ControllerInterface {
  coordinatorId: string;
  users: { value: string; label: string }[];
  onAssignCoordinator: () => Promise<void>;
  onUnassignCoordinator: () => Promise<void>;
  handleFilterChange: (value: string) => void;
  shouldUpdateProjectCoordinator: boolean;
  setShouldUpdateProjectCoordinator: (value: boolean) => void;
  isMutating: boolean;
  isLoading: boolean;
  isChanged: boolean;
}

export const useAssignCoordinatorPopup = (
  initCoordinator: ICoordinator,
  projectId: string,
  requestId: string,
  onClose: () => void,
): ControllerInterface => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const [coordinatorId, setCoordinatorId] = useState<string>(initCoordinator?.id || '');
  const [shouldUpdateProjectCoordinator, setShouldUpdateProjectCoordinator] =
    useState<boolean>(false);
  const handleAssignCoordinatorInvalidation = useAssignCoordinatorInvalidation();
  const flags = useLaunchDarklyFlags();

  const companyQuery = useQuery<ICompany, Error>(
    [QueryNamesEnums.GET_MY_COMPANY],
    getMyCompany.bind(this),
  );

  const companyUsersQuery = useQuery<{ results: IUser[] }, Error>(
    [
      QueryNamesEnums.GET_COMPANY_USERS,
      { companyId: companyQuery.data?.id, sorting: 'first_name' },
    ],
    getCompanyUsers.bind(this, {
      companyId: companyQuery.data?.id,
      sorting: '&sorting=first_name',
    }),
    { enabled: Boolean(companyQuery.data?.id) },
  );

  const updateRequestMutation = useMutation<Response, Error, UpdateRequestPayload>(
    updateDrawRequestCoordinator,
    {
      onSuccess: () => {
        if (flags?.['ENG_7910_updates_based_on_draw_events']) {
          handleAssignCoordinatorInvalidation({ projectId, drawRequestId: requestId });
        } else {
          queryClient.invalidateQueries([
            QueryNamesEnums.GET_DRAW_REQUEST,
            { projectId, drawRequestId: requestId },
          ]);
          queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_LIST);
        }
        enqueueSnackbar('Coordinator assigned', { variant: 'success' });
        onClose();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const updateCoordinatorMutation = useMutation<Response, Error, IProjectCoordinator>(
    updateProjectCoordinator,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT);
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_COMPANIES);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const onAssignCoordinator = useCallback(async () => {
    await updateRequestMutation.mutateAsync({
      project: projectId,
      drawRequest: requestId,
      coordinator: coordinatorId,
    });

    if (shouldUpdateProjectCoordinator) {
      updateCoordinatorMutation.mutateAsync({ projectId, coordinatorId });
    }
  }, [coordinatorId, shouldUpdateProjectCoordinator]);

  const onUnassignCoordinator = useCallback(async () => {
    await updateRequestMutation.mutateAsync({
      project: projectId,
      drawRequest: requestId,
      coordinator: null,
    });

    if (shouldUpdateProjectCoordinator) {
      updateCoordinatorMutation.mutateAsync({ projectId, coordinatorId: null });
    }
  }, [shouldUpdateProjectCoordinator]);

  const companyUsers = useMemo(() => {
    if (companyUsersQuery.data?.results?.length)
      return filterActiveUsers(companyUsersQuery.data.results)?.map((x) => ({
        value: x.id,
        label: x.full_name || getUserFullNameOrEmail(x),
      }));
    return [];
  }, [companyUsersQuery.data]);

  const handleFilterChange = (value: string) => {
    setCoordinatorId(value);
  };

  const isChanged = useMemo(() => {
    return coordinatorId !== initCoordinator?.id || shouldUpdateProjectCoordinator;
  }, [coordinatorId, initCoordinator?.id, shouldUpdateProjectCoordinator]);

  return {
    users: companyUsers,
    onAssignCoordinator,
    onUnassignCoordinator,
    handleFilterChange,
    coordinatorId,
    shouldUpdateProjectCoordinator,
    setShouldUpdateProjectCoordinator,
    isMutating: updateRequestMutation.isLoading || updateCoordinatorMutation.isLoading,
    isLoading: companyUsersQuery.isLoading,
    isChanged,
  };
};
