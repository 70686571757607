import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { ITokens, ErrorDual } from '@interfaces';
import { useUser } from '@hooks';
import { checkMfaStatus, postLogin } from '@globalService';
import { getErrorText, getRefreshTokenExpirationTime } from '@utils';

export interface LoginPayload {
  sso_access_token?: string;
  auth_connector?: string;
  sso_domain_name?: string;
  id_token?: string;
  account_token?: string;
  email?: string;
  password?: string;
}
export const useLogin = ({
  successCallback,
  errorCallback,
  onSettledCallback,
}: {
  successCallback?: () => void;
  errorCallback?: (error) => void;
  onSettledCallback?: () => void;
}) => {
  const { refetch } = useUser();
  const navigate = useNavigate();

  const postLoginData = useMutation<ITokens, ErrorDual, LoginPayload>(postLogin, {
    onSuccess: () => {
      if (successCallback) {
        successCallback();
      }
      localStorage.setItem('tokenExpirationTime', getRefreshTokenExpirationTime());
      return refetch(); // get user
    },
    onError: (error: ErrorDual, variables) => {
      localStorage.clear();
      sessionStorage.clear();

      // to reset location state
      if (!variables?.sso_access_token) setTimeout(() => navigate(location?.pathname, {}), 10000);
      if (errorCallback) {
        errorCallback(getErrorText(error));
      }
    },
    onSettled: () => {
      if (onSettledCallback) {
        onSettledCallback();
      }
    },
  });

  const checkMfaStatusData = useMutation(checkMfaStatus, {
    onError: (error: ErrorDual) => {
      if (errorCallback) {
        errorCallback(getErrorText(error));
      }
    },
  });

  return { postLoginData, checkMfaStatusData };
};
