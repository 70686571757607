import React, { useContext, useState } from 'react';
import { Box, Button, Grid2, IconButton, Stack, Typography } from '@mui/material';

import { colors } from '@theme';
import { ComponentProps } from './interface';
import ChecklistItem from './ChecklistItem';
import { DeleteIcon } from '@svgAsComponents';
import { AuthContext, SettingsContext } from '@context';
import { TOOLTIP_TEXTS } from '@constants';
import { EditPolicyItem, InfoIconWithTooltip } from '@components';
import { PoliciesItemLocal } from '@interfaces';
import { useDateFormatter } from '@hooks';

function Checklist({
  items,
  onItemClick,
  isEditable = false,
  addChecklistItem,
  deleteChecklistItem,
  showCheckedBy = true,
}: ComponentProps) {
  const { user } = useContext(AuthContext);
  const { isCurrentProjectArchived } = useContext(SettingsContext);
  const { dateFormatter } = useDateFormatter();
  const [openEditPolicyItem, setOpenEditPolicyItem] = useState<PoliciesItemLocal>();
  const emptyItem = { label: '', created_by_team: user?.active_team?.id, is_custom: true };

  return (
    <Box sx={{ display: 'flex', flexFlow: 'column nowrap' }}>
      {items.map((item) => {
        const showInfoTootip = item.description || (item.checked && item.checkedBy?.name);
        return (
          <Grid2
            container
            justifyContent="space-between"
            alignItems="baseline"
            key={item.id}
            sx={{ mb: '0.75rem' }}
          >
            <Grid2 sx={{ maxWidth: '90%' }}>
              <ChecklistItem
                key={item.id}
                {...item}
                onClick={onItemClick}
                disabled={isCurrentProjectArchived}
                tooltipText={TOOLTIP_TEXTS.isCurrentProjectArchived}
                showCheckedBy={showCheckedBy}
                source={isEditable ? 'request__action_popup__additional' : 'request__action_popup'}
              />
            </Grid2>
            <Grid2>
              <Stack direction="row" alignItems="center">
                <InfoIconWithTooltip
                  data-cy={`request__action_popup__checklist__item__info_tooltip__icon`}
                  color={showInfoTootip ? colors.status.information.medium : colors.neutral.medium}
                  tooltipText={
                    showInfoTootip ? (
                      <>
                        {item.description && (
                          <Stack>
                            <Typography variant="labelSemiBold">Description:</Typography>
                            <Typography
                              variant="label"
                              dangerouslySetInnerHTML={{ __html: item.description }}
                              data-cy={`request__action_popup__checklist__item__description`}
                            />
                          </Stack>
                        )}
                        {item.checked && item.checkedBy?.name && (
                          <Stack>
                            <Typography variant="labelSemiBold">Checked:</Typography>
                            <Typography
                              variant="label"
                              data-cy={`request__action_popup__checklist__item__checked_by`}
                            >
                              {`${item.checkedBy.name}, ${dateFormatter({
                                date: item.checkedBy.updatedAt,
                                withTime: true,
                              })}`}
                            </Typography>
                          </Stack>
                        )}
                      </>
                    ) : (
                      ''
                    )
                  }
                />
                {isEditable && !item.checked && (
                  <IconButton
                    onClick={() => deleteChecklistItem(item.id.toString())}
                    sx={{ p: 0, ml: 1 }}
                    data-cy="request__action_popup__checklist__item__delete_button"
                  >
                    <DeleteIcon size={24} color={colors.status.error.medium} />
                  </IconButton>
                )}
              </Stack>
            </Grid2>
          </Grid2>
        );
      })}
      {isEditable && (
        <Stack mt={2}>
          <Button
            variant="new"
            color="secondary"
            data-cy="request__action_popup__checklist__add_policy_requirement__button"
            onClick={() => {
              setOpenEditPolicyItem(emptyItem);
            }}
          >
            Add policy requirement
          </Button>
        </Stack>
      )}

      <EditPolicyItem
        source={'request__action_popup__checklist'}
        policyItem={openEditPolicyItem}
        saveItem={(editedItem: PoliciesItemLocal) => {
          addChecklistItem(editedItem.label, editedItem.description);
        }}
        onClose={() => setOpenEditPolicyItem(null)}
        items={items}
        isProjectActivation={false}
      />
    </Box>
  );
}

export default Checklist;
