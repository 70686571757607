import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { CompanyPrivacyColumnProps } from '../interface';
import { CompanyPrivacyCell } from './common';

export const companyPrivacyColumn = (props: CompanyPrivacyColumnProps): GridColDef => ({
  field: 'scope',
  headerName: 'Company private',
  minWidth: 80,
  groupable: false,
  aggregable: false,
  sortable: false,
  renderCell: (params) => <CompanyPrivacyCell params={params} {...props} />,
});
