import React, { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { MagicWandIcon } from '@svgAsComponents';
import { colors } from '@theme';

const SmartSummaryButton: FC<{
  size?: 'small' | 'medium' | 'large';
  onClick: () => void;
  source: string;
}> = ({ size = 'medium', onClick, source }) => (
  <Button
    onClick={onClick}
    data-cy={`${source}__documents_summary__button`}
    sx={{ background: colors.button.gradient }}
    size={size}
  >
    <Stack direction="row" alignItems="center" spacing={1}>
      <MagicWandIcon size={16} color={colors.white} />
      <Typography
        variant={size === 'small' ? 'labelSemiBold' : 'body2SemiBold'}
        color={colors.white}
        sx={{ whiteSpace: 'nowrap' }}
      >
        SmartSummary
      </Typography>
    </Stack>
  </Button>
);

export default SmartSummaryButton;
