import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Tooltip } from '@mui/material';
import truncate from 'lodash/truncate';
import { MAX_TEXT_LENGTH } from '@constants';
import { BaseColumnProps } from '../interface';

export const lineItemColumn = ({ source }: BaseColumnProps): GridColDef => ({
  field: 'line_item_name',
  headerName: 'Line item',
  minWidth: 180,
  groupable: false,
  aggregable: false,
  sortable: false,
  renderCell: (params) => {
    const { line_item_name } = params.row;

    const lineItemNames = line_item_name?.join(', ') || '';
    return (
      <Tooltip title={lineItemNames?.length > MAX_TEXT_LENGTH ? lineItemNames : ''}>
        <span data-cy={`${source}__line_items__value`}>
          {truncate(lineItemNames, { length: MAX_TEXT_LENGTH })}
        </span>
      </Tooltip>
    );
  },
});
