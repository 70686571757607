import { dataProvider } from '@api';
import { IApiPath, ICompany, IRoleNew } from '@interfaces';
import { ArgumentsCompaniesApi, ArgumentsCompaniesRolesByIdApi } from '../../args';
import { companiesApi, companiesRoleApi, companiesRolesByIdApi } from '../../paths';

export const getCompaniesV2 = async (
  args: ArgumentsCompaniesApi,
): Promise<{ results: ICompany[] }> => dataProvider.get(companiesApi(args)).json();

export const getCompaniesRolesV2 = async (args: IApiPath): Promise<{ results: IRoleNew[] }> =>
  dataProvider.get(companiesRoleApi(args)).json();

export const getCompaniesRoleByIdV2 = async (
  args: ArgumentsCompaniesRolesByIdApi,
): Promise<IRoleNew> => dataProvider.get(companiesRolesByIdApi(args)).json();
