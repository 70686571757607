import { TEAM_ROLES, USER_ROLES } from '@constants';
import { IRolePermissions, ITeam, IUser } from '@interfaces';

export const isCustomer = (user: IUser): boolean => user?.active_team?.company?.is_customer;

export const checkIsGeneralContractor = (teamRole: string): boolean =>
  teamRole === TEAM_ROLES.GeneralContractor;

export const checkIsAdmin = (userRole: string): boolean =>
  [USER_ROLES.CustomerSuccess, USER_ROLES.Superuser].includes(userRole);

export const checkIsLender = (teamRole: string): boolean => teamRole === TEAM_ROLES.Lender;

export const checkIsOwner = (teamRole: string): boolean => teamRole === TEAM_ROLES.Owner;

export const checkIsInvestor = (teamRole: string): boolean => teamRole === TEAM_ROLES.Investor;

export const checkIsCustomerSuccess = (teamRole: string): boolean =>
  teamRole === TEAM_ROLES.CustomerSuccess;

export const checkIsSuperUser = (teamRole: string): boolean => teamRole === TEAM_ROLES.Superuser;

export const checkIsExternalUser = (teamRole: string): boolean =>
  [
    TEAM_ROLES.Lender,
    TEAM_ROLES.Owner,
    TEAM_ROLES.GeneralContractor,
    TEAM_ROLES.Subcontractor,
    TEAM_ROLES.Investor,
  ].includes(teamRole);

export const getBorrower = (teams: ITeam[]) => teams?.find((o) => o.role === TEAM_ROLES.Owner);

export const getCustomer = (teams: ITeam[]) => teams?.find((o) => o.company?.is_customer);

export const getDisplayRolesList = (roles: IRolePermissions[]) =>
  roles?.map((o) => ({
    id: o.id,
    name_display: o.company_role,
    name: o.company_role,
  })) || [];
