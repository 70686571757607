import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  DrawRequestSourceEnum,
  DrawRequestTypeEnum,
  IDrawRequest,
  IInspection,
  IMenuItem,
  IProjectMilestone,
  PermissionNamesEnums,
  PostDrawRequest,
  QueryNamesEnums,
  ServiceTypeEnum,
} from '@interfaces';

import {
  getProjectDrawRequestsList,
  getProjectInspectionsList,
  getProjectMilestonesList,
  postDrawRequest,
} from '@globalService';
import { useContinueDraftButton, useReviewRequestInvalidation, useSafeSnackbar } from '@hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext, PermissionsContext, SettingsContext } from '@context';
import {
  getActiveDrawRequestFromList,
  getCurrentInspection,
  getServiceTypeDisplayName,
  getTeamRole,
  isCancelRequestedService,
  isChangeRequest,
  isRequestDraft,
  isRequestInReview,
  isRestricted,
} from '@utils';
import {
  AppraisalIcon,
  BudgetReviewIcon,
  BuilderReportIcon,
  ChangeIcon,
  DrawRequestIcon,
  FeasibilityIcon,
  HistoryIcon,
  InspectionIcon,
  PermitIcon,
  ServicePriceIcon,
  TitleIcon,
} from '@svgAsComponents';
import { TOOLTIP_TEXTS } from '@constants';

export interface ControllerInterface {
  createRequest: (values) => void;
  isLoading: boolean;
  isSubmitting: boolean;
  menuItems: IMenuItem[];
  drawRequestInProgress: IDrawRequest;
  isCurrentProjectArchived: boolean;
  infoModalText: string;
  closeInfoModal: () => void;
  openDraft: () => void;
  isCurrentProjectActive: boolean;
  createModalVisible: boolean;
  setCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
  continueDraftButtonLabel: string;
  disabledButton?: boolean;
  disabledTooltipText?: string;
}

export const useServicesButton = (): ControllerInterface => {
  const [createModalVisible, setCreateModal] = useState<boolean>(false);
  const { permissions } = useContext(PermissionsContext);
  const { isCurrentProjectArchived, isCurrentProjectActive, settings } =
    useContext(SettingsContext);
  const { enqueueSnackbar } = useSafeSnackbar();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const queryClient = useQueryClient();
  const [infoModalText, setInfoModalText] = useState<string>('');
  const handleRequestReviewInvalidation = useReviewRequestInvalidation();

  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);

  const serviceTypesMap = useMemo(
    () => settings?.display?.service_types,
    [settings?.display?.service_types],
  );
  const drawRequestsQuery = useQuery<{ results: IDrawRequest[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
    getProjectDrawRequestsList.bind(this, projectId),
  );

  const query = '{}';
  const limit = '1';
  // request project milestones list to understand if budget was added or not
  const projectMilestonesQuery = useQuery<{ results: IProjectMilestone[]; count: number }, Error>(
    [QueryNamesEnums.GET_PROJECT_MILESTONES, { projectId, query, limit }],
    getProjectMilestonesList.bind(this, { projectId, query, limit }),
  );

  const drawRequestInProgress = useMemo(
    () => getActiveDrawRequestFromList(drawRequestsQuery.data?.results),
    [drawRequestsQuery.data],
  );

  const { data: { results: inspectionData = [] } = {} } = useQuery<
    { results: IInspection[] },
    Error
  >(
    [QueryNamesEnums.GET_PROJECT_INSPECTIONS, { projectId }],
    getProjectInspectionsList.bind(this, { projectId }),
    {
      enabled: !isRestricted(PermissionNamesEnums.INSPECTIONS_VIEW, permissions),
    },
  );

  const draftRequest = useMemo(() => {
    const DRList = drawRequestsQuery.data?.results || [];
    return DRList.find((dr) => isRequestDraft(dr?.status));
  }, [drawRequestsQuery.data]);

  const addRequestMutation = useMutation<IDrawRequest, Error, PostDrawRequest>(postDrawRequest, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT, { projectId }]);
      handleRequestReviewInvalidation({
        projectId,
        drawRequestId: data?.id,
      });
      if (isRequestDraft(data.status)) {
        navigate(`/projects/${projectId}/requests/${data?.id}/submission/`);
      }
      if (isRequestInReview(data.status)) {
        navigate(`/projects/${projectId}/draws/draw-requests/${data?.id}/`);
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const createRequest = useCallback(
    (chosenType: DrawRequestTypeEnum, chosenSource?: DrawRequestSourceEnum) => {
      if (draftRequest) {
        setInfoModalText(
          `There is Draft ${isChangeRequest(draftRequest) ? 'CR' : 'DR'} created by Borrower user ${
            draftRequest.team?.company_name || ''
          }. If you need to create a new request on behalf of the borrower, please contact Customer Support.`,
        );
        return;
      }
      addRequestMutation.mutateAsync({
        id: projectId,
        type: chosenType,
        ...(chosenSource && { source: chosenSource }),
      });
    },
    [projectId, draftRequest, teamRole],
  );

  const isOrderButtonShown = useMemo(
    () =>
      !isRestricted(PermissionNamesEnums.INSPECTIONS_EDIT, permissions) &&
      Boolean(drawRequestInProgress?.type === DrawRequestTypeEnum.DRAW_REQUEST),
    [drawRequestInProgress],
  );

  const inProgressInspection = useMemo(
    () => getCurrentInspection(inspectionData),
    [inspectionData],
  );
  const isInspectionCancelRequested = useMemo(
    () => isCancelRequestedService(inProgressInspection?.status),
    [inProgressInspection],
  );

  const menuItems = useMemo(() => {
    return [
      ...(!isRestricted(PermissionNamesEnums.DRAWREQUESTS_CREATE, permissions)
        ? [
            {
              action: () => createRequest(DrawRequestTypeEnum.DRAW_REQUEST),
              text: 'Draw',
              icon: <DrawRequestIcon />,
              disabled: Boolean(drawRequestInProgress) || !isCurrentProjectActive,
              dataTestName: 'add_draw_draft',
              disabledTooltipText: isCurrentProjectActive
                ? TOOLTIP_TEXTS.activeRequest
                : TOOLTIP_TEXTS.isCurrentProjectArchived,
            },
            {
              action: () => createRequest(DrawRequestTypeEnum.CHANGE_REQUEST),
              text: 'Change',
              icon: <ChangeIcon />,
              dataTestName: 'add_change_request',
              disabled: Boolean(drawRequestInProgress) || !isCurrentProjectActive,
              disabledTooltipText: isCurrentProjectActive
                ? TOOLTIP_TEXTS.activeRequest
                : TOOLTIP_TEXTS.isCurrentProjectArchived,
            },
          ]
        : []),
      ...(!isRestricted(PermissionNamesEnums.DRAWREQUESTS_MANUAL_CREATE, permissions)
        ? [
            {
              action: () =>
                createRequest(DrawRequestTypeEnum.DRAW_REQUEST, DrawRequestSourceEnum.MANUAL),
              text: 'Historical draw',
              icon: <HistoryIcon />,
              dataTestName: 'add_historical_draw',
              disabled: Boolean(drawRequestInProgress) || !projectMilestonesQuery?.data?.count,
              disabledTooltipText: drawRequestInProgress
                ? TOOLTIP_TEXTS.activeRequest
                : TOOLTIP_TEXTS.noBudget,
              sectionBreak: !isRestricted(PermissionNamesEnums.INSPECTIONS_EDIT, permissions),
            },
          ]
        : []),
      ...(!isRestricted(PermissionNamesEnums.INSPECTIONS_EDIT, permissions)
        ? [
            {
              action: () => navigate(`order-service/?service_type=${ServiceTypeEnum.INSPECTION}`),
              text: 'Inspection',
              icon: <InspectionIcon size={24} />,
              dataTestName: 'project__add_request_menu__order_service__inspection__button',
              sectionTitle: 'SERVICES',
              disabled: Boolean(inProgressInspection),
              disabledTooltipText: isInspectionCancelRequested
                ? TOOLTIP_TEXTS.inspectionCancelRequested
                : TOOLTIP_TEXTS.inspectionOrdered,
            },
            {
              action: () => navigate(`order-service/?service_type=${ServiceTypeEnum.TITLE}`),
              text: getServiceTypeDisplayName({
                serviceTypesMap,
                serviceType: ServiceTypeEnum.TITLE,
              }),
              icon: <TitleIcon />,
              dataTestName: 'project__add_request_menu__order_service__title__button',
            },
            {
              action: () => navigate(`order-service/?service_type=${ServiceTypeEnum.FEASIBILITY}`),
              text: getServiceTypeDisplayName({
                serviceTypesMap,
                serviceType: ServiceTypeEnum.FEASIBILITY,
              }),
              icon: <FeasibilityIcon />,
              dataTestName: 'project__add_request_menu__order_service__feasibility__button',
            },
            {
              action: () =>
                navigate(`order-service/?service_type=${ServiceTypeEnum.BUDGET_REVIEW}`),
              text: getServiceTypeDisplayName({
                serviceTypesMap,
                serviceType: ServiceTypeEnum.BUDGET_REVIEW,
              }),
              icon: <BudgetReviewIcon />,
              dataTestName: 'project__add_request_menu__order_service__budget_review__button',
            },
            {
              action: () =>
                navigate(
                  `order-service/?service_type=${ServiceTypeEnum.BUILDER_INFORMATION_REPORT}`,
                ),
              text: getServiceTypeDisplayName({
                serviceTypesMap,
                serviceType: ServiceTypeEnum.BUILDER_INFORMATION_REPORT,
              }),
              icon: <BuilderReportIcon />,
              dataTestName:
                'project__add_request_menu__order_service__builder_information_report__button',
            },
            {
              action: () =>
                navigate(
                  `order-service/?service_type=${ServiceTypeEnum.PERMIT_VERIFICATION_REPORT}`,
                ),
              text: getServiceTypeDisplayName({
                serviceTypesMap,
                serviceType: ServiceTypeEnum.PERMIT_VERIFICATION_REPORT,
              }),
              icon: <PermitIcon />,
              dataTestName:
                'project__add_request_menu__order_service__permit_verification_report__button',
            },
            {
              action: () => navigate(`order-service/?service_type=${ServiceTypeEnum.APPRAISAL}`),
              text: getServiceTypeDisplayName({
                serviceTypesMap,
                serviceType: ServiceTypeEnum.APPRAISAL,
              }),
              icon: <AppraisalIcon />,
              dataTestName: 'project__add_request_menu__order_service__appraisal__button',
            },
            {
              action: () =>
                navigate(`order-service/?service_type=${ServiceTypeEnum.BROKER_PRICE_OPINION}`),
              text: getServiceTypeDisplayName({
                serviceTypesMap,
                serviceType: ServiceTypeEnum.BROKER_PRICE_OPINION,
              }),
              icon: <ServicePriceIcon />,
              dataTestName:
                'project__add_request_menu__order_service__broker_price_opinion__button',
            },
          ]
        : []),
    ];
  }, [
    draftRequest,
    drawRequestInProgress,
    permissions,
    inProgressInspection,
    isOrderButtonShown,
    isCurrentProjectActive,
    projectMilestonesQuery?.data?.count,
    serviceTypesMap,
  ]);

  const { openDraft, continueDraftButtonLabel } = useContinueDraftButton({
    projectId,
    drawRequestId: draftRequest?.id,
    drawRequest: draftRequest,
  });

  return {
    menuItems,
    isLoading: drawRequestsQuery.isLoading || addRequestMutation.isLoading,
    isSubmitting: addRequestMutation.isLoading,
    createRequest,
    drawRequestInProgress,
    isCurrentProjectArchived,
    infoModalText,
    closeInfoModal: () => setInfoModalText(''),
    openDraft,
    createModalVisible,
    isCurrentProjectActive,
    setCreateModal,
    continueDraftButtonLabel,
    disabledButton: Boolean(drawRequestInProgress) || !isCurrentProjectActive,
    disabledTooltipText: isCurrentProjectArchived
      ? TOOLTIP_TEXTS.isCurrentProjectArchived
      : isCurrentProjectActive
        ? TOOLTIP_TEXTS.activeRequest
        : TOOLTIP_TEXTS.isCurrentProjectArchived,
  };
};
