import React from 'react';
import { Stack } from '@mui/material';

import { colors } from '@theme';
import { CommentIconWithIndicator } from '@svgAsComponents';
import { IconButtonWithTooltip } from '@components';
const MessagePanelButton = ({ handleRightDrawerOpenerClick, commentsPreview, source }) => {
  return (
    <Stack
      sx={{
        backgroundColor: colors.main.primary.main,
        width: '40px',
        height: '40px',
        borderRadius: '2px',
      }}
    >
      <IconButtonWithTooltip
        tooltipText="Comments"
        sx={{
          '& > svg > path: hover': {
            fill: colors.main.primary.lighter,
          },
        }}
        onClick={() =>
          handleRightDrawerOpenerClick({
            title: 'Comments',
          })
        }
        data-cy={`${source}__comments__icon`}
      >
        <CommentIconWithIndicator
          color={colors.neutral.white}
          hasComments={commentsPreview?.has_comments}
          hasUnreadComments={commentsPreview?.has_unread_comments}
        />
      </IconButtonWithTooltip>
    </Stack>
  );
};

export default MessagePanelButton;
