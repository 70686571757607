import React from 'react';
import { Box, Popover } from '@mui/material';
import { ColumnChooserIcon } from '@svgAsComponents';
import { ComponentProps } from './interface';
import { ChecklistItem, IconButtonWithTooltip } from '@components';

const ColumnsFilter = ({
  columns,
  changeFieldVisibility,
  hiddenColumns,
  isUpdating,
  source,
  disabled = false,
}: ComponentProps) => {
  //here we handle what columns should be shown via columns filter
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButtonWithTooltip
        tooltipText="Select columns"
        aria-label="filter"
        aria-controls="fields-list"
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
        data-cy={`${source}__column_filter__icon`}
        disabled={disabled}
      >
        <ColumnChooserIcon />
      </IconButtonWithTooltip>
      <Popover
        id="fields-list"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        marginThreshold={0}
      >
        <Box sx={{ p: 2, minWidth: 320 }}>
          {columns.map((column: any, index) =>
            (typeof column.Header === 'string' && Boolean(column.Header)) || column.columnText ? (
              <ChecklistItem
                key={index.toString()}
                id={column.id || column.name}
                label={column.columnText || column.Header}
                checked={!hiddenColumns?.includes(column.name || column.accessor)}
                onClick={() => changeFieldVisibility(column.id || column.name || column.accessor)}
                {...(column.getToggleHiddenProps ? column.getToggleHiddenProps() : {})}
                disabled={isUpdating}
                index={index}
                source={`${source}__column_filter__${column.name || column.accessor}`}
              />
            ) : null,
          )}
        </Box>
      </Popover>
    </>
  );
};

export default ColumnsFilter;
