import React, { FC } from 'react';
import { Stack, Theme, Typography, useMediaQuery } from '@mui/material';

import {
  DocumentSectionContextEnum,
  HookState,
  IDocument,
  IProjectDocument,
  ITablePagination,
} from '@interfaces';
import {
  DocumentEditModal,
  DocumentSummaryList,
  Gallery,
  LoadingSkeleton,
  MessagePanel,
  PDFViewerNew,
  ReactTable,
  RightDrawer,
  ServiceMessage,
  SmartSummaryButton,
  TablePaginationNew,
} from '@components';
import * as Controller from './controller';
import { NoDataImage } from '@svgAsComponents';
import { colors } from '@theme';
import { IDocumentProps } from '../../interface';
import { DownloadAllButton, UploadButton } from '../../components';
import { useDocumentsData } from '@hooks';

const DocumentsPanel: FC<
  IDocumentProps & {
    drawRequestId?: string;
    restoreDocument: (documentId: string, name: string) => void;
    deleteDocument?: (documentId: string, name: string) => void;
    handleOpenDocumentUploader: () => void;
  }
> = ({
  source,
  prId,
  drawRequestId,
  deleteDocument,
  requestDocuments,
  milestoneId,
  inspectionId,
  milestoneSubmitId,
  serviceOrderId,
  restoreDocument,
  handleOpenDocumentUploader,
  isRightDrawer,
}) => {
  const {
    state,
    isLoading,
    documents,
    projectName,
    tablePagination,
    documentsCount,
    isPHBProject,
  } = useDocumentsData({
    projectId: prId,
    drawRequestId,
    milestoneId,
    serviceOrderId,
    inspectionId,
    context: DocumentSectionContextEnum.PRIMARY,
    isDocumentsPanel: true,
  });
  const {
    state: otherDocsState,
    isLoading: isOtherDocsLoading,
    documents: otherDocs,
    tablePagination: otherDocsTablePagination,
    documentsCount: otherDocsCount,
  } = useDocumentsData({
    projectId: prId,
    drawRequestId,
    milestoneId,
    serviceOrderId,
    inspectionId,
    context: DocumentSectionContextEnum.OTHER,
    isDocumentsPanel: true,
  });

  const controller = Controller.useDocumentsPanel({
    prId,
    requestId: drawRequestId,
    requestDocuments,
    milestoneId,
    milestoneSubmitId,
    inspectionId,
    serviceOrderId,
    source,
    restoreDocument,
    documents,
  });

  const {
    pdf,
    gallery,
    close,
    columns,
    rightMenu,
    rightDrawerParams,
    docSummaryMenu,
    docSummaryParams,
    openAllDocsSummary,
    openPdfViewer,
    activeDocument,
    closeDocumentEditModal,
    docSectionHeader,
  } = controller;
  const smallMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  if (state === HookState.ERROR || otherDocsState === HookState.ERROR) {
    return <ServiceMessage text="documents" />;
  }

  if (isLoading || isOtherDocsLoading) {
    return <LoadingSkeleton type="overviewBlock" />;
  }

  return (
    <Stack sx={{ flex: 1, marginBottom: 9 }}>
      {Boolean(state === HookState.SUCCESS && !documents?.length) &&
      Boolean(otherDocsState === HookState.SUCCESS && !otherDocs?.length) ? (
        <Stack justifyContent="center" alignItems="center" sx={{ flexGrow: 1 }}>
          <NoDataImage size={200} />
          <Typography sx={{ mt: 4, textAlign: 'center' }} variant="body2SemiBold">
            No documents yet
          </Typography>
        </Stack>
      ) : (
        <Stack spacing={2}>
          <DocumentsSection
            docSectionHeader={docSectionHeader}
            tablePagination={tablePagination}
            documentsCount={documentsCount}
            source={source}
            state={state}
            documents={documents}
            columns={columns}
          />
          {!isPHBProject && !isRightDrawer && (
            <DocumentsSection
              docSectionHeader="Other"
              tablePagination={otherDocsTablePagination}
              documentsCount={otherDocsCount}
              source={source}
              state={otherDocsState}
              documents={otherDocs}
              columns={columns}
            />
          )}
        </Stack>
      )}
      <Stack
        direction="row"
        justifyContent="flex-end"
        sx={{
          mt: 'auto',
          position: 'fixed',
          bottom: 0,
          backgroundColor: colors.background.white,
          py: 4.5,
          px: 2,
          width: smallMediaQuery ? '100%' : '564px',
        }}
      >
        <DownloadAllButton documents={documents} source={source} drawRequestId={drawRequestId} />
        <UploadButton onClick={handleOpenDocumentUploader} source={source} />
        {Boolean(state === HookState.SUCCESS && documents?.length) && (
          <Stack ml={1}>
            <SmartSummaryButton onClick={openAllDocsSummary} source={source} />
          </Stack>
        )}
      </Stack>

      {activeDocument && (
        <DocumentEditModal
          document={activeDocument}
          closeDocumentEditModal={closeDocumentEditModal}
          deleteDocument={deleteDocument}
          prId={prId}
        />
      )}
      {pdf && <PDFViewerNew pdfFile={pdf[0]} close={close} />}
      {gallery && <Gallery startIndex={0} close={close} files={gallery} />}
      <RightDrawer {...rightMenu}>
        <MessagePanel {...rightDrawerParams} source={`${source}__list__comments`} />
      </RightDrawer>
      <RightDrawer {...docSummaryMenu} drawerWidth="720px" right="600px">
        <DocumentSummaryList
          {...docSummaryParams}
          projectName={projectName}
          rows={documents}
          source={`${source}__list__summary`}
          openPdfViewer={openPdfViewer}
          tablePagination={tablePagination}
          documentsCount={documentsCount}
        />
      </RightDrawer>
    </Stack>
  );
};

export default DocumentsPanel;

const DocumentsSection: FC<{
  docSectionHeader: string;
  tablePagination: ITablePagination;
  documentsCount: number;
  source: string;
  state: HookState;
  documents: IProjectDocument[] | IDocument[];
  columns: object[];
}> = ({ docSectionHeader, tablePagination, documentsCount, source, state, documents, columns }) => {
  if (state === HookState.SUCCESS && !documents?.length) return null;

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {docSectionHeader && <Typography variant="h4">{docSectionHeader}</Typography>}
        <TablePaginationNew
          page={tablePagination.page}
          rowsPerPage={tablePagination.rowsPerPage}
          rowsPerPageOptions={tablePagination.rowsPerPageOptions}
          itemsCount={documentsCount}
          onPageChange={tablePagination.onPageChange}
          onRowsPerPageChange={tablePagination.onRowsPerPageChange}
          source={source}
        />
      </Stack>

      <ReactTable
        data={documents}
        hideHeader={true}
        columns={columns}
        footer={false}
        source={`${source}__primary__list`}
      />
    </Stack>
  );
};
