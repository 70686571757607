import React, { FC, PropsWithChildren } from 'react';
import { Stack, Typography, Box } from '@mui/material';
import { ComponentProps, Sizes } from './interface';
import {
  FileIcon,
  InfoIcon,
  LockIcon,
  ProjectListIcon,
  Warning2Icon,
  WarningIcon,
} from '@svgAsComponents';
import { ToolTipLineVariantEnum } from '@interfaces';
import { colors } from '@theme';
import { backgroundColorsMap } from '@constants';

const heightMap = {
  xs: '23px',
  small: '32px',
  default: '40px',
};

const TooltipCharacteristics = {
  [ToolTipLineVariantEnum.INFORMATION]: {
    style: {
      backgroundColor: backgroundColorsMap[ToolTipLineVariantEnum.INFORMATION],
      py: 1,
      px: 2,
    },
    icon: InfoIcon,
    color: colors.status.information.medium,
  },
  [ToolTipLineVariantEnum.TEXT]: {
    style: {
      backgroundColor: backgroundColorsMap[ToolTipLineVariantEnum.INFORMATION],
      py: 1,
      px: 2,
    },
    icon: ProjectListIcon,
    color: colors.text.dark,
  },
  [ToolTipLineVariantEnum.ERROR]: {
    style: {
      backgroundColor: backgroundColorsMap[ToolTipLineVariantEnum.ERROR],
      py: 1,
      px: 2,
    },
    icon: WarningIcon,
    color: colors.status.error.medium,
  },
  [ToolTipLineVariantEnum.WARNING]: {
    style: {
      backgroundColor: backgroundColorsMap[ToolTipLineVariantEnum.WARNING],
      py: 1,
      px: 2,
    },
    icon: Warning2Icon,
    color: colors.status.warning.medium,
  },
  [ToolTipLineVariantEnum.WARNING2]: {
    style: {
      py: 1,
      px: 2,
    },
    icon: Warning2Icon,
    color: colors.status.orange.medium,
  },
  [ToolTipLineVariantEnum.NOTIFICATION]: {
    style: {
      backgroundColor: backgroundColorsMap[ToolTipLineVariantEnum.NOTIFICATION],
      py: 1,
      px: 2,
    },
    icon: FileIcon,
    color: colors.status.orange.medium,
  },
  [ToolTipLineVariantEnum.LOCK]: {
    style: {
      backgroundColor: colors.background.white,
      py: 1,
      px: 2,
    },
    icon: LockIcon,
    color: colors.status.information.medium,
  },
  [ToolTipLineVariantEnum.ACTIVE_WARNING]: {
    style: {
      backgroundColor: colors.status.orange.medium,
      py: 1,
      px: 2,
    },
    icon: Warning2Icon,
    color: colors.background.white,
  },
};

const ToolTipLine: FC<PropsWithChildren<ComponentProps>> = ({
  variant = ToolTipLineVariantEnum.INFORMATION,
  text,
  children,
  typographyVariant = 'body2SemiBold' as Sizes,
  withBackground = false,
  fullWidth = false,
  dataTestName,
  size,
  sx,
}) => {
  const Icon = TooltipCharacteristics[variant].icon;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{
        ...(withBackground && TooltipCharacteristics[variant].style),
        ...(fullWidth && { backgroundColor: colors.background.white, p: 2 }),
        maxHeight: heightMap[size] || heightMap.default,
        ...sx,
      }}
      spacing={1}
    >
      <Stack sx={{ flexShrink: 1 }}>
        <Icon size={size === 'small' ? 16 : 24} color={TooltipCharacteristics[variant].color} />
      </Stack>

      {text ? (
        <Typography
          variant={typographyVariant}
          sx={{ color: TooltipCharacteristics[variant].color || colors.text.dark, ml: 1 }}
          data-cy={dataTestName}
        >
          {text}
        </Typography>
      ) : (
        <Box sx={{ ml: 1 }}>{children}</Box>
      )}
    </Stack>
  );
};

export default ToolTipLine;
