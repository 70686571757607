import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';

import { StyledBox } from '@components';
import * as Controller from './controller';
import {
  ChangeDataPopup,
  DrawRequestCard,
  LoanDetails,
  MobileInfoBox,
  ProjectDetails,
  Sources,
  Uses,
} from '..';
import { WithPermission } from '@utils';
import { PermissionNamesEnums } from '@interfaces';
import InspectionCard from '../InspectionCard';
import { SettingsContext, useLaunchDarklyFlags } from '@context';

const Root = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
  minHeight: 'calc(100vh - 277px)',
  gridTemplateAreas: `
    'first second third forth' 
  `,
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    gridTemplateAreas: `
    'first second' 
    'third forth'
  `,
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
    gridTemplateAreas: `
    'first' 
    'forth'
    'second'
    'third'
  `,
  },
  gridGap: theme.spacing(2),
}));

const OverviewTab = () => {
  const { projectId } = useParams();
  const {
    showCreateButton,
    disabledCreateButton,
    waitingForApproval,
    changedData,
    setChangedData,
    updateProjectData,
  } = Controller.useOverviewTab(projectId);
  const { settings } = useContext(SettingsContext);
  const { loan_servicing_statuses } = settings?.display || {};
  const flags = useLaunchDarklyFlags();

  return (
    <Root>
      <Box sx={{ gridArea: 'first' }}>
        {showCreateButton && isMobileOnly && !disabledCreateButton && (
          <MobileInfoBox text={'Eager to send a request to your Lender?'} style={{ mb: 2 }} />
        )}
        <StyledBox sx={{ p: 0 }}>
          <ProjectDetails setChangedData={setChangedData} />
        </StyledBox>
        {waitingForApproval && isMobileOnly && (
          <MobileInfoBox text={'Ready to review a request from the Borrower?'} style={{ mt: 2 }} />
        )}
      </Box>

      <Box sx={{ gridArea: 'second' }}>
        <Stack sx={{ height: '100%' }}>
          <StyledBox sx={{ p: 0 }}>
            <Sources />
          </StyledBox>
          <Box sx={{ mt: 2 }} />
          <StyledBox sx={{ p: 0 }}>
            <Uses />
          </StyledBox>
        </Stack>
      </Box>

      <Box sx={{ gridArea: 'third' }}>
        <Stack sx={{ height: '100%' }}>
          <WithPermission permissionKey={PermissionNamesEnums.INSPECTION_STATUS_VIEW}>
            <StyledBox sx={{ p: 0 }}>
              <InspectionCard />
            </StyledBox>
            <Box sx={{ mt: 2 }} />
          </WithPermission>
          <StyledBox sx={{ p: 0 }}>
            <LoanDetails setChangedData={setChangedData} />
          </StyledBox>
        </Stack>
      </Box>

      <Box sx={{ gridArea: 'forth' }}>
        <Stack sx={{ height: '100%' }}>
          <StyledBox sx={{ p: 0 }}>
            <DrawRequestCard />
          </StyledBox>
        </Stack>
      </Box>

      {flags?.['ENG_9589_quick_edit_on_project_overview'] && changedData && (
        <ChangeDataPopup
          onClose={() => setChangedData(null)}
          onSave={(value) => {
            updateProjectData(changedData.type, value);
            setChangedData(null);
          }}
          initData={changedData.data}
          title={changedData.title}
          type={changedData.type}
          validationRule={changedData.validationRule}
          options={loan_servicing_statuses}
          source='project'
        />
      )}
    </Root>
  );
};

export default OverviewTab;
