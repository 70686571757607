import { useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { matchPath, useLocation, useParams } from 'react-router-dom';
import find from 'lodash/find';

import { getHookState, isRequestDraft, isRequestInReview } from '@utils';
import {
  DeletePhotoPayload,
  DocumentTabEnums,
  HookState,
  IDocument,
  IDrawRequest,
  IPhoto,
  IProjectDocument,
  ITablePagination,
  QueryNamesEnums,
  UpdateProjectPhotoPayload,
} from '@interfaces';
import { AuthContext, SettingsContext, useLaunchDarklyFlags } from '@context';
import {
  deleteDrawRequestPhoto,
  getDrawRequest,
  getDrawRequestDocumentsList,
  getProjectDocuments,
  postThumbToProject,
} from '@globalService';
import {
  ConfirmModalHookInterface,
  useConfirmationModal,
  useSafeSnackbar,
  useTablePagination,
  useUrlParams,
} from '@hooks';
import { defaultRowsPerPageOptions, onlyProgressPhotos } from '@constants';

export interface ControllerInterface {
  state: HookState;
  photos: IPhoto[];
  projectId: string;
  drawRequestId: string;
  isAddPhotoAvailable: boolean;
  handlePhotoClick: (id: string) => void;
  activePhotoId: string;
  activePhoto: IPhoto;
  closeCallback: () => void;
  photosForSlider: IPhoto[];
  callbackOnPhotoChange: (id: string) => void;
  handlePhotoDelete: (photoId: string) => void;
  isPhotoDeleting: boolean;
  userId: string;
  handleFiltersChange: (_: string, filterValue: string[]) => void;
  filterValue: string[];
  handleProjectImageUpdate: () => void;
  confirmUpdateProjectCoverModal: ConfirmModalHookInterface;
  tablePagination: ITablePagination;
  itemsCount: number;
  isPHBProject: boolean;
  getDataParamsPaginated: (pagination, q) => void;
}

export const usePhotos = (): ControllerInterface => {
  const { user } = useContext(AuthContext);
  const { projectId } = useParams();
  const { pathname } = useLocation();
  const match = matchPath('/projects/:projectId/:tab/draw-requests/:drawRequestId', pathname);
  const matchTab = matchPath('/projects/:projectId/:tab/*', pathname);
  const tabPathname = matchTab?.params['*']?.split('/')[0];
  const drawRequestId = match?.params?.drawRequestId;
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const confirmUpdateProjectCoverModal = useConfirmationModal();
  const isProjectTab = tabPathname === DocumentTabEnums.PROJECT;
  const { isPHBProject } = useContext(SettingsContext);
  const [filterValue, setFilterValue] = useState([]);
  const flags = useLaunchDarklyFlags();

  const drawRequestQuery = useQuery<IDrawRequest, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId }],
    getDrawRequest.bind(this, { projectId, drawRequestId }),
    { enabled: Boolean(drawRequestId) },
  );

  const tablePagination = useTablePagination({
    initialRowsPerPage: defaultRowsPerPageOptions.documents[0],
    rowsPerPageOptions: defaultRowsPerPageOptions.documents,
  });

  useEffect(() => {
    tablePagination.setPage(0);
  }, [projectId, drawRequestId]);

  const getDataParamsPaginated = (pagination, q) => ({
    type: QueryNamesEnums.GET_PROJECT_MILESTONES,
    keys: ['id', 'name'],
    args: {
      pagination,
      q,
      projectId,
    },
    options: {
      strictSerialize: (data) =>
        data.map((item) => ({
          value: item.id,
          label: item.name,
        })),
    },
  });

  const paginationParams = useMemo(
    () =>
      `&offset=${
        tablePagination.page * tablePagination.rowsPerPage
      }&limit=${tablePagination.rowsPerPage}`,
    [tablePagination.page, tablePagination.rowsPerPage],
  );

  const stringQueryParams = useMemo(
    () =>
      onlyProgressPhotos +
      paginationParams +
      (isProjectTab ? '&include_draw_request_documents=false' : '') +
      (filterValue?.length ? `&milestones=${filterValue.join(',')}` : ''),
    [paginationParams, isProjectTab, filterValue],
  );

  // get photos
  const projectPhotosQuery = useQuery<{ results: IProjectDocument[]; count: number }, Error>(
    [QueryNamesEnums.GET_PROJECT_DOCUMENTS, { projectId, stringQueryParams }],
    getProjectDocuments.bind(this, { projectId, stringQueryParams }),
    {
      enabled: Boolean(projectId && !drawRequestId),
    },
  );

  const drawRequestPhotosQuery = useQuery<{ results: IDocument[]; count: number }, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_DOCUMENTS, { projectId, drawRequestId, stringQueryParams }],
    getDrawRequestDocumentsList.bind(this, { projectId, drawRequestId, stringQueryParams }),
    {
      enabled: Boolean(projectId && drawRequestId),
    },
  );

  const findPhotoById = (id) => find(photos, { id });

  const photos = useMemo(() => {
    const data = drawRequestId
      ? drawRequestPhotosQuery.data?.results
      : projectPhotosQuery.data?.results;
    if (!data?.length) return [];
    if (flags?.['ENG_9342_replace_line_item_name_with_milestone_names']) {
      return data.map((item) => ({
        ...item,
        milestones:
          item.milestone_names?.map((name) => ({
            name,
          })) || [],
      }));
    }
    return data.map((item) => ({
      ...item,
      milestones:
        item.line_item_name?.map((name) => ({
          name,
        })) || [],
    }));
  }, [drawRequestPhotosQuery.data, projectPhotosQuery.data, drawRequestId, flags]);

  // handle photo click
  const [activePhotoId, setActivePhotoId] = useUrlParams(
    null,
    'photoId',
    (s) => s.toString(),
    (s) => s.toString(),
  );
  const [activePhoto, setActivePhoto] = useState(null);

  const handlePhotoClick = (id) => () => setActivePhotoId(id);

  useEffect(() => {
    if (!activePhotoId) setActivePhoto(null);
    photos?.length && activePhotoId && setActivePhoto(findPhotoById(activePhotoId));
  }, [activePhotoId, photos]);

  const callbackOnPhotoChange = (id) => setActivePhotoId(id);

  // remove photo functionality
  const deletePhotoMutation = useMutation<Response, Error, DeletePhotoPayload>(
    deleteDrawRequestPhoto,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST,
          { projectId, drawRequestId },
        ]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_PHOTOS, { projectId }]);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_PHOTOS,
          { projectId, drawRequestId },
        ]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_INSPECTION_DOCUMENTS, { projectId }]);
        enqueueSnackbar('Photo deleted', { variant: 'success' });
        setActivePhotoId(null);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const handlePhotoDelete = async (photoId) => {
    await deletePhotoMutation.mutateAsync({
      projectId,
      photoId,
    });
  };

  const handleFiltersChange = (_, value) => {
    setFilterValue(value);
  };

  const updateProjectImageMutation = useMutation<Response, Error, UpdateProjectPhotoPayload>(
    postThumbToProject,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT, { projectId }]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECTS_LIST]);
        enqueueSnackbar('Project cover photo updated', { variant: 'success' });
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const handleProjectImageUpdate = () => {
    updateProjectImageMutation.mutateAsync({
      projectId,
      file_representations: activePhoto.file_representations,
    });
  };

  return {
    state: getHookState(drawRequestId ? drawRequestPhotosQuery : projectPhotosQuery),
    photos,
    projectId,
    drawRequestId,
    isAddPhotoAvailable:
      isProjectTab ||
      isRequestInReview(drawRequestQuery.data?.status) ||
      isRequestDraft(drawRequestQuery.data?.status),
    handlePhotoClick,
    activePhoto,
    activePhotoId,
    closeCallback: () => setActivePhotoId(null),
    photosForSlider: [],
    callbackOnPhotoChange,
    handlePhotoDelete,
    isPhotoDeleting: deletePhotoMutation.isLoading,
    userId: user?.id,
    handleFiltersChange,
    filterValue,
    handleProjectImageUpdate,
    confirmUpdateProjectCoverModal,
    tablePagination,
    itemsCount: drawRequestId ? drawRequestPhotosQuery.data?.count : projectPhotosQuery.data?.count,
    isPHBProject,
    getDataParamsPaginated,
  };
};
