import React, { FC } from 'react';

import { InspectionResultsV2, InspectionResults } from '@components';

import { useLaunchDarklyFlags } from '@context';

const InspectionResultsIndex: FC = () => {
  const flags = useLaunchDarklyFlags();
  if (flags?.['ENG_9554_9045_edit_service_costs_and_agency_status_by_CS']) {
    return <InspectionResultsV2 />;
  }
  return <InspectionResults />;
};

export default InspectionResultsIndex;
