import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Box, Grid2, Stack, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { colors } from '@theme';
import { ComponentProps } from './interface';
import { PermissionsContext, useGetListData, useLaunchDarklyFlags } from '@context';
import { Button } from './elements';
import { PopperWithAutoComplete } from '@components';
import { useTablePagination } from '@hooks';

const FilterButton: FC<ComponentProps> = ({
  title,
  filterKey,
  handleFiltersChange,
  initValues,
  cypressSelector,
  fixedWidth,
  getStaticValues,
  buttonWidth,
  maxWidth,
  minWidth,
  closeOnChange,
  defaultFilterLabel = 'All',
  withPagination,
  getDataParamsPaginated,
  multiple = true,
  textFieldHeight = '32px',
  justifyContent = 'space-between',
}) => {
  const flags = useLaunchDarklyFlags();
  const [maxCount, setMaxCount] = useState<number>(50);
  const [q, setQ] = useState<string>('');
  const { permissions } = useContext(PermissionsContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [pendingValue, setPendingValue] = useState<string[]>(initValues);
  const { page, rowsPerPage, nextPage, setPage } = useTablePagination({ initialRowsPerPage: 200 });

  const nextPageCallback = useMemo(
    () => (Math.ceil(maxCount / rowsPerPage) - 1 > page ? nextPage : null),
    [maxCount, rowsPerPage, page, nextPage],
  );

  const valuesList =
    withPagination && flags?.['eng-8873-pagination-in-filters']
      ? useGetListData(getDataParamsPaginated({ page, rowsPerPage }, q))
      : getDataParamsPaginated
        ? useGetListData(getDataParamsPaginated({ page: 0, rowsPerPage: 9999 }))
        : {
            serialized: getStaticValues({ permissions }),
            isLoading: false,
          };

  const valuesWithExistingData = useMemo(() => {
    const result = [...(valuesList?.serialized || [])];
    if (!getDataParamsPaginated) return result;
    pendingValue.forEach((id) => {
      if (!result.find((item) => item.value === id)) {
        result.unshift({
          label: id,
          value: id,
        });
      }
    });
    return result;
  }, [pendingValue, valuesList.serialized]);
  useEffect(() => {
    setPendingValue(initValues);
  }, [initValues]);

  useEffect(() => {
    if (valuesList?.['data']?.['count']) {
      setMaxCount(valuesList?.['data']?.['count']);
    }
  }, [valuesList?.['data']?.['count']]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const renderFilterValue = () => (
    <>
      <Typography
        variant="body3SemiBold"
        color={colors.text.medium}
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {pendingValue?.length ? stringToShow : defaultFilterLabel}
      </Typography>
      <KeyboardArrowDownIcon />
    </>
  );

  const open = Boolean(anchorEl);
  const id = open ? `${title}-label` : undefined;

  const stringToShow = useMemo(
    () =>
      valuesWithExistingData
        ?.filter((item) => pendingValue.includes(item.value))
        ?.map((item) => item.label || item.id)
        ?.join(','),
    [valuesWithExistingData, pendingValue],
  );

  return (
    <Grid2 container alignItems="center" sx={{ ...(fixedWidth && { width: fixedWidth }) }}>
      <Box
        sx={{
          maxWidth: fixedWidth || maxWidth || '320px',
          minWidth: fixedWidth || minWidth || '120px',
        }}
      >
        <Button
          onClick={handleClick}
          data-cy={cypressSelector}
          sx={{ ...(buttonWidth && { width: buttonWidth }) }}
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              width: '100%',
              height: textFieldHeight,
              backgroundColor: colors.neutral.lightest,
              borderRadius: '2px',
              p: 1,
              '& svg': { width: 16, height: 16 },
              color: colors.text.medium,
              ...(fixedWidth && { justifyContent }),
            }}
          >
            <Typography variant="body3" color={colors.text.medium} sx={{ whiteSpace: 'nowrap' }}>
              {`${title}:`}
            </Typography>
            {(fixedWidth && (
              <Stack direction="row" sx={{ justifyContent: 'flex-end' }}>
                {renderFilterValue()}
              </Stack>
            )) ||
              renderFilterValue()}
          </Stack>
        </Button>
      </Box>
      <PopperWithAutoComplete
        anchorEl={anchorEl}
        id={id}
        handleClose={handleClose}
        open={open}
        options={valuesWithExistingData}
        closeOnChange={closeOnChange}
        handleValuesChange={(values) => handleFiltersChange(filterKey, values)}
        placeHolder={`Find ${title.toLowerCase()}`}
        values={pendingValue}
        isLoading={valuesList.isLoading}
        inputChange={(data) => {
          setQ(data);
          setPage(0);
        }}
        loadNext={!valuesList.isLoading ? nextPageCallback : null}
        multiple={multiple}
      />
    </Grid2>
  );
};

export default FilterButton;
