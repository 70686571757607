import React, { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useBulkApplyPolicies } from './controller';
import { PoliciesTypeEnum, PolicyListSourceEnums } from '@interfaces';
import { PoliciesTypeLabel, PoliciesTypeMap, TEAM_ROLES } from '@constants';
import {
  CustomAutocomplete,
  FullScreenDialog,
  IconButtonWithTooltip,
  StyledBox,
} from '@components';
import { ArrowBackIcon } from '@svgAsComponents';

const BulkApplyPolicies: FC<{
  open: boolean;
  onClose: () => void;
  projectIds: string[];
  companyId: string;
}> = ({ open, onClose, projectIds = [], companyId }) => {
  const { selectedTemplates, applyPolicies, getFilteredTemplates, isLoading } =
    useBulkApplyPolicies({
      companyId,
      projectIds,
      onClose,
    });

  const content = () => (
    <Stack spacing={3} sx={{ p: 1, maxWidth: 1200, width: '100%', margin: '0 auto' }}>
      <StyledBox spacing={3} sx={{ p: 3 }}>
        <Typography variant="h3">Policy templates</Typography>
        <Stack spacing={3} sx={{ width: '100%', mt: 3 }}>
          {Object.values(PoliciesTypeEnum).map((type) => (
            <Stack spacing={2} direction="row" key={type}>
              <Stack sx={{ width: '20%' }}>
                <Typography variant="body1SemiBold">
                  {PoliciesTypeLabel[PoliciesTypeMap[type]]}
                </Typography>
              </Stack>
              <CustomAutocomplete
                field={selectedTemplates[PolicyListSourceEnums.SUBMISSION][type]}
                label="Submission"
                options={getFilteredTemplates(TEAM_ROLES.Owner, type as PoliciesTypeEnum)}
                inputProps={{
                  'data-cy': `bulk_apply_policies__approval__${PoliciesTypeMap[type]}_select`,
                }}
                sx={{ width: '40%' }}
              />
              <CustomAutocomplete
                field={selectedTemplates[PolicyListSourceEnums.APPROVAL][type]}
                label="Approval"
                options={getFilteredTemplates(TEAM_ROLES.Lender, type as PoliciesTypeEnum)}
                inputProps={{
                  'data-cy': `bulk_apply_policies__approval__${PoliciesTypeMap[type]}_select`,
                }}
                sx={{ width: '40%' }}
              />
            </Stack>
          ))}
        </Stack>
      </StyledBox>
    </Stack>
  );

  const actionButtons = () => (
    <Stack direction="row" spacing={2} justifyContent="flex-end">
      <Button color="secondary" onClick={onClose}>
        Cancel
      </Button>
      <LoadingButton variant="contained" onClick={applyPolicies} loading={isLoading}>
        Apply
      </LoadingButton>
    </Stack>
  );

  const title = () => (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ width: '100%', mr: 2 }}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        <IconButtonWithTooltip
          tooltipText="Go back"
          onClick={onClose}
          data-cy="bulk_apply_policies__back__icon"
        >
          <ArrowBackIcon />
        </IconButtonWithTooltip>
        <Typography variant="h1">Apply policies</Typography>
      </Stack>
      <Typography variant="body1">Amount of projects: {projectIds.length}</Typography>
    </Stack>
  );

  return (
    <FullScreenDialog open={open} title={title} content={content} actionButtons={actionButtons} />
  );
};

export default BulkApplyPolicies;
