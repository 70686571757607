import React, { FC, memo, useContext } from 'react';

import { CommonRowType, MilestoneListColumnTypeV2 } from './common';
import { CommentIconWithIndicator } from '@svgAsComponents';
import { PermissionNamesEnums, RequestTableTabsEnum } from '@interfaces';
import { IconButtonWithTooltip } from '@components';
import { colors } from '@theme';
import { DrawerContext } from '../drawer';
import { isRestricted } from '@utils';

const Comments: FC<{ row: CommonRowType }> = ({ row }) => {
  const { updateRightDrawer, commentsPreviewInfo, source } = useContext(DrawerContext);
  const rowInfo = commentsPreviewInfo?.[row.id] || row?.comments_preview;
  if (row.id === 'totals') return null;
  return (
    <IconButtonWithTooltip
      tooltipText="Comments"
      onClick={
        updateRightDrawer
          ? updateRightDrawer({
              milestoneId: row?.id,
              milestoneName: row?.name,
              tab: RequestTableTabsEnum.COMMENTS,
            })
          : row?.updateRightDrawer?.()
      }
      data-cy={
        source ? `${source}__body__comments__icon` : 'inspections__result__general_row__comments'
      }
    >
      <CommentIconWithIndicator
        color={colors.icons.green}
        hasComments={rowInfo?.has_comments}
        hasUnreadComments={rowInfo?.has_unread_comments}
      />
    </IconButtonWithTooltip>
  );
};

const Memoized = memo(Comments);

const comments: MilestoneListColumnTypeV2 = {
  field: 'comments_preview',
  headerAlign: 'right',
  headerClassName: 'icon',
  cellClassName: 'icon',
  renderHeader: () => null,
  editable: false,
  pinnable: false,
  disableColumnMenu: true,
  sortable: false,
  filterable: false,
  groupable: false,
  aggregable: false,
  hideable: false,
  resizable: false,
  permissionKey: PermissionNamesEnums.COMMENTS_VIEW,
  editableByPermissions: (permissions) =>
    !isRestricted(PermissionNamesEnums.COMMENTS_VIEW, permissions),
  renderCell: (params) => <Memoized row={params.row} />,
  maxWidth: 40,
  minWidth: 40,
};

export default comments;
